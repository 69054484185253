import React from 'react'
import UpcomingEventsStyles from './UpcomingEvents.module.css'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

const UpcomingEvents = () => {

     // get data from redux
     const name=useSelector((state)=>state.globalData.name);
     const count=useSelector((state)=>state.globalData.notificationCount);
     const dispatch =  useDispatch()

    const events = [1, 2, 3, 4, 5, 6]

    const [data, setData] = useState([])

    const getEventsData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/upcomingevents/getAllUpcomingEvents`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteEventbyId = async (eventId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/upcomingevents/deleteById/${eventId}`
            )

            dispatch(fetchNotifications())
            
       // this is the snipte for the notification

    //    const dateTime=res.data.date;

    //    const [dateString,timeString]=dateTime.split("T");

    //    const date=new Date(dateString);

    //    const time=timeString.substr(0,8);


    //    const localDate=date.toLocaleDateString();

    //    dispatch(setNotifications({
    //        notification:{
    //          name:name,
    //          details:res.data.message,
    //          time:time,
    //          date:localDate
    //        }
    //      }))
         dispatch(setNotificationCount({
          count:count+1,
         }))
         
       //   uptill here
            alert('Delete Successful!')
            // window.location.reload()
            getEventsData()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getEventsData()
    }, [])

    return (
        <div className={UpcomingEventsStyles.upcomingEventsMain}>
            <div className={UpcomingEventsStyles.upcomingEventsContent}>
                <div className={UpcomingEventsStyles.upcomingEventsHeader}>
                    <Typography sx={{ marginTop: '1%', color: '#121926', fontWeight: '600', fontSize: '20px' }} >
                        Upcoming Events
                    </Typography>
                    <Link to='/addevent'>
                    <Button sx={{ textTransform: 'capitalize' }} variant='contained'>Add Event</Button>
                    </Link>
                </div>
                <Box className={UpcomingEventsStyles.upcomingEventsComponent}>
                    <div className={UpcomingEventsStyles.upcomingEventsComponentLeft}>
                        <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Name
                        </Typography>
                    </div>
                    <div className={UpcomingEventsStyles.upcomingEventsComponentRight}>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Last modified
                        </Typography>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Action
                        </Typography>
                    </div>
                </Box>
                {data.map((event) =>
                    <Box className={UpcomingEventsStyles.upcomingEventsComponent}>
                        <div className={UpcomingEventsStyles.upcomingEventsComponentLeft}>
                            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                                {event.eventName}
                            </Typography>
                        </div>
                        <div className={UpcomingEventsStyles.upcomingEventsComponentRight}>
                            <Typography variant='h6' sx={{ fontSize: '15px' }}>
                                {event.date}
                            </Typography>
                            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600', display: 'flex', alignItems: 'baseline' }}>
                                <Link to={`/editevent/${event?.eventId}`} state={event}>
                                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </Link>
                                <IconButton disableRipple onClick={() => deleteEventbyId(event.eventId)}>
                                <DeleteForeverOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </IconButton>
                            </Typography>
                        </div>
                    </Box>
                ).reverse()}
            </div>
        </div>
    )
}

export default UpcomingEvents