import React, { useState, useEffect, useRef } from 'react';
import HomeBannerStyles from './EditHomeBanner.module.css';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setNotifications, setNotificationCount, fetchNotifications } from '../../state/GlobalState';

const EditHomeBanner = () => {
  const location = useLocation();
  const newdata = location.state;
  console.log(newdata);

  const navigate = useNavigate();

  const name = useSelector((state) => state.globalData.name);
  const count = useSelector((state) => state.globalData.notificationCount);
  const dispatch = useDispatch();

  const inputRef = useRef();

  const [file, setFile] = useState(null);
  const [bannerData, setBannerData] = useState({
    bannerTitle: '',
    bannerHeading: '',
  });

  const [data, setData] = useState([]);
  const [existingVideo, setExistingVideo] = useState(null);

  const getBannerData = async () => {
    try {
      const bannerGet = await axios.get(`${process.env.REACT_APP_GECEADMIN}/bannerVideo/getAllBannerDetails`);
      setData(bannerGet.data);
      setBannerData({
        bannerTitle: bannerGet.data[0]?.bannerVideo?.bannerTitle || '',
        bannerHeading: bannerGet.data[0]?.bannerVideo?.bannerHeading || '',
      });
      setFile(bannerGet.data[0]?.newBannerMediaName || '');
      setFileName(bannerGet.data[0]?.newBannerMediaName || '');
      setSource(`${process.env.REACT_APP_GECEADMIN}/bannerVideo/file/${bannerGet.data[0]?.newBannerMediaName}`);
      setVideoSelect(true);
      console.log(bannerGet.data);
    } catch (error) {
      console.log(error);
    }
  };


    useEffect(() => {
        getBannerData()
    }, [])

    const handleInputChange = (event) => {
        const {name, value} = event.target
        setBannerData({ ...bannerData, [name]: value })
    }

    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [videoSelect, setVideoSelect] = useState(false)

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setVideoSelect(true)
        setFile(file)
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardVideo = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = "";
        setVideoSelect(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formDataObj = new FormData();
        formDataObj.append("file", file);
        formDataObj.append("bannerTitle", bannerData.bannerTitle);
        formDataObj.append("bannerHeading", bannerData.bannerHeading);

        try{
            const videoResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/bannerVideo/uploadBannerVideo`,
                formDataObj,
                {
                    headers: {
                        // "Content-Type": "video/webm"
                        "Content-Type": "multipart/form-data"

                    },
                }
            );
            console.log(videoResponse);

            const bannerResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/bannerVideo/addBannerDetails/${videoResponse.data.bannerMediaId}`,
                bannerData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log('Banner Updated.')
                        alert('Banner Updated.')
                        navigate('/homepage')


            console.log(bannerResponse)
            dispatch(fetchNotifications())

               dispatch(setNotificationCount({
                count:count+1,
               }))
 
            console.log('Banner Updated.')
            console.log(bannerResponse)
            alert('Banner Updated.')
            discardVideo()
            navigate('/homepage')
            
        } catch (error) {
            console.error(error)

        }
      };

  return (
    <div className={HomeBannerStyles.homebannerMain}>
      <div className={HomeBannerStyles.homebannerContent}>
        <form action="" className={HomeBannerStyles.homebannerForm} onSubmit={handleSubmit} encType="multipart/form-data">
          <label className={HomeBannerStyles.homebannerFormLabel}>Title</label>
          <input
            className={HomeBannerStyles.homebannerFormText}
            type="text"
            name="bannerTitle"
            placeholder={data[0]?.bannerVideo?.bannerTitle}
            value={bannerData.bannerTitle}
            onChange={handleInputChange}
          />
          <label className={HomeBannerStyles.homebannerFormLabel}>Heading</label>
          <input
            className={HomeBannerStyles.homebannerFormText}
            type="text"
            name="bannerHeading"
            placeholder={data[0]?.bannerVideo?.bannerHeading}
            value={bannerData.bannerHeading}
            onChange={handleInputChange}
          />
          <label className={HomeBannerStyles.homebannerFormLabel}>Upload</label>
          <div className={HomeBannerStyles.VideoInput}>
            <input
              ref={inputRef}
              className={HomeBannerStyles.VideoInput_input}
              type="file"
              onChange={handleFileChange}
              accept=".mov,.mp4"
            />
            {!source && <button type="button" onClick={handleChoose}>Browse</button>}
            {source && (
              <video
                className={HomeBannerStyles.VideoInput_video}
                width="100%"
                controls
                src={source}
              />
            )}
            <div className={HomeBannerStyles.VideoInput_footer}>
              {videoSelect ? (
                <div>
                  {fileName}
                  <DeleteForeverOutlinedIcon onClick={discardVideo} sx={{ cursor: 'pointer' }} />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <Button variant="contained" type="submit" className={HomeBannerStyles.saveChanges}>Save changes</Button>
        </form>
      </div>
    </div>
  );
};

export default EditHomeBanner;