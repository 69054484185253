import React, { useState } from 'react'
import BusinessStyles from './AddOurBusiness.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';



//   notification section start

import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
//   notification section End


const AddOurBusiness = () => {
    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

     //   notification section start
    // get data from redux
    const name=useSelector((state)=>state.globalData.name);
    const count=useSelector((state)=>state.globalData.notificationCount);


    const dispatch=useDispatch();

    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [businessData, setBusinessData] = useState({
        businessHeading: '',
        businessDesc: '',
        businessLink: '',
    })
    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [imageSelect, setImageSelect] = useState(false)

    const handleInputChange = (event) => {
        const {name, value} = event.target
        setBusinessData({ ...businessData, [name]: value })
    }


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setImageSelect(true)
        setFile(file)
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardImage = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = ""
        setImageSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        formDataObj.append("businessHeading", businessData.businessHeading)
        formDataObj.append("businessDesc", businessData.businessDesc)
        formDataObj.append("businessLink", businessData.businessLink)

        try{
            const imageResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/business/uploadBusinessImage`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "image/jpeg"
                    }
                }
            )
            console.log(imageResponse)

            const businessResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/business/addBusinessDetails/${imageResponse.data.businessImageId}`,
                businessData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
//             console.log('Business Added.')
            console.log(businessResponse)

            dispatch(fetchNotifications())

            alert('Business Added.')
            navigate('/ourbusiness')


              // this is the snipte for the notification

            //   const dateTime=businessResponse.data.date;

            //   const [dateString,timeString]=dateTime.split("T");
    
            //   const date=new Date(dateString);
    
            //   const time=timeString.substr(0,8);
    
    
            //   const localDate=date.toLocaleDateString();
  
            //   dispatch(setNotifications({
            //       notification:{
            //         name:name,
            //         details:businessResponse.data.message,
            //         time:time,
            //         date:localDate
            //       }
            //     }))
                dispatch(setNotificationCount({
                 count:count+1,
                }))


            // alert('Business Added.')
            discardImage()
            navigate('/ourbusiness')

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={BusinessStyles.businessMain}>
            <div className={BusinessStyles.businessContent}>
                <form action="" className={BusinessStyles.businessForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={BusinessStyles.businessFormLabel}>Title</label>
                    <input className={BusinessStyles.businessFormText} type="text" value={businessData.businessHeading} name='businessHeading' onChange={handleInputChange} />
                    <label className={BusinessStyles.businessFormLabel}>Description</label>
                    <textarea className={BusinessStyles.businessFormDesc} value={businessData.businessDesc} name='businessDesc' onChange={handleInputChange} />
                    <label className={BusinessStyles.businessFormLabel}>Link</label>
                    <input className={BusinessStyles.businessFormText} type="text" value={businessData.businessLink} name='businessLink' onChange={handleInputChange} />
                    <label className={BusinessStyles.businessFormLabel}>Upload</label>
                    <div className={BusinessStyles.ImgInput}>
                        <input
                            ref={inputRef}
                            className={BusinessStyles.ImgInput_input}
                            type="file"
                            onChange={handleFileChange}
                        />
                        {!source && <button type='button' onClick={handleChoose}>Choose</button>}
                        {source && (
                            <img
                                className={BusinessStyles.ImgInput_img}
                                width="100%"
                                src={source}
                                alt='uploadedimage'
                            />
                        )}
                            </div>
                        <div className={BusinessStyles.ImgInput_footer}>
                            {imageSelect ?
                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                    {fileName}
                                    <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
                                </div> :
                                ''}
                        </div>
                    <Button variant='contained' type='submit' className={BusinessStyles.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default AddOurBusiness