import React, { useEffect, useState } from 'react';
import InvOppstyle from '../AddInvOpportunities/AddInvOpportunities.module.css';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';
import { useRef } from 'react';



const EditInvestmentBanner = () => {
    const { id } = useParams();
    const [invName, setInvName] = useState("")
    const [data, setData] = useState({ photos: { newImageName: '' } });
    const [imageFile, setImageFile] = useState(null); // To hold the selected image file
    const [showImage, setShowImage] = useState(true);
    const [displayedImage, setDisplayedImage] = useState(null);
    const fileInputRef = useRef(null);
    const [uploadedImage, setUploadedImage] = useState(false);


    const getData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/getInvestmentOpportunitiesById/${id}`
            );
            setData(res.data);
            // Set the invName value from the response
            setInvName(res.data.invName);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData();
    }, [id]);
    const updateInvestmentOpportunity = async (event) => {
        event.preventDefault();
        if (data.invName !== invName) {

            try {




                const res = await axios.post(
                    `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/updateInvestmentOpportunities/${id}`,
                    { invName: invName }
                );

                alert(`Name Was Changed`);
                console.log(res.data);
                getData();

                console.log("Updated InvName", invName);

            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleDeleteImage = () => {
        setShowImage(false); //here i am hide the image fuction 

        setData({
            ...data,
            photos: {
                newImageName: ''
            }
        });
        setDisplayedImage(null);
    };

    const handleSaveChanges = (event) => {
        event.preventDefault();

        // Check if the imageFile has changed
        // console.log(imageFile.name )
        // console.log(data.photos.imageName)
        // console.log(imageFile.name !== data.photos.imageName)
        if (imageFile !== null && imageFile.name !== data.photos.imageName) {
            const formData = new FormData();
            formData.append('file', imageFile);

            axios
                .post(
                    `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/updateImage/${id}/${id}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )
                .then((res) => {
                    getData();
                    setImageFile(null);
                    setUploadedImage(true);
                    // setDisplayedImage(`${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/file/${newImageName}`);
                    alert("Image Was Changed");


                    updateInvestmentOpportunity(event);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            // alert("No image selected. No API call made.");
            updateInvestmentOpportunity(event);
        }
    };




    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    const fetchImage = async () => {
        try {
            const imageResponse = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/file/${data.photos.newImageName}`,
                {
                    responseType: 'blob', // Setting the response type to blob
                }
            );

            // Convert blob to a File object
            const file = new File([imageResponse.data], data.photos.imageName, {
                type: 'image/jpeg', // Set the appropriate MIME type here
            });

            setImageFile(file);
            // setCoverSource(file);
        } catch (error) {
            console.error(error);
        }
    };



    useEffect(() => {
        if (data.photos.newImageName !== undefined && data.photos.newImageName !== null && data.photos.newImageName !== "") {

            fetchImage();
        }

    }, [data]);

    const generateLocalUrl = (file) => {
        try {
            const url = URL.createObjectURL(file);
            return url;
        } catch (error) {
            console.error('Error generating local URL:', error);
            return null;
        }
    };
    return (
        <div className={InvOppstyle.businessMain}>
            <div className={InvOppstyle.businessContent}>
                <form action="" className={InvOppstyle.businessForm}
                >
                    <label className={InvOppstyle.businessFormLabel}>Name</label>
                    <input
                        className={InvOppstyle.businessFormText}
                        type="text"
                        value={invName}
                        name="invName"
                        onChange={(e) => setInvName(e.target.value)}
                    />

                    <label className={InvOppstyle.businessFormLabel}> Upload Image</label>
                    <div className={InvOppstyle.imgRow}
                        style={{
                            width: '100%',
                            maxWidth: '300x',
                            maxHeight: '30vh',
                            borderRadius: '8px'
                        }}
                    >
                        {imageFile !== null &&
                            <div className={InvOppstyle.imgRowImg} >
                                <img src={generateLocalUrl(imageFile)} alt="image" />
                            </div>
                        }

                        {/* <Card
                                style={{
                                    width: '500px',
                                    height: '200px',
                                    boxShadow: 'none',
                                    background: `none, url(${displayedImage ? displayedImage : `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/file/${data.photos.newImageName}`})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            >

                            </Card> */}

                    </div>

                    <div className={InvOppstyle.ImgInput}>

                        <div>
                            <input

                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                style={{ display: 'none' }} // Hide the input

                                onChange={(e) => setImageFile(e.target.files[0])}
                            />
                            <Button
                                onClick={() => fileInputRef.current.click()}
                                style={{
                                    color: '#ffff',
                                    backgroundColor: '#1976d2',
                                    padding: '7px 15px',
                                    textTransform: 'capitalize',
                                    '&:hover': {
                                        backgroundColor: '#1976d2',
                                        color: '#ffff',
                                    },
                                    width: '100%'
                                }}
                            >
                                Upload Image
                            </Button>
                        </div>

                    </div>
                    <div></div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '30px', marginTop: '30px' }}>
                        <Button
                            onClick={() => setImageFile(null)}
                            startIcon={<DeleteForeverOutlinedIcon />}
                            style={{
                                color: '#ffff',  // Set your desired color
                                backgroundColor: '#1976d2',
                                padding: '7px 15px',  // Set your desired background color
                                '&:hover': {
                                    backgroundColor: '#1976d2',  // Set your desired hover background color
                                    color: '#ffff',  // Set your desired hover color
                                }
                            }}
                        >
                            Delete
                        </Button>
                        <Button variant="contained" className={InvOppstyle.saveChanges} onClick={handleSaveChanges}
                            sx={{
                                marginTop: '0px !important'
                            }}
                        >
                            Save changes
                        </Button>
                    </div>
                </form>
            </div >
        </div >
    );
}

export default EditInvestmentBanner;
