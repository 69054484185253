import React from 'react'
import OurBusinessStyles from './OurBusiness.module.css'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

const OurBusiness = () => {
    const name=useSelector((state)=>state.globalData.name);
    const count=useSelector((state)=>state.globalData.notificationCount);


    const dispatch=useDispatch();

    const business = [1, 2, 3]

    const [data, setData] = useState([])

    const getBusinessData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/business/getAllBusinessDetails`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteBusinessbyId = async (businessImageId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/business/deleteById/${businessImageId}`
            )

            dispatch(fetchNotifications())



            dispatch(setNotificationCount({
              count:count+1,
             }))
             
            alert('Delete Successful!')
            // window.location.reload()
            getBusinessData()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getBusinessData()
    }, [])


    return (
        <div className={OurBusinessStyles.ourbusinessMain}>
            <div className={OurBusinessStyles.ourbusinessContent}>
                <div className={OurBusinessStyles.ourbusinessHeader}>
                    <Typography sx={{ marginTop: '1%', color: '#121926', fontWeight: '600', fontSize: '20px' ,paddingBottom:'2%'}} >
                        Our Business
                    </Typography>
                    <Link to='/addbusiness'>
                        <Button sx={{ textTransform: 'capitalize' }} variant='contained'>Add Business</Button>
                    </Link>
                </div>
                <Box className={OurBusinessStyles.ourbusinessComponent}>
                    <div className={OurBusinessStyles.ourbusinessComponentLeft}>
                        <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Name
                        </Typography>
                    </div>
                    <div className={OurBusinessStyles.ourbusinessComponentRight}>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Last modified
                        </Typography>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Action
                        </Typography>
                    </div>
                </Box>
                {data.map((business) =>
                    <Box className={OurBusinessStyles.ourbusinessComponent}>
                        <div className={OurBusinessStyles.ourbusinessComponentLeft}>
                            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                                {business.business?.businessHeading}
                            </Typography>
                        </div>
                        <div className={OurBusinessStyles.ourbusinessComponentRight}>
                            <Typography variant='h6' sx={{ fontSize: '15px' }}>
                                {business.business?.date}
                            </Typography>
                            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600', display: 'flex', alignItems: 'baseline' }}>
                                <Link to={`/business/${business?.businessImageId}`} state={business} style={{marginRight:'10%'}} >
                                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </Link>
                                <IconButton disableRipple onClick={() => deleteBusinessbyId(business.businessImageId)}>
                                    <DeleteForeverOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </IconButton>
                            </Typography>
                        </div>
                    </Box>
                ).reverse()}
            </div>
        </div>
    )
}

export default OurBusiness