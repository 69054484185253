import React, { useEffect, useState } from 'react'
import EditJobStyles from './EditJob.module.css'
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



  // import this for redux read write
  import { useSelector, useDispatch } from 'react-redux'
  import { setNotifications } from '../../state/GlobalState'
  import { setNotificationCount } from '../../state/GlobalState'
  import { fetchNotifications } from '../../state/GlobalState'

const EditJob = () => {

    // get data from redux
    const name=useSelector((state)=>state.globalData.name);
    const count=useSelector((state)=>state.globalData.notificationCount);
    const dispatch =  useDispatch()


    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const inputRef = React.useRef();

    const [careerJobRoleName, setCareerJobRoleName] = useState('')
    const [jobLocation, setJobLocation] = useState('')
    const [jobType, setJobType] = useState('')
    const [careerJobRoleExperience, setCareerJobRoleExperience] = useState('')
    const [vacancy, setVacancy] = useState('')
    const [whoAreWeLookingFor, setWhoAreWeLookingFor] = useState('')
    const [whatYouWillBeDoing, setWhatYouWillBeDoing] = useState('')
    const [educationalRequirement, setEducationalRequirement] = useState('')
    const [remuneration, setRemuneration] = useState('')

    useEffect(() => {
        if (data) {
            setCareerJobRoleName(data.careerJobRoleName)
            setJobLocation(data.jobLocation)
            setJobType(data.jobType)
            setCareerJobRoleExperience(data.careerJobRoleExperience)
            setVacancy(data.vacancy)
            setWhoAreWeLookingFor(data.whoAreWeLookingFor)
            setWhatYouWillBeDoing(data.whatYouWillBeDoing)
            setEducationalRequirement(data.educationalRequirement)
            setRemuneration(data.remuneration)
        }
    }, [data])

    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const jobResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/career/updateJobRole/${data.careerJobRoleId}`,
                { careerJobRoleName, jobLocation, jobType, careerJobRoleExperience, vacancy, whoAreWeLookingFor, whatYouWillBeDoing, educationalRequirement,remuneration },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log('Job Role Updated.')
            console.log(jobResponse)

            dispatch(fetchNotifications())

            
             // this is the snipte for the notification

    //    const dateTime=jobResponse.data.date;

    //    const [dateString,timeString]=dateTime.split("T");

    //    const date=new Date(dateString);

    //    const time=timeString.substr(0,8);


    //    const localDate=date.toLocaleDateString();

    //    dispatch(setNotifications({
    //        notification:{
    //          name:name,
    //          details:jobResponse.data.message,
    //          time:time,
    //          date:localDate
    //        }
    //      }))
         dispatch(setNotificationCount({
          count:count+1,
         }))
         
       //   uptill here 

            alert('Job Role Updated.')
            navigate('/jobroles')
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={EditJobStyles.jobMain}>
        {/* <div className={EditJobStyles.jobContent}> */}
        <form action="" className={EditJobStyles.jobForm} onSubmit={handleSubmit}>
            <div className={EditJobStyles.inputRow}>

                <label >Job Role</label>
                <input type="text" value={careerJobRoleName} name='careerJobRoleName' onChange={(event) => setCareerJobRoleName(event.target.value)} />
            </div>
            <div className={EditJobStyles.inputRow}>

                <label >Location</label>
                <input type="text" value={jobLocation} name='jobLocation' onChange={(event) => setJobLocation(event.target.value)} />
            </div>
            <div className={EditJobStyles.inputRow}>

                <label >Job Type</label>
                <input type="text" value={jobType} name='jobType' onChange={(event) => setJobType(event.target.value)} />
            </div>
            <div className={EditJobStyles.inputRow}>

                <label >Experience Needed</label>
                <input type="text" value={careerJobRoleExperience} name='careerJobRoleExperience' onChange={(event) => setCareerJobRoleExperience(event.target.value)} />
            </div>
            <div className={EditJobStyles.inputRow}>

                <label >Vacancy</label>
                <input type="text" value={vacancy} name='vacancy' onChange={(event) => setVacancy(event.target.value)} />
            </div>
            <div className={EditJobStyles.inputRow}>

                <label >Who Are We Looking For</label>
                {/* <textarea className={EditJobStyles.jobFormDesc} value={whoAreWeLookingFor} name='whoAreWeLookingFor' onChange={(event) => setWhoAreWeLookingFor(event.target.value)} /> */}
                <div  className={EditJobStyles.reactQill} >
                    <ReactQuill theme="snow"   value={whoAreWeLookingFor} onChange={setWhoAreWeLookingFor} />
                </div>
            </div>
            <div className={EditJobStyles.inputRow}>

                <label >What You Will Be Doing</label>
                {/* <textarea className={EditJobStyles.jobFormDesc} value={whatYouWillBeDoing} name='whatYouWillBeDoing' onChange={(event) => setWhatYouWillBeDoing(event.target.value)}/> */}
                <div className={EditJobStyles.reactQill} >

                    <ReactQuill theme="snow" 
                     
                    value={whatYouWillBeDoing} onChange={setWhatYouWillBeDoing} />
                </div>
            </div>
            <div className={EditJobStyles.inputRow}>

                <label >Educational Requirement</label>
                <input value={educationalRequirement} name='educationalRequirement' onChange={(event) => setEducationalRequirement(event.target.value)} type="text" />
            </div>
            <div className={EditJobStyles.inputRow}>

                <label >Remuneration</label>
                <input value={remuneration} name='remuneration' onChange={(event) => setRemuneration(event.target.value)} type="text" />
            </div>

            <Button type='submit' className={EditJobStyles.postButton} variant='contained'>Edit Job</Button>
        </form>
        {/* </div> */}
    </div>
        // <div className={EditJobStyles.jobMain}>
        //     <div className={EditJobStyles.jobContent}>
        //         <form action="" className={EditJobStyles.jobForm} onSubmit={handleSubmit}>
        //             <label className={EditJobStyles.jobFormLabel}>Job Role</label>
        //             <input className={EditJobStyles.jobFormText} type="text" value={careerJobRoleName} name='careerJobRoleName' onChange={(event) => setCareerJobRoleName(event.target.value)} />
        //             <label className={EditJobStyles.jobFormLabel}>Location</label>
        //             <input className={EditJobStyles.jobFormText} type="text" value={jobLocation} name='jobLocation' onChange={(event) => setJobLocation(event.target.value)} />
        //             <label className={EditJobStyles.jobFormLabel}>Job Type</label>
        //             <input className={EditJobStyles.jobFormText} type="text" value={jobType} name='jobType' onChange={(event) => setJobType(event.target.value)} />
        //             <label className={EditJobStyles.jobFormLabel}>Experience Needed</label>
        //             <input className={EditJobStyles.jobFormText} type="text" value={careerJobRoleExperience} name='careerJobRoleExperience' onChange={(event) => setCareerJobRoleExperience(event.target.value)} />
        //             <label className={EditJobStyles.jobFormLabel}>Vacancy</label>
        //             <input className={EditJobStyles.jobFormText} type="text" value={vacancy} name='vacancy' onChange={(event) => setVacancy(event.target.value)} />
        //             <label className={EditJobStyles.jobFormLabel}>Who Are We Looking For</label>
        //             <textarea className={EditJobStyles.jobFormDesc} value={whoAreWeLookingFor} name='whoAreWeLookingFor' onChange={(event) => setWhoAreWeLookingFor(event.target.value)} />
        //             <label className={EditJobStyles.jobFormLabel}>What You Will Be Doing</label>
        //             <textarea className={EditJobStyles.jobFormDesc} value={whatYouWillBeDoing} name='whatYouWillBeDoing' onChange={(event) => setWhatYouWillBeDoing(event.target.value)} />
        //             <label className={EditJobStyles.jobFormLabel}>Educational Requirement</label>
        //             <input className={EditJobStyles.jobFormText} type="text" value={educationalRequirement} name='educationalRequirement' onChange={(event) => setEducationalRequirement(event.target.value)} />

        //             <label className={EditJobStyles.jobFormLabel}>Remuneration</label>
        //             <input className={EditJobStyles.jobFormText} type="text" value={remuneration} name='remuneration' onChange={(event) => setRemuneration(event.target.value)} />


        //             <Button type='submit' className={EditJobStyles.postButton} variant='contained'>Edit Job</Button>
        //         </form>
        //     </div>
        // </div>
    )
}

export default EditJob