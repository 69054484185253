import React, { useEffect, useState } from 'react'
import eidtStyle from './EditMarket.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

import { useParams } from 'react-router-dom';


const EditMarket = () => {
  const { id, id2, id3 } = useParams();

  // get data from redux
  const name = useSelector((state) => state.globalData.name);
  const count = useSelector((state) => state.globalData.notificationCount);
  const dispatch = useDispatch()

  const location = useLocation()
  const data = location.state


  const navigate = useNavigate()

  const inputRef = React.useRef();

  const [file, setFile] = useState(null)
  const [source, setSource] = React.useState();
  const [fileName, setFileName] = React.useState('');
  const [imageSelect, setImageSelect] = useState(false)

  const [graphName, setgraphName] = useState('')
  const [graphDesc, setgraphDesc] = useState('')



  useEffect(() => {
    if (data) {
      setgraphName(data.graphInfo.graphName)
      setgraphDesc(data.graphInfo.graphDesc)

      setSource(`${process.env.REACT_APP_GECEADMIN}/graph/file/${data.graphInfo.images.newImage}`);
      console.log(data);
    }
  }, [data])

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name)
    const url = URL.createObjectURL(file);
    setSource(url);
    setImageSelect(true)
    setFile(file)
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const discardImage = (event) => {
    setSource(null)
    setFileName('')
    setFile(null)
    inputRef.current.value = ""
    setImageSelect(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formDataObj = new FormData()
    formDataObj.append("file", file)
    formDataObj.append("graphName", graphName)
    formDataObj.append("graphDesc", graphDesc)

    try {
      const imageResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/graph/editGraphImage/${id3}/${data.graphInfo.images.imageId}`,
        formDataObj,
        {
          headers: {
            "Content-Type": "image/jpeg"
          }
        }
      )
      console.log(imageResponse)
    } catch (error) {
      console.error(error)
    }
    try {
      const businessResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/graph/editGraph/${id}/${id2}/${id3}`,
        { graphName, graphDesc },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      // console.log('Business Updated.')
      // console.log(businessResponse)

    } catch (error) {
      console.error(error)
    }
    alert('Graph Updated.')

    dispatch(fetchNotifications())

    dispatch(setNotificationCount({
      count: count + 1,
    }))
    discardImage()
    navigate(`/market/${id}/${id2}`)
  }

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     try {
  //       // const imageResponse = await axios.get(
  //       //   `${process.env.REACT_APP_GECEADMIN}/graph/file/${graphImg}`
  //       // );
  //       // console.log(imageResponse);
  //       setSource(`${process.env.REACT_APP_GECEADMIN}/graph/file/${graphImg}`);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchImage();
  // }, [graphImg]);

  return (
    <div className={eidtStyle.teammemberMain}>
      <div className={eidtStyle.teammemberContent}>
        <form action="" className={eidtStyle.teammemberForm} onSubmit={handleSubmit} encType='multipart/form-data'>
          <label className={eidtStyle.teammemberFormLabel}>Name</label>
          <input className={eidtStyle.teammemberFormText} type="text" value={graphName} name='graphName' onChange={(event) => setgraphName(event.target.value)} />
          <label className={eidtStyle.teammemberFormLabel}>Description</label>
          <textarea className={eidtStyle.teammemberFormDesc} value={graphDesc} name='graphDesc' onChange={(event) => setgraphDesc(event.target.value)} />
          <label className={eidtStyle.teammemberFormLabel}>Upload</label>
          <div className={eidtStyle.ImgInput}>
            <input
              ref={inputRef}
              className={eidtStyle.ImgInput_input}
              type="file"
              onChange={handleFileChange}
            />
            {!source && <button type='button' onClick={handleChoose}>Browse</button>}

            {console.log(source)}
            {source && (
              <img
                className={eidtStyle.ImgInput_img}
                width="100%"
                // src={`${process.env.REACT_APP_GECEADMIN}/graph/file/${graphImg}`}
                src={source}
                alt='uploadedimage'
              />
            )}
          </div>
          <div className={eidtStyle.ImgInput_footer}>
            {imageSelect ?
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                {fileName}
              </div> :
              ''}
          </div>
          <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
          <Button variant='contained' type='submit' className={eidtStyle.saveChanges}>Save changes</Button>
        </form>
      </div>
    </div>
  )
}

export default EditMarket