import React from 'react'
import ViewApplicantStyles from "./ViewApplicants.module.css"
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import Modal from '@mui/material/Modal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import axios from 'axios';


  // import this for redux read write
  import { useSelector, useDispatch } from 'react-redux'
  import { setNotifications } from '../../state/GlobalState'
  import { setNotificationCount } from '../../state/GlobalState'


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


function createData(id, name, email, number, date, job) {
    return { id, name, email, number, date, job };
}

const rows = [
    createData(1, 'Joseph William 1', 'Joseph@mail.com', 901235456, "12.07.2018", "JS Developer"),
    createData(2, 'Ashy Handgun 2', 'Akshay@mail.com', 1403500365, "12.07.2018", "JS Developer"),
    createData(3, 'Larry Doe 3', 'larry@mail.com', 6048323973, "12.07.2018", "JS Developer"),
    createData(4, 'Sara Soudan 4', 'Sara@mail.com', 3272117434, "12.07.2018", "JS Developer"),
    createData(5, 'Larry Doe 3', 'larry@mail.com', 3760221303, "12.07.2018", "JS Developer"),
];

const ViewApplicants = () => {

    
       // get data from redux
       const name=useSelector((state)=>state.globalData.name);
       const count=useSelector((state)=>state.globalData.notificationCount);
       const dispatch =  useDispatch()

   
    const location = useLocation()
    const { state }=location;
    console.log(state)
    const { row, category } = state || {}

    console.log(row)
    console.log(category)

    const navigate = useNavigate()

    const [jobRole, setJobRole] = React.useState("");

    const handleJobRoles = (event) => {
        setJobRole(event.target.value);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [query, setQuery] = useState("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteApplicantbyId = async (resumeId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/career/deleteById/${resumeId}`
            )
            console.log(res)

            // this is the snipte for the notification

       const dateTime=res.data.date;

       const [dateString,timeString]=dateTime.split("T");

       const date=new Date(dateString);

       const time=timeString.substr(0,8);


       const localDate=date.toLocaleDateString();

       dispatch(setNotifications({
           notification:{
             name:name,
             details:res.data.message,
             time:time,
             date:localDate
           }
         }))
         dispatch(setNotificationCount({
          count:count+1,
         }))
         
       //   uptill here 

            alert('Delete Successful!..')
            navigate('/jobroles')
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Card sx={{maxWidth:'95%', border: 'none', outline:'none',boxShadow:'none', mt:'3%'  }}>
                <CardContent>
                    <Paper className={ViewApplicantStyles.myboder}>
                        <TableContainer sx={{ maxHeight: 650 }}>
                            <div className={ViewApplicantStyles.viewApplicantListBox1}>
                                <Box className={ViewApplicantStyles.Box1}>
                                    <Typography variant='h5'>{row?.careerJobRoleName}</Typography>
                                    <Typography variant='h6'>6 Applications</Typography>
                                </Box>
                            </div>
                            <div className={ViewApplicantStyles.viewApplicantListBox2}>
                                <Box className={ViewApplicantStyles.Box1}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon sx={{ zIndex: '1' }} />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            onChange={(e) => setQuery(e.target.value)}
                                            inputProps={{ 'aria-label': 'search' }}
                                            sx={{ width: '100%', color: '#121926', backgroundColor: '#F8FAFC', borderRadius: '10px', border: '1px solid #bdbdbd' }}
                                        />
                                    </Search>
                                </Box>
                            </div>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className={ViewApplicantStyles.Mainhead}>
                                        <TableCell className={ViewApplicantStyles.Tablehead}>Name</TableCell>
                                        <TableCell className={ViewApplicantStyles.Tablehead}>Contact No.</TableCell>
                                        <TableCell className={ViewApplicantStyles.Tablehead}>Applied On</TableCell>
                                        <TableCell className={ViewApplicantStyles.Tablehead}>Applied For</TableCell>
                                        <TableCell className={ViewApplicantStyles.Tablehead}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.resume
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .filter(applicant => applicant?.userAppForm?.applicantName?.toLowerCase().includes(query)).map((applicant) => {
                                            return (
                                                <TableRow className={ViewApplicantStyles.MainRow} hover role="checkbox" tabIndex={-1} key={applicant?.userAppForm?.applicationId} >
                                                    <TableCell className={ViewApplicantStyles.Tablerow1}> {applicant?.userAppForm?.applicantName}<Box className={ViewApplicantStyles.Email}>{applicant?.userAppForm?.applicantEmail}</Box></TableCell>
                                                    <TableCell className={ViewApplicantStyles.Tablerow}>{applicant?.userAppForm?.applicantContact} </TableCell>
                                                    <TableCell className={ViewApplicantStyles.Tablerow}>{applicant?.userAppForm?.date} </TableCell>
                                                    <TableCell className={ViewApplicantStyles.Tablerow}>{row?.careerJobRoleName}</TableCell>
                                                    <TableCell className={ViewApplicantStyles.Tablerow}>
                                                        <Link to={`/applicantdetails/${applicant?.resumeId}`} state={{row: row, applicant: applicant}}>
                                                            <VisibilityOutlinedIcon className={ViewApplicantStyles.Tableicon} />
                                                        </Link>
                                                        <Link disableRipple onClick={() => deleteApplicantbyId(applicant.resumeId)}>
                                                        <DeleteForeverOutlinedIcon style={{marginLeft:'10px'}} className={ViewApplicantStyles.Tableicon} />
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{paddingTop:'2%'}}
                        />
                    </Paper>
                </CardContent>
            </Card>
        </>
    )
}

export default ViewApplicants