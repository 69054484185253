

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { Image, Video } from 'cloudinary-react';
import Dashboard from '../Dashboard/Dashboard';
// import Users from '../Users/Users';
// import Ticket from '../Ticket/Ticket';
import Notifications from '../Notifications/Notifications';
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import Collapse from '@mui/material/Collapse';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import StarBorder from '@mui/icons-material/StarBorder';
import Homepage from "../Homepage/Homepage";
import AboutUs from "../AboutUs/AboutUs";
import UserTicket from "../UserTicket/UserTicket";
// import Roles from "../Roles/Roles";
import { FaBars } from 'react-icons/fa';
import { Button } from "@mui/material";
import DrawerStyles from './Drawer.module.css'
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { Details, Logout, SecurityTwoTone } from "@mui/icons-material";
// import AddUser from '../AddUser/AddUser'
// import EditUser from '../EditUser/EditUser'
import EditHomeBanner from "../EditHomeBanner/EditHomeBanner";
// import Career from "../Career/Career";
import Investor from "../Investor/Investor";
// import Media from "../Media/Media";
import ContactUs from "../ContactUs/ContactUs";
import EditOurBusiness from "../EditOurBusiness/EditOurBusiness";
import EditTeamMember from "../EditTeamMember/EditTeamMember";
import MeetOurTeam from "../MeetOurTeam/MeetOurTeam";
import OurBusiness from "../OurBusiness/OurBusiness";
import InvestmentHighlights from "../InvestmentHighlights/InvestmentHighlights";
import EditInvestHighlight from "../EditInvestHighlight/EditInvestHighlight";
import CompanyDetails from "../CompanyDetails/CompanyDetails";
import EditCompanyDetail from "../EditCompanyDetail/EditCompanyDetail";
import UpcomingEvents from "../UpcomingEvents/UpcomingEvents";
import EditEvent from "../EditEvent/EditEvent";
import Gallery from "../Gallery/Gallery";
import EditGallery from "../EditGallery/EditGallery";
import BranchAddress from "../BranchAddress/BranchAddress";
import EditAddress from "../EditAddress/EditAddress";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material/Search";
import JobApplicants from "../JobApplicants/JobApplicants";
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import JobRoles from "../JobRoles/JobRoles";
import ViewApplicants from "../ViewApplicants/ViewApplicants";
import AddOurBusiness from "../AddOurBusiness/AddOurBusiness";
import AddTeamMember from "../AddTeamMember/AddTeamMember";
import AddInvestHighlight from "../AddInvestHighlight/AddInvestHighlight";
import AddCompanyDetail from "../AddCompanyDetail/AddCompanyDetail";
import AddUpcomingEvent from "../AddUpcomingEvent/AddUpcomingEvent";
import AddGallery from "../AddGallery/AddGallery";
import AddAddress from "../AddAddress/AddAddress";
import Applicant from "../Applicant/Applicant";
import ContactsList from "../ContactsList/ContactsList";
import PostJob from "../PostJob/PostJob";
import ApplicantDetails from "../ApplicantDetails/ApplicantDetails";
import EditJob from "../EditJob/EditJob";
// import axios from "axios";
import { setLogout } from '../../state/GlobalState'
import NotificationsSharpIcon from '@mui/icons-material/NotificationsSharp';
import { useLocation } from 'react-router-dom';

import Badge from "@mui/material/Badge";
import ButtonGroup from "@mui/material/ButtonGroup";

// import AddIcon from "@mui/icons-material/Add";


// this is the import line from redux selector and dipatch
import { useSelector, useDispatch } from 'react-redux'

import { setNotificationCount } from "../../state/GlobalState";
import { setNotifications } from "../../state/GlobalState";


import { useEffect } from "react";
// import { NavLink, useNavigate } from 'react-router-dom'
import { isLoggedIn, doLogout } from "../../auth";
import PrivateRoute from "../PrivateRoute/PrivateRoute";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InvestorOpportunities from "../InvestorOpportunities/InvestorOpportunities";
import AddInvOpportunities from "../AddInvOpportunities/AddInvOpportunities";
import EditInvOpportunities from "../EditInvOpportunities/EditInvOpportunities";
import EditInvOppo2 from "../EditInvOppo2/EditInvOppo2";
import InvRequest from "../InvRequest/InvRequest";
import AddFormat from "../AddFormat/AddFormat";
import AddPitch from "../AddPitch/AddPitch";
import ShortSummary from "../ShortSummary/ShortSummary";
import TheMarket from "../TheMarket/TheMarket";
import Ourteam from "../OurTeam/OurTeam";
import TheBusiness from "../TheBusiness/TheBusiness";
import AddMarket from "../AddMarket/AddMarket";
import EditMarket from "../EditMarket/EditMarket";
import AddBusin from "../AddBusiness/AddBusin";
import Editbusi from "../Editbusi/Editbusi";
import AddOur from "../AddOur/AddOur";
import EditOur from "../EditOur/EditOur";
import Document from "../Document/Document";
import AddDoc from "../AddDoc/AddDoc";
import EditDoc from "../EditDoc/EditDoc";
import EditPdf from "../EditPdf/EditPdf";
import EditVideo from "../EditVideo/EditVideo";
import VideoFor from "../VideoFor/VideoFor";
import Interested from "../Interested/Interested";
import CreateLink from "../CreateLink/CreateLink";
import EditInvestmentBanner from "../InvestorOpportunities/EditInvestmentBanner";
import TestimonialDisplay from "../Testimonial/TestimonialDisplay";
import AddTestimonial from "../AddTestimonial/AddTestimonial";
import EditTestimonial from "../EditTestimonial/EditTestimonial";
import Project from "../Project/Project";
import AddProject from "../AddProject/AddProject";
import EditProject from "../EditProject/EditProject";
import geceLogo from '../../assets/images/GeceLogo.svg'
import EditAboutUs from "../EditAboutUs/EditAboutUs";
import ITService from "../ITService/ITService";
import ITHighlights from "../ITHighlights/ITHighlights";
import AddITHighlights from "../AddITHighlights/AddITHighlights";
import EditITHighlight from "../EditITHighlight/EditITHighlight";


const drawerWidth = 240;

// Responsive code

export default function ClippedDrawer() {

  const [selectedTab, setSelectedTab] = useState("Dashboard");

  // Function to handle tab selection
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };
  // const [count, setCount] = React.useState(0);

  // const resetCount = () => {
  //   setCount(0);
  // };

  const location = useLocation();

  // const navigate = useNavigate()
  const [login, setLogin] = useState(false)
  // const [user, setUser] = useState(undefined)
  const [mail, setMail] = useState(undefined)
  const dispatch = useDispatch();
  // useEffect(() => {
  //   setLogin(isLoggedIn())
  //   const data = JSON.parse(localStorage.getItem('data'))
  //   setUser(data?.name || data?.sendDetails?.name)
  //   // setMail(data?.usernameOrEmail || data?.sendDetails?.usernameOrEmail)
  // }, [login])

  const logout = () => {

    dispatch(setLogout())

  }


  // this is the line to get data from redux
  const count = useSelector((state) => state.globalData.notificationCount);
  const user = useSelector((state) => state.globalData.user);
  const name = useSelector((state) => state.globalData.name);
  // const details=useSelector((state)=>state.globalData.details);
  console.log(count);






  const incCount = () => {
    let tempcount = count + 1;
    dispatch(setNotificationCount({ count: tempcount }))
  }

  const resetCount = () => {
    dispatch(setNotificationCount({ count: 0 }))
  }










  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen)

  const [openUser, setOpenUser] = React.useState(false);
  const [openContent, setOpenContent] = React.useState(false)
  const [openJobPortal, setOpenJobPortal] = React.useState(false)
  const [openInvestor, setOpenInvestor] = React.useState(false)

  const handleAllClose = () => {
    setOpenContent(false)
    setOpenUser(false);

    setOpenJobPortal(false)
    setOpenInvestor(false)
  }
  const handleUsersClick = () => {
    setOpenUser(!openUser);
    setOpenContent(false)
    setOpenJobPortal(false)
    setOpenInvestor(false)
  };

  const handleContentClick = () => {
    setOpenContent(!openContent)
    setOpenUser(false);

    setOpenJobPortal(false)
    setOpenInvestor(false)
  }

  const handleJobClick = () => {
    setOpenJobPortal(!openJobPortal)
    setOpenUser(false);
    setOpenContent(false)

    setOpenInvestor(false)
  }
  const handleInvestorClick = () => {
    setOpenInvestor(!openInvestor)
    setOpenUser(false);
    setOpenContent(false)
    setOpenJobPortal(false)

  }

  const navigate = useNavigate()

  const activeTab = (route) => {
    navigate(`${route}`)
    return {
      color: '#f6faff',
    }
  }

  // profile section  

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Profile Menu 

  const renderMenu = (
    <Menu
      sx={{ mt: isMenuOpen ? '49px' : '0px', }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} sx={{ display: 'flex', gap: '10px', }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', }}>
          <Image
            publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1683702654/Ellipse_kqekbg.svg"
            cloudName="dxluokypg"
          />



        </Box>
        <Box >
          <Typography variant="h3" sx={{ fontSize: '12px' }}>{name}</Typography>
          <Typography variant="h4" sx={{ fontSize: '12px' }}>{user}</Typography>
          {/* <Typography variant="h4" sx={{ fontSize: '12px' }}>{details}</Typography> */}
        </Box>
      </MenuItem>
      {/* <MenuItem onClick={handleMenuClose} sx={{ gap: '5%' }}>
        <PersonOutlineIcon />My Profile
      </MenuItem> */}
      <MenuItem onClick={logout} sx={{ gap: '5%', mb: '5%', mt: '5%' }}>
        <LogoutIcon /> Logout
      </MenuItem>
    </Menu>
  );


  // profile working 

  const renderMobileMenu = (
    <Menu sx={{ mt: '25%' }}>
      <MenuItem onClick={handleProfileMenuOpen} sx={{ mt: '25%' }}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          sx={{ mt: '25%' }}
        >
          <AccountCircle />
        </IconButton>
        <Typography variant="h6" sx={{ mt: '25%' }}>Profile</Typography>
      </MenuItem>
    </Menu>
  );


  const Search = styled("div")(({ theme }) => ({
    position: "relative",

    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    // [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(3),
    // width: "auto"
    // }
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#1F384C",

    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      // transition: theme.transitions.create("width"),
      width: "100%"
    }
  }));


  return (
    <Box sx={{ display: "flex", justifyContent: 'space-evenly' }}>
      <CssBaseline />
      {/* navbar Responsive  */}
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: 'white', paddingBottom: '8px', boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)', }}
      >
        <Toolbar>
          <div>
            <Button sx={{ display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' }, fontSize: '1.175rem', p: 'none' }}>
              <FaBars onClick={toggle} />
            </Button>

          </div>
          <Link to='/dashboard' style={{marginTop:'0.5%'}}>
            {/* <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1682507755/KJSSCosmosAdmin_mprddf.svg" cloudName="dxluokypg"  /> */}
            <img width={"90px"} src={geceLogo} alt="" />

          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' }, justifyContent: 'center', ml: { xs: '', sm: '', md: '10%', lg: '15%' } }}>
            <AppBar position="static" sx={{
              bgcolor: 'white', boxShadow: 'none'
            }}>
              <Toolbar >
                {/* <Search sx={{
                  bgcolor: '#F6F6FB',
                  "&:hover": {
                    backgroundColor: '#F6F6FB'
                  },
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                  <SearchIconWrapper sx={{ paddingLeft: '0px', paddingRight: '15px' }}>
                    <SearchIcon sx={{ color: '#627B87' }} />
                  </SearchIconWrapper>
                  <StyledInputBase sx={{ flexGrow: 1, marginLeft: '-25px', fontSize: '14px', color: '#627B87', '&::placeholder': { color: '#A8B5C2', textAlign: 'left', marginRight: '0px' } }} placeholder="Search…" />
                </Search> */}
              </Toolbar>
            </AppBar>
          </Box>

          {/* <ButtonGroup>
        <Button
          aria-label="increase"
          onClick={incCount}
        >
          <AddIcon fontSize="small" />
        </Button>
      </ButtonGroup> */}



          <Box sx={{ flexGrow: 1, }}>
            <AppBar position="static" sx={{ bgcolor: "transparent", boxShadow: 'none' }}>
              <Toolbar>
                <Box sx={{ flexGrow: 1, }} />
                <Box sx={{
                  display: 'flex', alignItems: 'center', justifyContent: "center",
                  textAlign: "center",
                }}>
                  <IconButton
                    size="large"
                    edge="start"
                    aria-label="account of current user"
                    // aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    sx={{ borderRadius: '0%', gap: { lg: '5px' }, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}
                  >
                    {/* <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1683702654/Ellipse_kqekbg.svg" cloudName="dxluokypg" /> */}
                    <AccountCircleIcon sx={{ color: 'black' }} />


                    <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' }, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography sx={{ color: 'grey', textAlign: 'center', marginBottom: '2px' }}>{name}</Typography>
                      <KeyboardArrowDownIcon sx={{ color: 'grey' }} />
                    </Box>
                  </IconButton>
                </Box>
                {/* <IconButton sx={{ display: {xs:'none',sm:'flex',md:'flex',lg:'flex'},}} ><Image  publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1683702654/Notif_Icon_pm3pss.svg" cloudName="dxluokypg" /></IconButton> */}

                {/* Notification icon strat   */}


                <Link to='/notifications'>
                  <Badge color="secondary" badgeContent={count} onClick={resetCount}>
                    <NotificationsRoundedIcon sx={{ color: 'black', cursor: 'pointer' }} />
                  </Badge>
                </Link>



                {/* Notification icon end  */}
              </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
          </Box>

        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? "temporary" : "persistent"}
        open={!isMobile || isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box"
          }
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto", pt: '24px', color: '#585858' }}>
          <List>
            <ListItem disablePadding onClick={() => { navigate('/dashboard'); handleAllClose() }}>
              <ListItemButton sx={{
                backgroundColor: location.pathname === '/dashboard' ? '#f6faff' : 'transparent',
                color: location.pathname === '/dashboard' ? 'darkblue' : 'inherit',
              }}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <DashboardRoundedIcon sx={{
                    color: location.pathname === '/dashboard' ? 'darkblue' : 'inherit',
                  }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Dashboard</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => { navigate('/leadslist'); handleAllClose() }}
                sx={{
                  backgroundColor: location.pathname === '/leadslist' ? '#E3F5F' : 'transparent',
                  color: location.pathname === '/leadslist' ? 'darkblue' : 'inherit',
                }}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <PersonRoundedIcon sx={{
                    color: location.pathname === '/leadslist' ? 'darkblue' : 'inherit',
                  }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Leads</ListItemText>
              </ListItemButton>
            </ListItem>


            {/* ---------------------------------------- */}
            <ListItem disablePadding>
              <ListItemButton onClick={handleInvestorClick} sx={{ backgroundColor: openInvestor ? "#f6faff" : "#ffffff" }}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <NotificationsSharpIcon sx={{ color: openInvestor ? "darkblue" : "#585858" }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500', color: openInvestor ? "darkblue" : "#585858" }}>Investors</ListItemText>
              </ListItemButton>
            </ListItem>
            <Collapse in={openInvestor} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => navigate('/interest')}
                  sx={{
                    pl: 7,
                    backgroundColor: location.pathname === '/interest' ? '#E3F5F' : 'transparent',
                    color: location.pathname === '/interest' ? 'darkblue' : 'inherit',
                  }}>
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Prospects</ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            <Collapse in={openInvestor} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => navigate('/request')}
                  sx={{
                    pl: 7,
                    backgroundColor: location.pathname === '/request' ? '#E3F5F' : 'transparent',
                    color: location.pathname === '/request' ? 'darkblue' : 'inherit',
                  }}>
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Request</ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            {/* ---------------------------------------- */}



            <ListItem disablePadding onClick={() => { navigate('/ticket'); handleAllClose() }}>
              <ListItemButton sx={{
                backgroundColor: location.pathname === '/ticket' ? '#E3F5F' : 'transparent',
                color: location.pathname === '/ticket' ? 'darkblue' : 'inherit',
              }} >
                <ListItemIcon sx={{
                  minWidth: '40px',
                  color: location.pathname === '/ticket' ? 'darkblue' : 'inherit',
                }}>
                  {/* <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1682490031/Ticket_wq60fu.svg" cloudName="dxluokypg" /> */}
                  <ConfirmationNumberRoundedIcon />
                </ListItemIcon >
                <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Ticket</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding onClick={() => { navigate('/notifications'); handleAllClose() }}>
              <ListItemButton sx={{
                backgroundColor: location.pathname === '/notifications' ? '#E3F5F' : 'transparent',
                color: location.pathname === '/notifications' ? 'darkblue' : 'inherit',
              }}>
                <ListItemIcon sx={{
                  minWidth: '40px',
                  color: location.pathname === '/notifications' ? 'darkblue' : 'inherit',
                }}>
                  {/* <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1682490032/Notif_d4dyqi.svg" cloudName="dxluokypg" /> */}
                  <NotificationsRoundedIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Notifications</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleContentClick} >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <ArticleRoundedIcon sx={{ color: openContent ? "darkblue" : "#585858" }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500', color: openContent ? "darkblue" : "#585858" }}>Content Management</ListItemText>
              </ListItemButton>
            </ListItem>
            <Collapse in={openContent} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => navigate('/homepage')}
                  sx={{
                    pl: 7,
                    backgroundColor: location.pathname === '/homepage' ? '#E3F5F' : 'transparent',
                    color: location.pathname === '/homepage' ? 'darkblue' : 'inherit',
                  }}>
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Homepage</ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            <Collapse in={openContent} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => navigate('/aboutUs')}
                  sx={{
                    pl: 7,
                    backgroundColor: location.pathname === '/aboutUs' ? '#E3F5F' : 'transparent',
                    color: location.pathname === '/aboutUs' ? 'darkblue' : 'inherit',
                  }}>
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>About Us</ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            
            <Collapse in={openContent} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => navigate('/itservice')}
                  sx={{
                    pl: 7,
                    backgroundColor: location.pathname === '/itservice' ? '#E3F5F' : 'transparent',
                    color: location.pathname === '/itservice' ? 'darkblue' : 'inherit',
                  }}>
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>IT Service</ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            <Collapse in={openContent} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => navigate('/investor')}
                  sx={{
                    pl: 7,
                    backgroundColor: location.pathname === '/investor' ? '#E3F5F' : 'transparent',
                    color: location.pathname === '/investor' ? 'darkblue' : 'inherit',
                  }}>
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Investor</ListItemText>
                </ListItemButton>
              </List>
            </Collapse>

            <Collapse
                  in={openContent}
                  timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{
                        pl: 7,
                        backgroundColor: location.pathname === '/project' ? '#E3F5F' : 'transparent',
                        color: location.pathname === '/project' ? 'darkblue' : 'inherit',
                      }}
                      onClick={() => navigate('/project')} selected={location.pathname === '/project'}>
                      <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Projects</ListItemText>
                    </ListItemButton>
                  </List>
                </Collapse>
                
            {/* <Collapse in={openContent} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => navigate('/media')}
                  sx={{
                    pl: 7,
                    backgroundColor: location.pathname === '/media' ? '#E3F5F' : 'transparent',
                    color: location.pathname === '/media' ? 'darkblue' : 'inherit',
                  }}>
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Media</ListItemText>
                </ListItemButton>
              </List>
            </Collapse> */}
            <Collapse in={openContent} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => navigate('/contact us')}
                  sx={{
                    pl: 7,
                    backgroundColor: location.pathname === '/contact%20us' ? '#E3F5F' : 'transparent',
                    color: location.pathname === '/contact%20us' ? 'darkblue' : 'inherit',
                  }}>
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Contact Us</ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItem disablePadding>
              <ListItemButton onClick={handleJobClick} sx={{ backgroundColor: openJobPortal ? "#f6faff" : "#ffffff" }}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <BadgeRoundedIcon sx={{ color: openJobPortal ? "darkblue" : "#585858" }} />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500', color: openJobPortal ? "darkblue" : "#585858" }}>Job Portal</ListItemText>
              </ListItemButton>
            </ListItem>
            <Collapse in={openJobPortal} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => navigate('/jobapplicants')}
                  sx={{
                    pl: 7,
                    backgroundColor: location.pathname === '/jobapplicants' ? '#E3F5F' : 'transparent',
                    color: location.pathname === '/jobapplicants' ? 'darkblue' : 'inherit',
                  }}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Job Applicants</ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            <Collapse in={openJobPortal} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton onClick={() => navigate('/jobroles')}
                  sx={{
                    pl: 7,
                    backgroundColor: location.pathname === '/jobroles' ? '#E3F5F' : 'transparent',
                    color: location.pathname === '/jobroles' ? 'darkblue' : 'inherit',
                  }}>
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}>Manage Job Roles</ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, bgcolor: { xs: '#ffff', sm: '#f9f9f9', md: '#f9f9f9', lg: '#f9f9f9' } }}>
        <Toolbar />
        <Routes>

          <Route path='/' element={<Dashboard />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/leadslist' element={<ContactsList />} />
          {/* <Route path='/role' element={<Roles />} /> */}
          {/* <Route path='/add user' element={<AddUser />} />
          <Route path='/edit user' element={<EditUser />} /> */}
          <Route path='/ticket' element={<UserTicket />} />
          <Route path='/notifications' element={<Notifications />} />
          <Route path='/homepage' element={<Homepage />} />
          <Route path='/aboutUs' element={<AboutUs />} />
          <Route path='/homebanner' element={<EditHomeBanner />} />
          <Route path='/editAboutUs' element={<EditAboutUs />} />
          <Route path='/ourbusiness' element={<OurBusiness />} />
          <Route path='/addbusiness' element={<AddOurBusiness />} />
          <Route path='/business/:id' element={<EditOurBusiness />} />
          <Route path='/meetourteam' element={<MeetOurTeam />} />
          <Route path='/addteammember' element={<AddTeamMember />} />
          <Route path='/teammember/:id' element={<EditTeamMember />} />
          <Route path='/investor' element={<Investor />} />
          <Route path='/investment' element={<InvestmentHighlights />} />
          <Route path='/addinvestment' element={<AddInvestHighlight />} />
          <Route path='/editinvestment/:id' element={<EditInvestHighlight />} />
          <Route path='/companydetails' element={<CompanyDetails />} />
          <Route path='/adddetail' element={<AddCompanyDetail />} />
          <Route path='/editdetail/:id' element={<EditCompanyDetail />} />

          <Route path='/project' element={<Project />} />
          <Route path='/addproject' element={<AddProject />} />
          <Route path='/editproject' element={<EditProject />} />

          {/* <Route path='/media' element={<Media />} /> */}
          <Route path='/upcomingevents' element={<UpcomingEvents />} />
          <Route path='/addevent' element={<AddUpcomingEvent />} />
          <Route path='/editevent/:id' element={<EditEvent />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/addgallery' element={<AddGallery />} />
          <Route path='/editgallery/:id' element={<EditGallery />} />
          <Route path='/contact us' element={<ContactUs />} />
          <Route path='/branchaddress' element={<BranchAddress />} />
          <Route path='/addaddress' element={<AddAddress />} />
          <Route path='/editaddress/:id' element={<EditAddress />} />
          <Route path='/jobapplicants' element={<JobApplicants />} />
          <Route path='/editInvestmentBanner/:id' element={<EditInvestmentBanner />} />

          <Route path='/applicants/:id' element={<Applicant />} />
          <Route path='/jobroles' element={<JobRoles />} />
          <Route path='/postjob' element={<PostJob />} />
          <Route path='/editjob/:id' element={<EditJob />} />
          <Route path='/viewapplicants/:id' element={<ViewApplicants />} />
          <Route path='/applicantdetails/:id' element={<ApplicantDetails />} />
          {/* <Route path='/adduser' element={<AddUser />} /> */}
          {/* <Route path='/edituser' element={<EditUser />} /> */}
          {/* <Route path='/edituser' element={<EditUser />} /> */}
          <Route path='/itservice' element={<ITService />} />
          <Route path='/ithighlights' element={<ITHighlights/>} />
          <Route path='/addItHighlight' element={<AddITHighlights />} />
          <Route path='/addItHighlight/:id' element={<EditITHighlight />} />
         



          <Route path='/testimonial' element={<TestimonialDisplay />} />
          <Route path='/addtestimonial' element={<AddTestimonial />} />
          <Route path='/edittestimonial' element={<EditTestimonial />} />

          <Route path='/InvestmentOpp' element={<InvestorOpportunities />} />
          <Route path='/AddInvOppo' element={<AddInvOpportunities />} />
          <Route path='/EditInvOppo/:id' element={<EditInvOpportunities />} />
          <Route path='/EditInv2' element={<EditInvOppo2 />} />



          <Route path='/request' element={<InvRequest />} />
          <Route path='/interest' element={<Interested />} />

          <Route path='/formates' element={<AddFormat />} />
          <Route path='/videofor' element={<VideoFor />} />

          <Route path='/pitch/:id/:id2/:summeryId' element={<AddPitch />} />

          <Route path='/summary/:id/:id2/:summaryId' element={<ShortSummary />} />

          <Route path='/market/:id/:id2' element={<TheMarket />} />
          <Route path='/addmarket/:id/:id2' element={<AddMarket />} />
          <Route path='/editmarket/:id/:id2/:id3' element={<EditMarket />} />

          <Route path='/our/:id/:id2' element={<Ourteam />} />
          <Route path='/addour/:id/:id2' element={<AddOur />} />
          <Route path='/editour/:id/:id2/:id3' element={<EditOur />} />

          <Route path='/theBusi/:id/:id2' element={<TheBusiness />} />
          <Route path='/addBusi/:id/:id2' element={<AddBusin />} />
          <Route path='/editBusi/:id/:id2/:id3' element={<Editbusi />} />

          <Route path='/doc/:id/:id2' element={<Document />} />
          <Route path='/adddoc/:id/:id2' element={<AddDoc />} />
          <Route path='/editdoc/:id/:id2/:id3' element={<EditDoc />} />

          <Route path='/editpdf/:id/:id2/:id3' element={<EditPdf />} />
          <Route path='/editVi/:id/:id2/:id3' element={<EditVideo />} />



          <Route path='/createL/:id/:invName' element={<CreateLink />} />
        </Routes>
      </Box>
    </Box>
  );
}

