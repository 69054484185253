import React from 'react'
import ReqStyle from "./Interested.module.css"
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';


// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid gray',
    boxShadow: '2px 2px 10px 2px gray',
    borderRadius: '5px',
    p: 4,
};

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));



function createData(id, name, email, number, register, role) {
    return { id, name, email, number, register, role };
}

const rows = [
    createData(1, 'Joseph William 1', 'Joseph@mail.com', 901235456, "12.07.2018", "Admin"),
    createData(2, 'Ashy Handgun 2', 'Akshay@mail.com', 1403500365, "12.07.2018", "Super Admin"),
    createData(3, 'Larry Doe 3', 'larry@mail.com', 6048323973, "12.07.2018", "user"),
    createData(4, 'Sara Soudan 4', 'Sara@mail.com', 3272117434, "12.07.2018", "Admin"),
    createData(5, 'Larry Doe 3', 'larry@mail.com', 3760221303, "12.07.2018", "Super Admin"),
    createData(6, 'Joseph William 1', 'Joseph@mail.com', 925475400, "12.07.2018", "user"),
    createData(7, 'Sara Soudan 4', 'Sara@mail.com', 830192060, "12.07.2018", "Admin"),
    createData(8, 'Joseph William 1', 'Joseph@mail.com', 485237000, "12.07.2018", "Super Admin"),
    createData(9, 'Ashy Handgun 2', 'Akshay@mail.com', 126577691, "12.07.2018", "user"),
    createData(10, 'Larry Doe 3', 'larry@mail.com', 126317000, "12.07.2018", "Super Admin"),
    createData(11, 'Sara Soudan 4', 'Sara@mail.com', 678022000, "12.07.2018", "Admin"),
    createData(12, 'Joseph William 1', 'Joseph@mail.com', 672545757, "12.07.2018", "user"),
    createData(13, 'Jack parrow', 'Akshay@mail.com', 146793744, "12.07.2018", "Admin"),
    createData(14, 'Larry Doe 3', 'larry@mail.com', 2009624137, "12.07.2018", "Super Admin"),
    createData(15, 'Joseph William 5', 'larry@mail.com', 210147125, "12.07.2018", "user"),
];
function Interested() {

    // get data from redux
    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false);
    const handleOpen = (row) => {
        setOpen(true)
        setSelectedData(row)
    };
    const handleClose = () => setOpen(false);

    const [query, setQuery] = useState("");
    const [rowCount, setRowCount] = useState(10);


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const [data, setData] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [entryCount,setEntryCount]=useState("");


    const getReqData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/investorContacts/getAllInvestorContacts`
            ).then((res)=>{
                setData(res.data.reverse())
                console.log(res.data)
            })
            
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        setEntryCount(data.length);
    },[data])

    const deletegetReqId = async (contactUsId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/contactUs/deletegetReqId/${contactUsId}`
            )
            console.log(deletegetReqId);

            console.log(res);

            const dateTime = res.data.date;
            const [dateString, timeString] = dateTime.split("T");
            const date = new Date(dateString);
            const time = timeString.substr(0, 8);
            const localDate = date.toLocaleDateString();

            dispatch(setNotifications({
                notification: {
                    name: name,
                    details: res.data.message,
                    time: time,
                    date: localDate
                }
            }))
            dispatch(setNotificationCount({
                count: count + 1,
            }))

            //   uptill here 



            alert('Delete Successful!')
            // window.location.reload()
            getReqData()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getReqData()
    }, [])


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };




    const filteredData = data
    .filter(row => String(row.username).includes(query))

    useEffect(() => {
        setRowCount(filteredData.length)
        setPage(0);
    }, [data, query])

    return (
        <>
            <Card sx={{ maxWidth: '95%', border: 'none', outline: 'none', boxShadow: 'none', mt: '3%' }}>
                <CardContent>
                    <Paper className={ReqStyle.myboder}>
                        <TableContainer sx={{ maxHeight: 650 }}>
                            <div className={ReqStyle.USerListBox1}>
                                <Box className={ReqStyle.Box1}>
                                    <Typography variant='h5'>Prospects</Typography>
                                    <Typography variant='h6'>{entryCount} entries</Typography>
                                </Box>
                            </div>
                            <div className={ReqStyle.USerListBox2}>
                                <Box className={ReqStyle.Box1}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon sx={{ zIndex: '1', color: '#727374' }} />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            onChange={(e) => setQuery(e.target.value)}
                                            inputProps={{ 'aria-label': 'search' }}
                                            sx={{ width: '100%', color: '##121926', backgroundColor: '#F8FAFC', borderRadius: '10px', border: '1px solid #Bdbdbd' }}
                                        />
                                    </Search>
                                </Box>
                                {/* <Box className={ReqStyle.Box2}>
                                    <FileCopyOutlinedIcon />
                                    <PrintOutlinedIcon />
                                    <FilterListIcon />
                                </Box> */}
                            </div>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className={ReqStyle.Mainhead}>
                                        <TableCell className={ReqStyle.Tablehead}>User</TableCell>
                                        <TableCell className={ReqStyle.Tablehead}>Phone Number</TableCell>
                                        <TableCell className={ReqStyle.Tablehead}>Submitted On</TableCell>
                                        <TableCell className={ReqStyle.Tablehead}>IP Address</TableCell>
                                        {/* <TableCell className={ReqStyle.Tablehead}>Message</TableCell> */}
                                        {/* <TableCell className={ReqStyle.Tablehead}>Action</TableCell>
                                         */}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* {data
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .filter(row => row.contactName?.toLowerCase().includes(query)).map((row) => { */}
                                           {filteredData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow className={ReqStyle.MainRow} hover role="checkbox" tabIndex={-1} key={row.contactId} >
                                                    <TableCell className={ReqStyle.Tablerow1}> {row.contactName}<Box className={ReqStyle.Email}>{row.contactEmail}</Box></TableCell>
                                                    <TableCell className={ReqStyle.Tablerow}>{row.contactPhoneNumber} </TableCell>
                                                    <TableCell className={ReqStyle.Tablerow}>{row.date} </TableCell>
                                                    <TableCell className={ReqStyle.Tablerow}>{row.ipAddress} </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={rowCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ paddingTop: '2%' }}
                        />
                    </Paper>
                </CardContent>
            </Card>

        </>
    );
}

export default Interested
