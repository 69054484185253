import React from 'react'
import ITHighStyles from './ITHighlights.module.css'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';


// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'

import { fetchNotifications } from '../../state/GlobalState'

const ITHighlights = () => {

     // get data from redux
     const name=useSelector((state)=>state.globalData.name);
     const count=useSelector((state)=>state.globalData.notificationCount);
     const dispatch =  useDispatch()



    const [data, setData] = useState([])

    const getAllItsData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/itServicesHighlights/getAllItServiceHighlights`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteITHighlightbyId = async (logoId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/itServicesHighlights/deleteById/${logoId}`
            )
            dispatch(fetchNotifications())
               // this is the snipte for the notification

            //    const dateTime=res.data.date;

            //    const [dateString,timeString]=dateTime.split("T");
     
            //    const date=new Date(dateString);
     
            //    const time=timeString.substr(0,8);
     
     
            //    const localDate=date.toLocaleDateString();
   
            //    dispatch(setNotifications({
            //        notification:{
            //          name:name,
            //          details:res.data.message,
            //          time:time,
            //          date:localDate
            //        }
            //      }))
                 dispatch(setNotificationCount({
                  count:count+1,
                 }))
                 
               //   uptill here 


            getAllItsData()
            alert('Delete Successful!')
            // window.location.reload()
            getAllItsData()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllItsData()
    }, [])

    return (
        <div className={ITHighStyles.highlightMain}>
            <div className={ITHighStyles.highlightContent}>
                <div className={ITHighStyles.highlightHeader}>
                    <Typography sx={{ marginTop: '1%', color: '#121926', fontWeight: '600', fontSize: '20px' }} >
                        IT Highlights
                    </Typography>
                    <Link to='/addItHighlight'>
                        <Button sx={{ textTransform: 'capitalize' }} variant='contained'>Add IT item</Button>
                    </Link>
                </div>
                <Box className={ITHighStyles.highlightComponent}>
                    <div className={ITHighStyles.highlightComponentLeft}>
                        <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            IT Name
                        </Typography>
                    </div>
                    <div className={ITHighStyles.highlightComponentRight}>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Last modified
                        </Typography>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Action
                        </Typography>
                    </div>
                </Box>
                {data.map((item) =>
                    <Box className={ITHighStyles.highlightComponent}>
                        <div className={ITHighStyles.highlightComponentLeft}>
                            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                                {item.highlights?.itServicesHeader}
                            </Typography>
                        </div>
                        <div className={ITHighStyles.highlightComponentRight}>
                            <Typography variant='h6' sx={{ fontSize: '15px' }}>
                                {item.highlights?.date}
                            </Typography>
                            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600', display: 'flex',gap:'10px', alignItems: 'baseline' }}>
                                <Link to={`/addItHighlight/${item?.logoId}`} state={item}>
                                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </Link>
                                <IconButton disableRipple onClick={() => deleteITHighlightbyId(item?.logoId)}>
                                    <DeleteForeverOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </IconButton>
                            </Typography>
                        </div>
                    </Box>
                )}
            </div>
        </div>
    )
}

export default ITHighlights