import React from 'react'
import CompanyDetailsStyles from './CompanyDetails.module.css'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'


const CompanyDetails = () => {

     // get data from redux
     const name=useSelector((state)=>state.globalData.name);
     const count=useSelector((state)=>state.globalData.notificationCount);
     const dispatch =  useDispatch()

    const details = [1, 2, 3, 4]

    const [data, setData] = useState([])

    const getCompanyData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/companydetails/getAllCompanyDetails`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDetailbyId = async (pdfId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/companydetails/deleteById/${pdfId}`
            )
            dispatch(fetchNotifications())
            // this is the snipte for the notification

            // const dateTime=res.data.date;

            // const [dateString,timeString]=dateTime.split("T");
  
            // const date=new Date(dateString);
  
            // const time=timeString.substr(0,8);
  
  
            // const localDate=date.toLocaleDateString();

            // dispatch(setNotifications({
            //     notification:{
            //       name:name,
            //       details:res.data.message,
            //       time:time,
            //       date:localDate
            //     }
            //   }))
              dispatch(setNotificationCount({
               count:count+1,
              }))
              
            //   uptill here 
            alert('Delete Successful!')
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCompanyData()
    }, [])

    return (
        <div className={CompanyDetailsStyles.companyDetailsMain}>
            <div className={CompanyDetailsStyles.companyDetailsContent}>
                <div className={CompanyDetailsStyles.companyDetailsHeader}>
                    <Typography sx={{ marginTop: '1%', color: '#121926', fontWeight: '600', fontSize: '20px' }} >
                        Company Details
                    </Typography>
                    <Link to='/adddetail'>
                    <Button sx={{ textTransform: 'capitalize' }} variant='contained'>Add Detail</Button>
                    </Link>
                </div>
                <Box className={CompanyDetailsStyles.companyDetailsComponent}>
                    <div className={CompanyDetailsStyles.companyDetailsComponentLeft}>
                        <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Name
                        </Typography>
                    </div>
                    <div className={CompanyDetailsStyles.companyDetailsComponentRight}>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Last modified
                        </Typography>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Action
                        </Typography>
                    </div>
                </Box>
                {data.map((detail) =>
                    <Box className={CompanyDetailsStyles.companyDetailsComponent}>
                        <div className={CompanyDetailsStyles.companyDetailsComponentLeft}>
                            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                                {detail.companyDetails?.companyDetailsHeading}
                            </Typography>
                        </div>
                        <div className={CompanyDetailsStyles.companyDetailsComponentRight}>
                            <Typography variant='h6' sx={{ fontSize: '15px' }}>
                                {detail.companyDetails?.date}
                            </Typography>
                            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600', display: 'flex', alignItems: 'baseline' }}>
                                <Link to={`/editdetail/${detail?.pdfId}`} state={detail}>
                                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </Link>
                                <IconButton disableRipple onClick={() => deleteDetailbyId(detail.pdfId)}>
                                <DeleteForeverOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </IconButton>
                            </Typography>
                        </div>
                    </Box>
                )}
            </div>
        </div>
    )
}

export default CompanyDetails