import React from 'react'
import Typography from '@mui/material/Typography';
import InvestorStyles from './Investor.module.css';
import { Box, Button } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { Link } from 'react-router-dom';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';


export const Investor = () => {
  return (
    <div className={InvestorStyles.investorMain}>
    <div className={InvestorStyles.investorContent}>
        <Typography sx={{ marginTop: '2%', color: '#121926', fontWeight: '600', fontSize: '20px',paddingBottom:'3%' }} >
            Investor
        </Typography>
        <Box className={InvestorStyles.investorComponent}>
            <div className={InvestorStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Title
                </Typography>
            </div>
            <div className={InvestorStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Last modified
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Action
                </Typography>
            </div>
        </Box>
        <Box className={InvestorStyles.investorComponent}>
            <div className={InvestorStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Investment Highlights
                </Typography>
            </div>
            <div className={InvestorStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                    12.07.2018
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    <Link to='/investment'>
                        <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                    </Link>
                </Typography>
            </div>
        </Box>
        <Box className={InvestorStyles.investorComponent}>
            <div className={InvestorStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Company Details
                </Typography>
            </div>
            <div className={InvestorStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                    12.07.2018
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    <Link to='/companydetails'>
                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                    </Link>
                </Typography>
            </div>
        </Box>
        <Box className={InvestorStyles.investorComponent}>
            <div className={InvestorStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Investment opportunities
                </Typography>
            </div>
            <div className={InvestorStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                    12.07.2018
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    <Link to='/InvestmentOpp'>
                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                    </Link>
                </Typography>
            </div>
        </Box>
    </div>
</div>
  )
}

export default Investor