import React, { useEffect } from 'react'
import TicketStyles from "./UserTicket.module.css"
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import axios from 'axios';


// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    bgcolor: 'background.paper',
    border: '2px solid gray',
    boxShadow: '2px 2px 10px 2px gray',
    borderRadius: '5px',
    p: 4,
};


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function createData(id, name, date) {
    return { id, name, date };
}

const rows = [
    createData(1, 'Read More CTA', "12.07.2018"),
    createData(2, 'Explore More CTA', "12.07.2018"),
    createData(3, 'Read More CTA', "12.07.2018"),
    createData(4, 'Explore More CTA', "12.07.2018"),
    createData(5, 'Read More CTA', "12.07.2018"),
    createData(6, 'Explore More CTA', "12.07.2018"),
    createData(7, 'Read More CTA', "12.07.2018"),
    createData(8, 'Explore More CTA', "12.07.2018"),
    createData(9, 'Read More CTA', "12.07.2018"),
    createData(10, 'Explore More CTA', "12.07.2018"),
    createData(11, 'Read More CTA', "12.07.2018"),
    createData(12, 'Explore More CTA', "12.07.2018"),
    createData(13, 'Read More CTA', "12.07.2018"),
    createData(14, 'Explore More CTA', "12.07.2018"),
    createData(15, 'Read More CTA', "12.07.2018"),
];


const UserTicket = () => {


    // get data from redux
    const name=useSelector((state)=>state.globalData.name);
    const count=useSelector((state)=>state.globalData.notificationCount);
    const dispatch =  useDispatch()

    const [data, setData] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [sImg,setSImg]=React.useState();
    // const [ticketStatus, setTicketStatus] = useState('');

    const getTicketData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/tickets/getAllErrorDetails`,
                {
                    headers: {
                        "Accept": "*/*"
                    }
                }
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }


    const handleStatus=async(event,screenshotId)=>{
        const statusData={
            updateStatus:event.target.value,
        }

        console.log(statusData);
        try {
            
            const statusResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/tickets/addStatus/${screenshotId}`,
                statusData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log('status Updated')
            console.log(statusResponse)
            getTicketData();
        } catch (error) {
            console.error('status not updated error '+error);
        }

    }
    dispatch(fetchNotifications())

    const deleteTicketbyId = async (screenshotId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/tickets/deleteById/${screenshotId}`
            )
            dispatch(setNotificationCount({
                count:count+1,
               }))
            alert('Delete Successful!')
            getTicketData();
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getTicketData()
    }, [])

   
       
 
    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [ticketData, setTicketData] = useState({
        errorName: '',
        errorDescription: '',
    })
    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [imageSelect, setImageSelect] = useState(false)
    const [rowCount, setRowCount] = useState(10);

   

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setTicketData({ ...ticketData, [name]: value })
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setImageSelect(true)
        setFile(file)
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardImage = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = ""
        setImageSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        formDataObj.append("errorName", ticketData.errorName)
        formDataObj.append("errorDescription", ticketData.errorDescription)

        try {
            const imageResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/tickets/uploadErrorScreenshot`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "image/png"
                    }
                }
            )
            console.log(imageResponse)

            const ticketResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/tickets/addErrorDetails/${imageResponse.data.screenshotId}`,
                ticketData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log('Ticket Added.')
            console.log(ticketResponse)
            dispatch(fetchNotifications())

            // this is the snipte for the notification

            // const dateTime=ticketResponse.data.date;

            // const [dateString,timeString]=dateTime.split("T");
  
            // const date=new Date(dateString);
  
            // const time=timeString.substr(0,8);
  
  
            // const localDate=date.toLocaleDateString();

            // dispatch(setNotifications({
            //     notification:{
            //       name:name,
            //       details:ticketResponse.data.message,
            //       time:time,
            //       date:localDate
            //     }
            //   }))
              dispatch(setNotificationCount({
               count:count+1,
              }))
              
            //   uptill here
            // alert('Ticket Added.')
            discardImage()
            // window.location.reload()
            getTicketData()
            //close the open model which is add ticket 
            setTicketData({
                errorName: '',
                errorDescription: '',
            })
            setOpen(false)
            
        } catch (error) {
            console.error(error)
        }
    }

      
      
        // useEffect(() => {
        //   // Retrieve the selected value from localStorage when the component mounts
        //   const storedTicketStatus = localStorage.getItem('ticketStatus');
        //   if (storedTicketStatus) {
        //     setTicketStatus(storedTicketStatus);
        //   }
        // }, []);
      
        // const handleTicketStatus = (event) => {
        //   const selectedStatus = event.target.value;
        //   setTicketStatus(selectedStatus);
        //   // Store the selected value in localStorage
        //   localStorage.setItem('ticketStatus', selectedStatus);
        // };
      

    // const [ticketStatus, setTicketStatus] = React.useState("");

    // const handleTicketStatus = (event) => {
    //     setTicketStatus(event.target.value);
    // };


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [view, setView] = useState(false)
    const handleView = (row) => {
        setView(true)
        console.log(row)
        setSelectedData(row)

    }
    const handleViewClose = () => setView(false)

    const [query, setQuery] = useState("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const filteredData=data.filter(row => String(row.errors.errorName).includes(query)).reverse()

    useEffect(()=>{
        setRowCount(filteredData.length)
        setPage(0);
    },[data,query])
    
    return (
        <>
            <Card sx={{ maxWidth:'95%', border: 'none', outline:'none',boxShadow:'none', mt:'3%'  }}>
                <CardContent>
                    <Paper className={TicketStyles.myboder}>
                        <TableContainer sx={{ maxHeight: 650 }}>
                            <div className={TicketStyles.ticketListBox1}>
                                <Box className={TicketStyles.Box1}>
                                    <Typography variant='h5'>Tickets</Typography>
                                </Box>
                                <Box className={TicketStyles.Box07}>
                                    <Button variant="contained" onClick={handleOpen} className={TicketStyles.addUserBtn}>Add Ticket</Button>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style} className={TicketStyles.formStart}>
                                            <form action='' onSubmit={handleSubmit} encType='multipart/form-data'>
                                                <label>Error</label>
                                                <Box className={TicketStyles.form}>
                                                    <input text='text' value={ticketData.errorName} name='errorName' onChange={handleInputChange}></input>
                                                </Box>
                                                <label>Description</label>
                                                <Box className={TicketStyles.form}>
                                                    <textarea text='text' col="10" rows="8" value={ticketData.errorDescription} name='errorDescription' onChange={handleInputChange}></textarea>
                                                </Box>
                                                <label>Upload Screenshot</label>
                                                <div className={TicketStyles.ImgInput}>
                                                    <input
                                                        ref={inputRef}
                                                        className={TicketStyles.ImgInput_input}
                                                        type="file"
                                                        onChange={handleFileChange}
                                                    />
                                                    {!source && <button type='button' onClick={handleChoose}>Browse</button>}
                                                    {source && (
                                                        <img
                                                            className={TicketStyles.ImgInput_img}
                                                            width="100%"
                                                            src={source}
                                                            alt='uploadedimage'
                                                        />
                                                        )}
                                                        </div>
                                                        <label style={{fontWeight:274,fontSize:'10px',lineHeight:'12px',color:'#d2d2d2'}}>Only: jpg/ png/ xls/ xlsx  less than 1mb</label>
                                                    <div className={TicketStyles.ImgInput_footer}>
                                                        {imageSelect ?
                                                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                                                {fileName}
                                                                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
                                                            </div> :
                                                            ''}
                                                    </div>
                                                <Box className={TicketStyles.form}>
                                                    <Button type='submit' variant="contained">Submit</Button>
                                                </Box>
                                            </form>
                                        </Box>
                                    </Modal>
                                </Box>
                            </div>
                            <div className={TicketStyles.ticketListBox2}>
                                <Box className={TicketStyles.Box1}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon sx={{ zIndex: '1' }} />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            onChange={(e) => setQuery(e.target.value)}
                                            inputProps={{ 'aria-label': 'search' }}
                                            sx={{ width: '100%', color: '##121926', backgroundColor: '#F8FAFC', borderRadius: '10px', border: '1px solid #Bdbdbd' }}
                                        />
                                    </Search>
                                </Box>
                            </div>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className={TicketStyles.Mainhead}>
                                        <TableCell className={TicketStyles.Tablehead}>Ticket ID</TableCell>
                                        <TableCell className={TicketStyles.Tablehead}>Error Name</TableCell>
                                        <TableCell className={TicketStyles.Tablehead}>Date</TableCell>
                                        <TableCell className={TicketStyles.Tablehead} sx={{paddingLeft:'2%'}}>Status</TableCell>
                                        <TableCell className={TicketStyles.Tablehead}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    
                                    filteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            
                                            return (
                                                <TableRow className={TicketStyles.MainRow} hover role="checkbox" tabIndex={-1} key={row.screenshotId} >
                                                    <TableCell className={TicketStyles.Tablerow1}> #TC-{row.screenshotId}</TableCell>
                                                    <TableCell className={TicketStyles.Tablerow}>{row.errors?.errorName} </TableCell>
                                                    <TableCell className={TicketStyles.Tablerow}>{row.errors?.date} </TableCell>
                                                    <TableCell className={TicketStyles.Tablerow}>
                                                        <FormControl sx={{ m: 1, width: '180px' }} key={row.screenshotId}>
                                                            <Select
                                                             value={row.ticketStatus}  
                                                             onChange={(event) => handleStatus(event, row.screenshotId)}
                                                             
                                                             inputProps={{ "aria-label": "Job Roles" }}
                                                             id={row.screenshotId}
                                                             name={row.screenshot}
                                                             defaultValue={row.errors?.status===null ? "status":row.errors?.status}
                                                             sx={{height:'40px'}}
                                                            >
                                                                <MenuItem value="status">Status</MenuItem>
                                                                <MenuItem value='In-Progress'>In-Progress</MenuItem>
                                                                <MenuItem value='Open'>Open</MenuItem>
                                                                <MenuItem value='Resolved'>Resolved</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell className={TicketStyles.Tablerow}>{row.action}
                                                        <VisibilityOutlinedIcon className={TicketStyles.Tableicon} onClick={() => handleView(row)} />
                                                        <Modal
                                                            open={view}
                                                            onClose={handleViewClose}
                                                            aria-labelledby="modal-modal-title"
                                                            aria-describedby="modal-modal-description"
                                                        
                                                        >
                                                            <Box sx={style} className={TicketStyles.formStart}>
                                                                <form action='' method=''>
                                                                    <label>Error</label>
                                                                    <Box className={TicketStyles.form}>
                                                                        <input text='text' value={selectedData?.errors?.errorName} disabled></input>
                                                                    </Box>
                                                                    <label>Description</label>
                                                                    <Box className={TicketStyles.form}>
                                                                        <textarea text='text' col="10" rows="8" value={selectedData?.errors?.errorDescription} disabled></textarea>
                                                                    </Box>
                                                                    <label>Screenshot</label>
                                                                    <Box className={TicketStyles.form}>
                                                                        {/* <img src={`data:image/png;base64,${selectedData?.newScreenshot}`} alt="" /> */}
                                                                        <img src={`${process.env.REACT_APP_GECEADMIN}/tickets/file/${selectedData?.newScreenshot}`} alt="" /> 
                                                                        {/* imageResponse.data.teamImageId */}
                                                                    </Box>
                                                                </form>
                                                            </Box>
                                                        </Modal>
                                                    <DeleteForeverOutlinedIcon className={TicketStyles.Tableicon} style={{marginLeft:'10%'}} onClick={() => deleteTicketbyId(row.screenshotId)} />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                       
                                        }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={rowCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{paddingTop:'2%'}}
                        />
                    </Paper>
                </CardContent>
            </Card>
        </>
    )
}

export default UserTicket





