import React, { useEffect, useState } from 'react'
import EditHighlightStyles from './EditITHighlight.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditITHighlight = () => {

    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [imageSelect, setImageSelect] = useState(false)

    const [itServicesHeader, setInvestmentHeader] = useState('')
    const [itServicesDetails, setInvestmentDetails] = useState('')

    useEffect(() => {
        if (data) {
            setInvestmentHeader(data.highlights?.itServicesHeader)
            setInvestmentDetails(data.highlights?.itServicesDetails)
        }
    }, [data])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setImageSelect(true)
        setFile(file)
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardImage = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = ""
        setImageSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        formDataObj.append("itServicesHeader", itServicesHeader)
        formDataObj.append("itServicesDetails", itServicesDetails)

        try {
            const imageResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/itServicesHighlights/updateLogoById/${data.logoId}`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "image/jpeg"
                    }
                }
            )
            console.log(imageResponse)

            const highlightResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/itServicesHighlights/updateItServiceHighlights/${data.highlights.itServicesId}`,
                { itServicesHeader, itServicesDetails },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log('Highlight Updated.')
            console.log(highlightResponse)
            alert('Highlight Updated.')
            discardImage()
            navigate('/investment')
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        const fetchImage = async () => {
          try {
            const imageResponse = await axios.get(
              `${process.env.REACT_APP_GECEADMIN}/itServicesHighlights/file/${data.logo}`
            );
            console.log(imageResponse);
            setSource(imageResponse);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchImage();
      }, [data.logo]);
    

    return (
        <div className={EditHighlightStyles.editHighlightMain}>
            <div className={EditHighlightStyles.editHighlightContent}>
                <form action="" className={EditHighlightStyles.editHighlightForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={EditHighlightStyles.editHighlightFormLabel}>Title</label>
                    <input className={EditHighlightStyles.editHighlightFormText} type="text" value={itServicesHeader === null? 'Empty Value': itServicesHeader} name='itServicesHeader' onChange={(event) => setInvestmentHeader(event.target.value)} />
                    <label className={EditHighlightStyles.editHighlightFormLabel}>Description</label>
                    <textarea className={EditHighlightStyles.editHighlightFormDesc} value={itServicesDetails  === null? 'Empty Value': itServicesDetails} name='itServicesDetails' onChange={(event) => setInvestmentDetails(event.target.value)}/>
                    <label className={EditHighlightStyles.editHighlightFormLabel}>Upload</label>
                    <div className={EditHighlightStyles.ImgInput}>
                        <input
                            ref={inputRef}
                            className={EditHighlightStyles.ImgInput_input}
                            type="file"
                            onChange={handleFileChange}
                        />
                        {!source && <button type='button' onClick={handleChoose}>Browse</button>}
                        {source && (
                            <img
                                className={EditHighlightStyles.ImgInput_img}
                                width="100%"
                                src={`${process.env.REACT_APP_GECEADMIN}/itServicesHighlights/file/${data.logo}`}
                                alt='uploadedimage'
                            />
                        )}
                            </div>
                        <div className={EditHighlightStyles.ImgInput_footer}>
                            {imageSelect ?
                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                    {fileName}
                                </div> :
                                ''}
                                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
                        </div>
                    <Button variant='contained' type='submit' className={EditHighlightStyles.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default EditITHighlight