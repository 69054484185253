import React, { useState } from 'react';
import BusinessStyles from './AddTestimonial.module.css';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
import TeamMemberStyles from '../AddTeamMember/AddTeamMember.module.css'

const AddTestimonial = () => {

  const location = useLocation();
  const data = location.state;
  console.log(data);

  const navigate = useNavigate();

  const name = useSelector((state) => state.globalData.name);
  const count = useSelector((state) => state.globalData.notificationCount);
  const dispatch = useDispatch()

  const inputRef = React.useRef();
  const [file, setFile] = useState(null)

  const [testimonialData, setTestimonialData] = useState({
    testimonialName: '',
    testimonialPosition: '',
    testimonialMessage: '',
  });

  const [source, setSource] = React.useState();
  const [fileName, setFileName] = React.useState('');
  const [imageSelect, setImageSelect] = useState(false)

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTestimonialData({ ...testimonialData, [name]: value });
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name)
    const url = URL.createObjectURL(file);
    setSource(url);
    setImageSelect(true)
    setFile(file)
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const discardImage = (event) => {
    setSource(null)
    setFileName('')
    setFile(null)
    inputRef.current.value = ""
    setImageSelect(false)
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formDataObj = new FormData()
    formDataObj.append("file", file)
    formDataObj.append("testimonialName", testimonialData.testimonialName)
    formDataObj.append("testimonialPosition", testimonialData.testimonialPosition)
    formDataObj.append("testimonialMessage", testimonialData.testimonialMessage)

    try {
      const testimonialresponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/testimonial/addTestimonial`,
        testimonialData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );


      const imageResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/testimonial/uploadTestimonialImage/${testimonialresponse.data.testimonialId}`,
        formDataObj,
        {
          headers: {
            "Content-Type": "image/jpeg"
          }
        }
      )
      console.log(imageResponse)



      console.log(testimonialresponse)
      dispatch(fetchNotifications())

      dispatch(setNotificationCount({
        count: count + 1,
      }))
      alert('Testimonial Added.');
      navigate('/testimonial');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={BusinessStyles.businessMain}>
      <div className={BusinessStyles.businessContent}>
        <form
          action=""
          className={BusinessStyles.businessForm}
          onSubmit={handleSubmit}
        >
          <label className={BusinessStyles.businessFormLabel}>Name</label>
          <input
            className={BusinessStyles.businessFormText}
            type="text"
            value={testimonialData.testimonialName}
            name="testimonialName"
            onChange={handleInputChange}
          />
          <label className={BusinessStyles.businessFormLabel}>Position</label>
          <input
            className={BusinessStyles.businessFormText}
            type="text"
            value={testimonialData.testimonialPosition}
            name="testimonialPosition"
            onChange={handleInputChange}
          />
          <label className={BusinessStyles.businessFormLabel}>Message</label>
          <textarea
            className={BusinessStyles.businessFormDesc}
            value={testimonialData.testimonialMessage}
            name="testimonialMessage"
            onChange={handleInputChange}
          />

          <label className={TeamMemberStyles.teammemberFormLabel}>Upload</label>
          <div className={TeamMemberStyles.ImgInput}>
            <input
              ref={inputRef}
              className={TeamMemberStyles.ImgInput_input}
              type="file"
              onChange={handleFileChange}
            />
            {!source && <button type='button' onClick={handleChoose}>Browse</button>}
            {source && (
              <img
                className={TeamMemberStyles.ImgInput_img}
                width="100%"
                src={source}
                alt='uploadedimage'
              />
            )}
          </div>
          <div className={TeamMemberStyles.ImgInput_footer}>
            {imageSelect ?
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                {fileName}
                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
              </div> :
              ''}
          </div>


          <Button variant="contained" type="submit" className={BusinessStyles.saveChanges}>
            Save changes
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AddTestimonial;
