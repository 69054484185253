

import { Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { doLogin } from '../../auth'
import { loginUser } from '../../services/UserService'
import loginPageStyle from "./LoginPage.module.css";
import { setLogin } from '../../state/GlobalState'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { useDispatch } from 'react-redux'
import useAuth from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom'


import { InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ErrorIcon from '@mui/icons-material/Error';

function LoginPage() {

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate()
  const [isLoginIdValid, setIsLoginIdValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSubmmitError, setIsSubmitError] = useState(false);

  const dispatch = useDispatch()


  const [loginDetail, setLoginDetail] = useState({

    usernameOrEmail: '',
    password: '',
  })

  const [errors, setErrors] = useState({
    usernameOrEmail: false,
    password: false,
  });



  const handleChange = (event, field) => {
    let actualValue = event.target.value
    setLoginDetail({
      ...loginDetail,
      [field]: actualValue,
    })
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

  const [emailError, setEmailError] = useState(false);


   const handleSubmit = async (event) => {
    event.preventDefault();

    if (!loginDetail.usernameOrEmail || !emailRegex.test(loginDetail.usernameOrEmail)) {
      setIsLoginIdValid(true);
      return false;
    }
    setIsLoginIdValid(false);

    if (!loginDetail.password || !passwordRegex.test(loginDetail.password)) {
      setIsPasswordValid(true);
      return false;
    }
    setIsPasswordValid(false);



    let response = await fetch(`${process.env.REACT_APP_GECEADMIN}/api/auth/login`, {

      method: 'POST',
      // `${process.env.REACT_APP_GECEADMIN}/gallery/file/${data.newImage}`
      headers: {
        // 'ngrok-skip-browser-warning': true,
        'Content-Type': 'application/json',
        'Accept': 'application/json',

      },
      body: JSON.stringify(loginDetail)

    }).then(response => {
      if (response.ok) {
        console.log('Login successfully');

        return response.json(); // Parse response body as JSON

      } else {
        throw new Error('Network response was not ok');
      }
    }).then(data => {
        console.log(data); // Print the entire JSON response object
        const token = data.accessToken;
        // console.log(data.details)
        // console.log(data.dateTime)
        // const dateTime = data.dateTime;

        // const [dateString, timeString] = dateTime.split("T");

        // const date = new Date(dateString);

        // const time = timeString.substr(0, 8);


        // const localDate = date.toLocaleDateString();


        dispatch(setLogin({
          user: loginDetail.usernameOrEmail,
          token: token,
          name: data.name,

        }))

        // dispatch(setNotifications({
        //   notification: {
        //     name: data.name,
        //     details: data.details,
        //     time: time,
        //     date: localDate
        //   }
        // }))
        dispatch(setNotificationCount({
          count: 1,
        }))




        navigate('/dashboard');
        // Handle the data or perform further operations
      }).catch(error => {
        console.log('Login Failed!!!!!')
        // handleLoginUser(false);
        setIsSubmitError(true);
        console.error(error);
      })



  };



  const content = (
    <div className={loginPageStyle.mainsec}>
      <div className={loginPageStyle.innersec}>
        <div className={loginPageStyle.inner_sec}>
          <center>
            <img
              // src="https://res.cloudinary.com/dxluokypg/image/upload/v1682507755/KJSSCosmosAdmin_mprddf.svg"
              src="https://res.cloudinary.com/da21yifxa/image/upload/v1703311934/logo_2_1_r8uxfe.png"
              alt="logo"
              width={220}
            />
          </center>
          <Typography variant="h1" sx={{display:'flex',justifyContent:'center',mt:'5%'}}>Hi, Welcome Back</Typography>
          <Typography variant="h2" sx={{display:'flex',justifyContent:'center'}}>
            Enter your credentials to continue
          </Typography>
          <form action="" onSubmit={handleSubmit}>
          {isSubmmitError &&  <p style={{border:'2px solid 5F5E5E',padding:'3%',justifyContent:'left',display:'flex',backgroundColor:'#ffdfe4'}}> <ErrorIcon  sx={{color:'#ff5d5d',paddingRight:'2%'}} /> Login Id or Password are Incorrect</p>}

          <TextField
          className={loginPageStyle.TextField}
          id="outlined-basic"
          label="Email Address / Username"
          variant="outlined"
          value={loginDetail.usernameOrEmail}
          onChange={(e) => handleChange(e, 'usernameOrEmail')}
          error={isLoginIdValid} // Add error prop
          helperText={isLoginIdValid && 'Login ID is required'} 
          autoComplete='off'
        />
            {/* {isLoginIdValid && <p >Login id is Required</p>} */}
        <TextField
        className={loginPageStyle.TextField}
        sx={{ fontWeight: '400', fontSize: '5px', lineHeight: '15px' }}
        id="outlined-basic"
        label="Password"
        variant="outlined"
        value={loginDetail.password}
        onChange={(e) => handleChange(e, 'password')}
        type={showPassword ? 'text' : 'password'}
        error={isPasswordValid} // Add error prop
          helperText={isPasswordValid && 'Password is required'} 
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
              {showPassword ? (
                <VisibilityOff onClick={() => setShowPassword(false)} />
              ) : (
                <Visibility onClick={() => setShowPassword(true)} />
              )}
            </InputAdornment>
          ),
        }}
      />

            {/* {isPasswordValid && <p >password is Required</p>} */}
           

            {/* Desktop View end */}
            <div style={{
              marginTop: '5%',
              marginBottom: '1%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: '#364152',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight:'18px'
            }}>
              <FormControlLabel
                control={
                  <Checkbox defaultChecked sx={{ borderRadius: '5px' }} />
                }
                label="Keep me logged in"
              />

            </div>
            <Button
              className={loginPageStyle.SignInBtn}
              type="submit"
              variant="contained"
              sx={{
                width:'100%',
                p: 2,
                // height: 50,
                fontSize: '15px',
                lineHeight: '26px',
                color: '#fff',
                borderRadius: '10px',
                fontWeight: '500',
                // textTransform: 'none',
                // display: 'block',
                mb:'5%'
              }}
            // onClick={login}
            >
             Sign In
            </Button>
       


          </form>
        </div>
      </div>
    </div>
  );

  return content;
}

export default LoginPage

