import React, { useEffect, useState,useRef } from 'react'
import forStyle from './VideoFor.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const VideoFor = () => {

    const location = useLocation()

    const { id, type } = location.state
    // console.log(data)
    const inputRef = useRef();
    const navigate = useNavigate()

    const [file, setFile] = useState(null);

    const [channel, setChannel] = useState('')
    const [channelId, setChannelId] = useState("");
    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [videoSelect, setVideoSelect] = useState(false)

   
    

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setVideoSelect(true)
        setFile(file)
    };
    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardVideo = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = "";
        setVideoSelect(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file);

        try {
            const resName = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/channel/addChannel/${id}`,
                { channelName: channel, channelType: type },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log(resName)
            const resid = resName.data.channelId


            const resUrl = await axios.post(`${process.env.REACT_APP_GECEADMIN}/videofile/uploadFile/${id}/${resid}`,
            formDataObj,
            {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            console.log('Highlight Updated.')
        
            alert('video Updated.')

            navigate(`/EditInvOppo/${id}`)
        } catch (error) {
            console.error(error)
        }
    }

    // useEffect(() => {
    //     const fetchImage = async () => {
    //       try {
    //         const imageResponse = await axios.get(
    //           `${process.env.REACT_APP_GECEADMIN}/investmentHighlights/file/${data.logo}`
    //         );
    //         console.log(imageResponse);
    //         setSource(imageResponse);
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     };

    //     fetchImage();
    //   }, [data.logo]);


    return (
        <div className={forStyle.editHighlightMain}>
            <div className={forStyle.editHighlightContent}>
                <form action="" className={forStyle.editHighlightForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={forStyle.editHighlightFormLabel}>Name</label>
                    <input className={forStyle.editHighlightFormText} type="text" value={channel} name='channel' onChange={(event) => setChannel(event.target.value)} />
                    {/* <label className={forStyle.editHighlightFormLabel}>Url</label>
                    <input className={forStyle.editHighlightFormText} type="text" value={invPdf} name='invPdf' onChange={(event) => setInvPdf(event.target.value)} />*/}
                    <div className={forStyle.VideoInput}>
                        <input
                            ref={inputRef}
                            className={forStyle.VideoInput_input}
                            type="file"
                            onChange={handleFileChange}
                            accept=".mov,.mp4"
                        />
                        {!source && <button type="button" onClick={handleChoose}>Browse</button>}
                        {source && (
                            <video
                                className={forStyle.VideoInput_video}
                                width="100%"
                                controls
                                src={source}
                            />
                        )}
                        {/* <div className={forStyle.ImgInput_footer} > */}
                            {videoSelect ? 
                                <div style={{display:'flex',justifyContent:'space-around',width:'100%', padding:'2% 0'}}>
                                    {fileName}
                                    <DeleteForeverOutlinedIcon onClick={discardVideo} sx={{ cursor: 'pointer' }} />
                                </div> :                       
                             ''}
                        {/* </div> */}
                    </div>
                    <Button variant='contained' type='submit' className={forStyle.saveChanges}>Save changes</Button>
                </form>
            </div >
        </div >
    )
}

export default VideoFor

