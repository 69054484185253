import React, { useState } from 'react'
import TeamMemberStyles from './AddTeamMember.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

  // import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

const AddTeamMember = () => {

      // get data from redux
      const name=useSelector((state)=>state.globalData.name);
      const count=useSelector((state)=>state.globalData.notificationCount);
      const dispatch =  useDispatch()
    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate() 

    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [memberData, setMemberData] = useState({
        teammateName: '',
        teammatePosition: '',
        teammateInfo: '',
    })
    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [imageSelect, setImageSelect] = useState(false)

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setMemberData({ ...memberData, [name]: value })
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setImageSelect(true)
        setFile(file)
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardImage = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = ""
        setImageSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        formDataObj.append("teammateName", memberData.teammateName)
        formDataObj.append("teammatePosition", memberData.teammatePosition)
        formDataObj.append("teammateInfo", memberData.teammateInfo)

        try {
            const imageResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/team/uploadTeamImage`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "image/jpeg"
                    }
                }
            )
            console.log(imageResponse)

            const teamResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/team/addTeamDetails/${imageResponse.data.teamImageId}`,
                memberData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            // console.log('Member Added.')
            navigate('/MeetOurTeam')
            // alert('Member Added.')

            console.log(teamResponse)
            dispatch(fetchNotifications())

            
       // this is the snipte for the notification

    //    const dateTime=teamResponse.data.date;

    //    const [dateString,timeString]=dateTime.split("T");

    //    const date=new Date(dateString);

    //    const time=timeString.substr(0,8);


    //    const localDate=date.toLocaleDateString();

    //    dispatch(setNotifications({
    //        notification:{
    //          name:name,
    //          details:teamResponse.data.message,
    //          time:time,
    //          date:localDate
    //        }
    //      }))
         dispatch(setNotificationCount({
          count:count+1,
         }))
         
       //   uptill here 

            alert('Member Added.')
            discardImage()
            navigate('/MeetOurTeam')

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={TeamMemberStyles.teammemberMain}>
            <div className={TeamMemberStyles.teammemberContent}>
                <form action="" className={TeamMemberStyles.teammemberForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={TeamMemberStyles.teammemberFormLabel}>Name</label>
                    <input className={TeamMemberStyles.teammemberFormText} type="text" value={memberData.teammateName} name='teammateName' onChange={handleInputChange} />
                    <label className={TeamMemberStyles.teammemberFormLabel}>Position</label>
                    <input className={TeamMemberStyles.teammemberFormText} type="text" value={memberData.teammatePosition} name='teammatePosition' onChange={handleInputChange} />
                    <label className={TeamMemberStyles.teammemberFormLabel}>Description</label>
                    <textarea className={TeamMemberStyles.teammemberFormDesc} value={memberData.teammateInfo} name='teammateInfo' onChange={handleInputChange} />
                    <label className={TeamMemberStyles.teammemberFormLabel}>Upload</label>
                    <div className={TeamMemberStyles.ImgInput}>
                        <input
                            ref={inputRef}
                            className={TeamMemberStyles.ImgInput_input}
                            type="file"
                            onChange={handleFileChange}
                        />
                        {!source && <button type='button' onClick={handleChoose}>Browse</button>}
                        {source && (
                            <img
                                className={TeamMemberStyles.ImgInput_img}
                                width="100%"
                                src={source}
                                alt='uploadedimage'
                            />
                        )}
                            </div>
                        <div className={TeamMemberStyles.ImgInput_footer}>
                            {imageSelect ?
                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                    {fileName}
                                    <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
                                </div> :
                                ''}
                    </div>
                    <Button variant='contained' type='submit' className={TeamMemberStyles.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default AddTeamMember