import React from 'react'
import inveStyle from './InvestorOpportunities.module.css'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
import ShareIcon from '@mui/icons-material/Share';
import EditInvestmentBanner from './EditInvestmentBanner';


const InvestorOpportunities = () => {

    // get data from redux
    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()

    const details = [1, 2, 3, 4]

    const [data, setData] = useState([])
    const [selectedDetail, setSelectedDetail] = useState(null);
    const getinvestorData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/getAllInvestmentOpportunities`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDetailbyId = async (invId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/deleteInvestmentOpportunities/${invId}`
            )
            dispatch(fetchNotifications())

            dispatch(setNotificationCount({
                count: count + 1,
            }))

            alert('Delete Successful!')
            getinvestorData()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getinvestorData()
    }, [])

    return (
        <div className={inveStyle.companyDetailsMain}>
            <div className={inveStyle.companyDetailsContent}>
                <div className={inveStyle.companyDetailsHeader}>
                    <Typography sx={{ marginTop: '1%', color: '#121926', fontWeight: '600', fontSize: '20px' }} >
                        Investor Opportunities
                    </Typography>
                    <Link to='/AddInvOppo'>
                        <Button sx={{ textTransform: 'capitalize' }} variant='contained'>Add</Button>
                    </Link>
                </div>
                <Box className={inveStyle.companyDetailsComponent}>
                    <div className={inveStyle.companyDetailsComponentLeft}>
                        <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Name
                        </Typography>
                    </div>
                    <div className={inveStyle.companyDetailsComponentRight}>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600', marginRight: '5%' }}>
                            Last modified
                        </Typography>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600', marginRight: '5%' }}>
                            Action
                        </Typography>
                    </div>
                </Box>
                {data.map((detail) =>
                    <Box className={inveStyle.companyDetailsComponent} key={detail.invId}>
                        <div className={inveStyle.companyDetailsComponentLeft}>
                            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                                {detail.invName}
                            </Typography>
                        </div>
                        <div className={inveStyle.companyDetailsComponentRight}>
                            <Typography variant='h6' sx={{ fontSize: '15px' }}>
                                {detail.date}
                            </Typography>
                            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600', display: 'flex', alignItems: 'baseline' }}>
                                <Link to={`/EditInvOppo/${detail?.invId}`} >
                                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </Link>
                                <IconButton disableRipple onClick={() => deleteDetailbyId(detail.invId)}>
                                    <DeleteForeverOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer', marginLeft: '10px' }} />
                                </IconButton>
                                <Link to={`/createL/${detail?.invId}/${detail?.invName}`} >
                                {/* <Link to='/createL' > */}
                                    <ShareIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </Link>
                            </Typography>
                        </div>
                    </Box>
                    
                ).reverse()}
{selectedDetail && (
                    <Link to={`/editInvestmentBanner/${selectedDetail.invId}`} >
                        <EditInvestmentBanner data={selectedDetail} />
                    </Link>
                )}

            </div>
            
        </div>
    )
}

export default InvestorOpportunities





