 
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function PrivateRoute (props) {
  const {Cmp} = props;
  const navigate = useNavigate();
  useEffect(() =>{
    let login = localStorage.getItem('login');
    // console.log('login');
    if(!login){
      navigate('/login')
      
    }
  })
  return (
    <div>
      <Cmp />
    </div>
  )
}

export default PrivateRoute
