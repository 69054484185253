import React, { useEffect, useState } from 'react'
import BusinessStyles from './EditOurBusiness.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

const EditOurBusiness = () => {

  
const name=useSelector((state)=>state.globalData.name);
const count=useSelector((state)=>state.globalData.notificationCount);
const dispatch=useDispatch();


  const location = useLocation()
  const data = location.state
  console.log(data)

  const navigate = useNavigate()

  const inputRef = React.useRef();

  const [file, setFile] = useState(null)
  const [source, setSource] = React.useState();
  const [fileName, setFileName] = React.useState('');
  const [imageSelect, setImageSelect] = useState(false)

  const [businessHeading, setBusinessHeading] = useState('')
  const [businessDesc, setBusinessDesc] = useState('')
  const [businessLink, setBusinessLink] = useState('')

  useEffect(() => {
    if (data) {
      setBusinessHeading(data.business.businessHeading)
      setBusinessDesc(data.business.businessDesc)
      setBusinessLink(data.business.businessLink)
    }
  }, [data])


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name)
    const url = URL.createObjectURL(file);
    setSource(url);
    setImageSelect(true)
    setFile(file)
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const discardImage = (event) => {
    setSource(null)
    setFileName('')
    setFile(null)
    inputRef.current.value = ""
    setImageSelect(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formDataObj = new FormData()
    formDataObj.append("file", file)
    formDataObj.append("businessHeading", businessHeading)
    formDataObj.append("businessDesc", businessDesc)
    formDataObj.append("businessLink", businessLink)

    try {
      const imageResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/business/uploadBusinessImageById/${data.businessImageId}`,
        formDataObj,
        {
          headers: {
            "Content-Type": "image/jpeg"
          }
        }
      )
      console.log(imageResponse)

      const businessResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/business/updateBusinessDetails/${data.business.businessId}`,
        {businessHeading, businessDesc, businessLink},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      console.log('Business Updated.')
      console.log(businessResponse)
      alert('Business Updated.')

      dispatch(fetchNotifications())

      dispatch(setNotificationCount({
        count:count+1,
       }))
      discardImage()
      navigate('/ourbusiness')
    } catch (error) {
      console.error(error)
    }
  }


    useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageResponse = await axios.get(
          `${process.env.REACT_APP_GECEADMIN}/business/file/${data.newBusinessImageName}`
        );
        console.log(imageResponse);
        setSource(imageResponse);
      } catch (error) {
        console.error(error);
      }
    };

    fetchImage();
  }, [data.newBusinessImageName]);


  return (
    <div className={BusinessStyles.businessMain}>
      <div className={BusinessStyles.businessContent}>
        <form action="" className={BusinessStyles.businessForm} onSubmit={handleSubmit} encType='multipart/form-data'>
          <label className={BusinessStyles.businessFormLabel}>Title</label>
          <input className={BusinessStyles.businessFormText} type="text" value={businessHeading} name='businessHeading' onChange={(event) => setBusinessHeading(event.target.value)} />
          <label className={BusinessStyles.businessFormLabel}>Description</label>
          <textarea className={BusinessStyles.businessFormDesc} value={businessDesc} name='businessDesc' onChange={(event) => setBusinessDesc(event.target.value)} />
          <label className={BusinessStyles.businessFormLabel}>Link</label>
          <input className={BusinessStyles.businessFormText} type="text" value={businessLink} name='businessLink' onChange={(event) => setBusinessLink(event.target.value)} />
          <label className={BusinessStyles.businessFormLabel}>Upload</label>
          <div className={BusinessStyles.ImgInput}>
            <input
              ref={inputRef}
              className={BusinessStyles.ImgInput_input}
              type="file"
              onChange={handleFileChange}
            />
            {!source && <button type='button' onClick={handleChoose}>Browse</button>}
            {source && (
              <img
                className={BusinessStyles.ImgInput_img}
                width="100%"
                src={`${process.env.REACT_APP_GECEADMIN}/business/file/${data.newBusinessImageName}`}
                alt='uploadedimage'
              />
              )}
              </div>
            <div className={BusinessStyles.ImgInput_footer}>
              {imageSelect ?
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                  {fileName}
                </div> :
                ''}
                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
            </div>
          <Button variant='contained' type='submit' className={BusinessStyles.saveChanges}>Save changes</Button>
        </form>
      </div>
    </div>
  )
}

export default EditOurBusiness