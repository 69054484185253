import React from 'react'
import PostJobStyles from './PostJob.module.css'
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'


const PostJob = () => {


    // get data from redux
    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()


    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const [careerJobRoleName, setCareerJobRoleName] = useState('')
    const [jobLocation, setJobLocation] = useState('')
    const [jobType, setJobType] = useState('')
    const [careerJobRoleExperience, setCareerJobRoleExperience] = useState('')
    const [vacancy, setVacancy] = useState('')
    const [whoAreWeLookingFor, setWhoAreWeLookingFor] = useState('')
    const [whatYouWillBeDoing, setWhatYouWillBeDoing] = useState('')
    const [educationalRequirement, setEducationalRequirement] = useState('')
    const [remuneration, setRemuneration] = useState('')

    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const jobResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/career/addJobRole/${data.careerCategoryId}`,
                { careerJobRoleName, jobLocation, jobType, careerJobRoleExperience, vacancy, whoAreWeLookingFor, whatYouWillBeDoing, educationalRequirement,remuneration },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log('Job Role Added.')
            navigate('/jobroles')

            console.log(jobResponse)

            // this is the snipte for the notification

            const dateTime = jobResponse.data.date;

            const [dateString, timeString] = dateTime.split("T");

            const date = new Date(dateString);

            const time = timeString.substr(0, 8);


            const localDate = date.toLocaleDateString();

            dispatch(setNotifications({
                notification: {
                    name: name,
                    details: jobResponse.data.message,
                    time: time,
                    date: localDate
                }
            }))
            dispatch(setNotificationCount({
                count: count + 1,
            }))

            //   uptill here 

            alert('Job Role Added.')
            navigate('/jobroles')
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={PostJobStyles.jobMain}>
            {/* <div className={PostJobStyles.jobContent}> */}
            <form action="" className={PostJobStyles.jobForm} onSubmit={handleSubmit}>
                <div className={PostJobStyles.inputRow}>

                    <label >Job Role</label>
                    <input type="text" value={careerJobRoleName} name='careerJobRoleName' onChange={(event) => setCareerJobRoleName(event.target.value)} />
                </div>
                <div className={PostJobStyles.inputRow}>

                    <label >Location</label>
                    <input type="text" value={jobLocation} name='jobLocation' onChange={(event) => setJobLocation(event.target.value)} />
                </div>
                <div className={PostJobStyles.inputRow}>

                    <label >Job Type</label>
                    <input type="text" value={jobType} name='jobType' onChange={(event) => setJobType(event.target.value)} />
                </div>
                <div className={PostJobStyles.inputRow}>

                    <label >Experience Needed</label>
                    <input type="text" value={careerJobRoleExperience} name='careerJobRoleExperience' onChange={(event) => setCareerJobRoleExperience(event.target.value)} />
                </div>
                <div className={PostJobStyles.inputRow}>

                    <label >Vacancy</label>
                    <input type="text" value={vacancy} name='vacancy' onChange={(event) => setVacancy(event.target.value)} />
                </div>
                <div className={PostJobStyles.inputRow}>

                    <label >Who Are We Looking For</label>
                    {/* <textarea className={PostJobStyles.jobFormDesc} value={whoAreWeLookingFor} name='whoAreWeLookingFor' onChange={(event) => setWhoAreWeLookingFor(event.target.value)} /> */}
                    <div  className={PostJobStyles.reactQill} >
                        <ReactQuill theme="snow"   value={whoAreWeLookingFor} onChange={setWhoAreWeLookingFor} />
                    </div>
                </div>
                <div className={PostJobStyles.inputRow}>

                    <label >What You Will Be Doing</label>
                    {/* <textarea className={PostJobStyles.jobFormDesc} value={whatYouWillBeDoing} name='whatYouWillBeDoing' onChange={(event) => setWhatYouWillBeDoing(event.target.value)}/> */}
                    <div className={PostJobStyles.reactQill} >

                        <ReactQuill theme="snow" 
                         
                        value={whatYouWillBeDoing} onChange={setWhatYouWillBeDoing} />
                    </div>
                </div>
                <div className={PostJobStyles.inputRow}>

                    <label >Educational Requirement</label>
                    <input value={educationalRequirement} name='educationalRequirement' onChange={(event) => setEducationalRequirement(event.target.value)} type="text" />
                </div>
                <div className={PostJobStyles.inputRow}>

                    <label >Remuneration</label>
                    <input value={remuneration} name='remuneration' onChange={(event) => setRemuneration(event.target.value)} type="text" />
                </div>

                <Button type='submit' className={PostJobStyles.postButton} variant='contained'>Post Job</Button>
            </form>
            {/* </div> */}
        </div>
    )
}

export default PostJob