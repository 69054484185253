import React from 'react'
import Typography from '@mui/material/Typography';
import InvestorStyles from './AddPitch.module.css';
import { Box, Button } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { Link } from 'react-router-dom';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { useParams } from 'react-router-dom';


export const AddPitch = () => {
    const { id,id2,summeryId } = useParams();

  return (
    <div className={InvestorStyles.investorMain}>
    <div className={InvestorStyles.investorContent}>
        <Typography sx={{ marginTop: '1%', color: '#121926', fontWeight: '600', fontSize: '20px' }} >
            Investor Opportunities
        </Typography>
        <Box className={InvestorStyles.investorComponent}>
            <div className={InvestorStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Title
                </Typography>
            </div>
            <div className={InvestorStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Last modified
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Action
                </Typography>
            </div>
        </Box>
        <Box className={InvestorStyles.investorComponent}>
            <div className={InvestorStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Short summary
                </Typography>
            </div>
            <div className={InvestorStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                    15-07-2023
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    <Link to={`/summary/${id}/${id2}/${summeryId}`}>
                        <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                    </Link>
                </Typography>
            </div>
        </Box>
        <Box className={InvestorStyles.investorComponent}>
            <div className={InvestorStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Our Business
                </Typography>
            </div>
            <div className={InvestorStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                    15-07-2023
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    <Link to={`/theBusi/${id}/${id2}`}>
                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                    </Link>
                </Typography>
            </div>
        </Box>
        <Box className={InvestorStyles.investorComponent}>
            <div className={InvestorStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    The Market
                </Typography>
            </div>
            <div className={InvestorStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                    15-07-2023
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    
                    {/* <Link to='/market/${id}/${id2}'> */}
                    <Link to={`/market/${id}/${id2}`}>
                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                    </Link>
                </Typography>
            </div>
        </Box>
        <Box className={InvestorStyles.investorComponent}>
            <div className={InvestorStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Our team
                </Typography>
            </div>
            <div className={InvestorStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                    15-07-2023
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    <Link to={`/our/${id}/${id2}`}>
                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                    </Link>
                </Typography>
            </div>
        </Box>
        <Box className={InvestorStyles.investorComponent}>
            <div className={InvestorStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Document
                </Typography>
            </div>
            <div className={InvestorStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                    15-07-2023
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    {/* <Link to='/InvestmentOpp'> */}
                    <Link to={`/doc/${id}/${id2}`}>
                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                    </Link>
                </Typography>
            </div>
        </Box>
        
    </div>
</div>
  )
}

export default AddPitch