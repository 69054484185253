import React, { useState } from 'react'
import InvOppstyle from './AddInvOpportunities.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';



//   notification section start

import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
//   notification section End


const AddInvOpportunities = () => {
    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);


    const dispatch = useDispatch();

    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [Invoppdata, setInvoppdata] = useState({
        invName: '',
        // businessDesc: '',
        // businessLink: '',
    })
    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [imageSelect, setImageSelect] = useState(false)

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setInvoppdata({ ...Invoppdata, [name]: value })
    }


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setImageSelect(true)
        setFile(file)
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardImage = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = ""
        setImageSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        formDataObj.append("invName", Invoppdata.invName)
        // formDataObj.append("businessDesc", Invoppdata.businessDesc)
        // formDataObj.append("businessLink", Invoppdata.businessLink)

        try {
            const invResponse = await axios.post(
                // `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/addInvestmentOpportunities/${imageResponse.data.businessImageId}`,
                `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/addInvestmentOpportunities`,
                Invoppdata,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log(invResponse)


            const imageResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/addImage/${invResponse.data.invId}`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "image/jpeg"
                    }
                }
            )
            console.log(imageResponse)


            dispatch(fetchNotifications())

            alert('Investor Added.')
            navigate('/InvestmentOpp')

            dispatch(setNotificationCount({
                count: count + 1,
            }))

            discardImage()
            navigate('/InvestmentOpp')

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={InvOppstyle.businessMain}>
            <div className={InvOppstyle.businessContent}>
                <form action="" className={InvOppstyle.businessForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={InvOppstyle.businessFormLabel}>Name</label>
                    <input className={InvOppstyle.businessFormText} type="text" value={Invoppdata.invName} name='invName' onChange={handleInputChange} />
                    {/* <label className={InvOppstyle.businessFormLabel}>Description</label>
                    <textarea className={InvOppstyle.businessFormDesc} value={Invoppdata.businessDesc} name='businessDesc' onChange={handleInputChange} />
                    <label className={InvOppstyle.businessFormLabel}>Link</label>
                    <input className={InvOppstyle.businessFormText} type="text" value={Invoppdata.businessLink} name='businessLink' onChange={handleInputChange} /> */}
                    <label className={InvOppstyle.businessFormLabel}>Upload</label>
                    <div className={InvOppstyle.ImgInput}>
                        <input
                            ref={inputRef}
                            className={InvOppstyle.ImgInput_input}
                            type="file"
                            onChange={handleFileChange}
                        />
                        {!source && <button type='button' onClick={handleChoose}>Choose</button>}
                        {source && (
                            <img
                                className={InvOppstyle.ImgInput_img}
                                width="100%"
                                src={source}
                                alt='uploadedimage'
                            />
                        )}
                    </div>
                    <div className={InvOppstyle.ImgInput_footer}>
                        {imageSelect ?
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                {fileName}
                                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
                            </div> :
                            ''}
                    </div>
                    <Button variant='contained' type='submit' className={InvOppstyle.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default AddInvOpportunities



