import React from 'react'
import OurStyle from './OurTeam.module.css'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
import { useParams } from 'react-router-dom';


const Ourteam = () => {
    const { id, id2 } = useParams();
    // get data from redux
    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()

    const [data, setData] = useState([])

    const getinvestorData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/channel/getChannelById/${id2}`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDetailbyId = async (teamId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/investoppOurTeam/deleteTeamDetails/${id}/${id2}/${teamId}`
            )
            dispatch(fetchNotifications())

            dispatch(setNotificationCount({
                count: count + 1,
            }))

            alert('Delete Successful!')
            getinvestorData()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getinvestorData()
    }, [])

    return (
        <div className={OurStyle.companyDetailsMain}>
            <div className={OurStyle.companyDetailsContent}>
                <div className={OurStyle.companyDetailsHeader}>
                    <Typography sx={{ marginTop: '1%', color: '#121926', fontWeight: '600', fontSize: '20px' }} >
                        Our Team
                    </Typography>
                    <Link to={`/addour/${id}/${id2}`}>
                        <Button sx={{ textTransform: 'capitalize' }} variant='contained'>Add</Button>
                    </Link>
                </div>
                <Box className={OurStyle.companyDetailsComponent}>
                    <div className={OurStyle.companyDetailsComponentLeft}>
                        <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Name
                        </Typography>
                    </div>
                    <div className={OurStyle.companyDetailsComponentRight}>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Last modified
                        </Typography>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Action
                        </Typography>
                    </div>
                </Box>
                {data?.team?.map((detail) =>
                    <Box className={OurStyle.companyDetailsComponent}>
                        <div className={OurStyle.companyDetailsComponentLeft}>
                            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                                {detail?.teamName}
                            </Typography>
                        </div>
                        <div className={OurStyle.companyDetailsComponentRight}>
                            <Typography variant='h6' sx={{ fontSize: '15px' }}>
                                {detail?.date}
                            </Typography>
                            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600', display: 'flex', alignItems: 'baseline' }}>
                                {/* <Link to={`/editour/${detail?.teamId}`} > */}
                                <Link to={`/editour/${id}/${id2}/${detail?.teamId}`} state={{ graphInfo: detail }} >
                                    {/* <Link to= '/EditInvOppo'> */}
                                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </Link>
                                <IconButton disableRipple onClick={() => deleteDetailbyId(detail?.teamId)}>
                                    <DeleteForeverOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer', marginLeft: '10px' }} />
                                </IconButton>
                            </Typography>
                        </div>
                    </Box>
                ).reverse()}
            </div>
        </div>
    )
}

export default Ourteam





