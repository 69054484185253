import React, { useState } from 'react'
import addbusiStyle from './AddBusin.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
import { useParams } from 'react-router-dom';

const AddBusin = () => {
    const { id, id2 } = useParams();

    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()
    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [businessData, setbusinessData] = useState({
        businessHeading: '',
        businessLink: '',
    })
    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [imageSelect, setImageSelect] = useState(false)

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setbusinessData({ ...businessData, [name]: value })
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setImageSelect(true)
        setFile(file)
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardImage = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = ""
        setImageSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        formDataObj.append("businessHeading", businessData.businessHeading)
        formDataObj.append("businessLink", businessData.businessLink)

        try {
            const BusinResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/investorBusiness/addBusinessDetails/${id}/${id2}`,
                businessData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )

            console.log(BusinResponse)
            const imageResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/investorBusiness/uploadBusinessImage/${BusinResponse.data.businessId}`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "image/jpeg"
                    }
                }
            )
            navigate(`/theBusi/${id}/${id2}`)
            alert('Card Added.')
            dispatch(fetchNotifications())

            dispatch(setNotificationCount({
                count: count + 1,
            }))

            discardImage()

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={addbusiStyle.teammemberMain}>
            <div className={addbusiStyle.teammemberContent}>
                <Typography variant='h6'>Add Business</Typography>
                <form action="" className={addbusiStyle.teammemberForm} onSubmit={handleSubmit} encType='multipart/form-data'>

                    <label className={addbusiStyle.teammemberFormLabel}>Name</label>
                    <input className={addbusiStyle.teammemberFormText} type="text" value={businessData.businessHeading} name='businessHeading' onChange={handleInputChange} />

                    <label className={addbusiStyle.teammemberFormLabel}>Link</label>
                    <input className={addbusiStyle.teammemberFormText} type="text" value={businessData.businessLink} name='businessLink' onChange={handleInputChange} />

                    <label className={addbusiStyle.teammemberFormLabel}>Upload</label>
                    <div className={addbusiStyle.ImgInput}>
                        <input
                            ref={inputRef}
                            className={addbusiStyle.ImgInput_input}
                            type="file"
                            onChange={handleFileChange}
                        />
                        {!source && <button type='button' onClick={handleChoose}>Browse</button>}
                        {source && (
                            <img
                                className={addbusiStyle.ImgInput_img}
                                width="100%"
                                src={source}
                                alt='uploadedimage'
                            />
                        )}
                    </div>
                    <div className={addbusiStyle.ImgInput_footer}>
                        {imageSelect ?
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                {fileName}
                                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
                            </div> :
                            ''}
                    </div>
                    <Button variant='contained' type='submit' className={addbusiStyle.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default AddBusin