import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import AboutUsStyle from '../AboutUs/AboutUs.module.css';
import { Box, Button } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

import { Link } from 'react-router-dom';
import axios from 'axios';

const AboutUs = () => {

  return (
    <div className={AboutUsStyle.homepageMain}>
      <div className={AboutUsStyle.homepageContent}>
        <Typography sx={{ marginTop: '2%', color: '#121926', fontWeight: '600', fontSize: '20px' ,paddingBottom:'3%'}} >
          About Us
        </Typography>
        <Box className={AboutUsStyle.homepageComponent}>
          <div className={AboutUsStyle.homepageComponentLeft}>
            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
              Title
            </Typography>
          </div>
          <div className={AboutUsStyle.homepageComponentRight}>
            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
              Last modified
            </Typography>
            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
              Action
            </Typography>
          </div>
        </Box>
        <Box className={AboutUsStyle.homepageComponent}>
          <div className={AboutUsStyle.homepageComponentLeft}>
            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
            About Us  Video
            </Typography>
          </div>
          <div className={AboutUsStyle.homepageComponentRight}>
            <Typography variant='h6' sx={{ fontSize: '15px' }}>
              12.07.2018
            </Typography>
            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
              <Link to='/editAboutUs'>
                <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
              </Link>
            </Typography>
          </div>
        </Box>

   

      </div>
    </div>
  )
}

export default AboutUs