import React, { useEffect, useState } from 'react'
import editp from './EditPdf.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

import { useParams } from 'react-router-dom';


const EditPdf = () => {
    const { id, id2, id3, channelId } = useParams();

    // get data from redux
    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()

    const location = useLocation()
    const data = location.state

    const [channelData, setChannelData] = React.useState(
        {
            channelName: "",
            invPdf: "",
            channelType: "",
        }
    )

    const navigate = useNavigate()

    const inputRef = React.useRef();


    useEffect(() => {
        if (data) {
            setChannelData({
                channelName: data.channelData.channelName,
                invPdf: data.channelData.pdf.pdfFile,
                channelType: data.channelData.channelType

            })
        }

        console.log(id)
        console.log(id2)
        console.log(id3)

    }, [data])


    const handleInputs = (e) => {
        const { name, value } = e.target;

        setChannelData((prev) => {
            return (
                {
                    ...prev,
                    [name]: value,
                }
            )
        })
    }


    const handleSubmit = async (event) => {

        event.preventDefault();
        const formDataObj = new FormData()
        let resid = null;

        try {
            const resName = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/channel/updateChannel/${id2}`,
                {
                    channelName: channelData.channelName,
                    channelType: channelData.channelType
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log(resName)
            resid = resName.data.channelId

        } catch (error) {
            console.error(error)
        }
        
        try {
            const resUrl = await axios.post(`${process.env.REACT_APP_GECEADMIN}/pdf/updatePdf/${id}/${id2}/${id3}`,
                { pdfFile: channelData.invPdf },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log('Highlight Updated.')
            console.log(resUrl)
            alert('Pdf Updated.')

            navigate(`/EditInvOppo/${id}`)
            dispatch(fetchNotifications())

            dispatch(setNotificationCount({
                count: count + 1,
            }))


        } catch (error) {
            console.error(error)
        }
    }



    return (
        <div className={editp.teammemberMain}>
            <div className={editp.teammemberContent}>
                <form action="" className={editp.teammemberForm} onSubmit={handleSubmit} >
                    <label className={editp.teammemberFormLabel}>Name</label>
                    <input className={editp.teammemberFormText} type="text" value={channelData.channelName} name='channelName' onChange={handleInputs} />
                    <label className={editp.teammemberFormLabel}>Url</label>
                    <input className={editp.teammemberFormText} type="text" value={channelData.invPdf} name='invPdf' onChange={handleInputs} />
                    {/* <label className={editp.teammemberFormLabel}>Upload</label>
                    <div className={editp.ImgInput}>
                        <input
                            ref={inputRef}
                            className={editp.ImgInput_input}
                            type="file"
                            onChange={handleFileChange}
                        />
                        {!source && <button type='button' onClick={handleChoose}>Browse</button>}
                        {source && (
                            <img
                                className={editp.ImgInput_img}
                                width="100%"
                                src={`${process.env.REACT_APP_GECEADMIN}/graph/file/${data.graphs.images.newImage}`}
                                alt='uploadedimage'
                            />
                        )}
                    </div>
                    <div className={editp.ImgInput_footer}>
                        {imageSelect ?
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                {fileName}
                            </div> :
                            ''}
                    </div> */}
                    {/* <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} /> */}
                    <Button variant='contained' type='submit' className={editp.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default EditPdf