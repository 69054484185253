import React, { useState } from 'react'
import EditHighlightStyles from './AddInvestHighlight.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'


const AddInvestHighlight = () => {


    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch();

    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [investData, setInvestData] = useState({
        investmentHeader: '',
        investmentDetails: '',
    })
    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [imageSelect, setImageSelect] = useState(false)

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setInvestData({ ...investData, [name]: value })
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setImageSelect(true)
        setFile(file)
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardImage = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = ""
        setImageSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        formDataObj.append("investmentHeader", investData.investmentHeader)
        formDataObj.append("investmentDetails", investData.investmentDetails)

        try {
            const imageResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/investmentHighlights/uploadLogo`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "image/jpeg"
                    }
                }
            )
            console.log(imageResponse)

            const investResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/investmentHighlights/addInvestmentHighlights/${imageResponse.data.logoId}`,
                investData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            // console.log('Highlight Added.')
            console.log(investResponse)
            alert('Highlight Added.')
            navigate('/investment')


            const dateTime=investResponse.data.date;


            // const dateTime = investResponse.data.data.Time;

            const [dateString, timeString] = dateTime.split("T");

            const date = new Date(dateString);

            const time = timeString.substr(0, 8);


            const localDate = date.toLocaleDateString();

            dispatch(setNotifications({
                notification: {
                    name: name,
                    details: investResponse.data.message,
                    time: time,
                    date: localDate
                }
            }))
            dispatch(setNotificationCount({
                count: count + 1,
            }))

            console.log('Highlight Added.')
            alert('Highlight Added.')
            discardImage()
            navigate('/investment')
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={EditHighlightStyles.editHighlightMain}>
            <div className={EditHighlightStyles.editHighlightContent}>
                <form action="" className={EditHighlightStyles.editHighlightForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={EditHighlightStyles.editHighlightFormLabel}>Title</label>
                    <input className={EditHighlightStyles.editHighlightFormText} type="text" value={investData.investmentHeader} name='investmentHeader' onChange={handleInputChange} />
                    <label className={EditHighlightStyles.editHighlightFormLabel}>Description</label>
                    <textarea className={EditHighlightStyles.editHighlightFormDesc} value={investData.investmentDetails} name='investmentDetails' onChange={handleInputChange} />
                    <label className={EditHighlightStyles.editHighlightFormLabel}>Upload</label>
                    <div className={EditHighlightStyles.ImgInput}>
                        <input
                            ref={inputRef}
                            className={EditHighlightStyles.ImgInput_input}
                            type="file"
                            onChange={handleFileChange}
                        />
                        {!source && <button type='button' onClick={handleChoose}>Browse</button>}
                        {source && (
                            <img
                                className={EditHighlightStyles.ImgInput_img}
                                width="100%"
                                src={source}
                                alt='uploadedimage'
                            />
                        )}
                    </div>
                    <div className={EditHighlightStyles.ImgInput_footer}>
                        {imageSelect ?
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                {fileName}
                                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
                            </div> :
                            ''}
                    </div>
                    <Button variant='contained' type='submit' className={EditHighlightStyles.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default AddInvestHighlight