import React, { useEffect, useState } from 'react'
import BusinessStyles from './EditProject.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import useBackdrop from '../../hooks/useBackdrop/useBackdrop';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const EditProject = () => {


    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch();
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop()

    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const inputRef = React.useRef();
    const inputRef2 = React.useRef();
    const photosInputRef = React.useRef();

    const [fileName, setFileName] = React.useState('');
    const [imageSelect, setImageSelect] = useState(false)
    const [files, setFiles] = useState(null)
    const [sources, setCoverSources] = React.useState();
    const [fileNames, setFileNames] = React.useState([]);
    const [imageSelects, setImageSelects] = useState(false)



    const [projHeading, setprojHeading] = useState('')
    const [projClient, setprojClient] = useState('')
    //   const [portalLink, setportalLink] = useState('')
    const [projLocation, setprojLocation] = useState('')
    const [projCategory, setprojCategory] = useState('')
    const [projValue, setprojValue] = useState('')
    const [projDate, setprojDate] = useState('')
    const [projShortDesc, setprojShortDesc] = useState('')
    const [projDesc, setprojDesc] = useState('')
    const [whatYouWillBeDoing, setWhatYouWillBeDoing] = useState('')
    const [projLink, setProjLink] = useState('')

    const [coverFile, setCoverFile] = useState(null)
    const [coversSource, setCoverSource] = useState("");

    const [projectFiles, setProjectFiles] = useState([]);
    const [projectSources, setProjectSources] = useState([]);


    const fetchImage = (img) => {

    }

    useEffect(() => {
        if (data) {
            //   setportalName(data.portal.portalName)
            setprojHeading(data.projHeading)
            setprojClient(data.projClient)
            setprojLocation(data.projLocation)
            setprojCategory(data.projCategory)
            setprojValue(data.projValue)
            setProjLink(data.projLink)
            setprojDate(data.projDate)
            setprojShortDesc(data.projShortDesc)
            setprojDesc(data.projDesc)
            //   setportalLink(data.portal.portalLink)
        }


    }, [data])


    const handleCoverFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        // const url = URL.createObjectURL(file);
        // setCoverSource(url);
        // setImageSelect(true)
        setCoverFile(file)
    };



    const discardImage = () => {
        setCoverFile("")
        setCoverSource("")

        setProjectFiles([])
        setProjectSources([])
        inputRef.current.value = ""
        // setImageSelect(false)
    }


    const handleProjectPhotoChange = (event) => {
        const selectedFiles = Array.from(event.target.files);

        setProjectFiles((prevFiles) => {
            const filesArray = prevFiles || []; // Convert prevFiles to an empty array if it's null or undefined
            return [...filesArray, ...selectedFiles];
        });


    };
    const discardImages = (event) => {
        setFiles([]);
        setFileNames([]);
        setCoverSources([]);
        inputRef.current.value = "";
        setImageSelects(false);
    };




    const handleSubmit = async (event) => {



        event.preventDefault()
        openBackdrop()

        console.log("data:", data);
        console.log("data.projCover:", data?.projCover);
        
        if(projHeading!==""&& projClient !==""&& projLocation!==""&& projCategory!==""&& projValue!==""&& projLink!==""&& projDate!==""&& projShortDesc!==""&& projDesc!=="" && coverFile!==null && projectFiles.length>=1)
        {

        
        try {

            const formDataObjCover = new FormData()
            formDataObjCover.append("file", coverFile)


            const formDataObjProject = new FormData()


            for (let i = 0; i < projectFiles.length; i++) {
                formDataObjProject.append('files', projectFiles[i]);
            }

            const businessResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/project/updateProject/${data.projectId}`,
                { projHeading, projClient, projLocation, projCategory, projValue, projLink, projDate, projShortDesc, projDesc },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            // console.log('Project Updated.')
            // console.log(businessResponse)


            const imageResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/project/updateProjectCover/${data.projCover.coverId}`,
                formDataObjCover,
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            );


            // console.log(imageResponse)

            const PhotosResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/project/updateProjectPhotos/${data.projectId}`,
                formDataObjProject,
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }
            )

                
            discardImages()
            alert('Projected Updated.')
            navigate('/project')

            dispatch(fetchNotifications())

            dispatch(setNotificationCount({
                count: count + 1,
            }))
            closeBackdrop();
            // console.log("heloooooooooooooo", PhotosResponse)
        } catch (error) {
            alert("Error Editing Data")
            console.log("error Editing Data")
            
            closeBackdrop();
            console.error(error)
        }

    }
    else
    {
        if(projHeading===""|| projClient ===""||  projLocation===""||  projCategory===""||  projValue===""||  projLink===""||  projDate===""||  projShortDesc===""||  projDesc==="")
        {
            alert("Please Pull a Dash on the Field you dont want to fill! Fields can't be empty");
        }
        if(coverFile===null || coverFile===undefined || coverFile==="" )
        {
            alert("Project Cover Photo Not Selected")
        }
        if(projectFiles.length===0)
        {
            alert("Please add atleast one Project Photo")
        }
        closeBackdrop();
    }



    }






    


    useEffect(() => {
        const fetchImage = async () => {
            try {
                const imageResponse = await axios.get(
                    `${process.env.REACT_APP_GECEADMIN}/project/file/${data.projCover.newCoverImageName}`,
                    {
                        responseType: 'blob', // Setting the response type to blob
                    }
                );

                // Convert blob to a File object
                const file = new File([imageResponse.data], data.projCover.coverImageName, {
                    type: 'image/jpeg', // Set the appropriate MIME type here
                });

                setCoverFile(file);
                // setCoverSource(file);
            } catch (error) {
                console.error(error);
            }
        };

        fetchImage();
    }, []);


    useEffect(() => {
        const fetchImages = async () => {
            try {
                const responses = await Promise.all(
                    data.photos.map(async (tyr) => {
                        const response = await axios.get(
                            `${process.env.REACT_APP_GECEADMIN}/project/files/${tyr.newprojImageName}`,
                            {
                                responseType: 'blob', // Setting the response type to blob
                            }
                        );
                        return response.data;
                    })
                );

                const convertedFiles = responses.map((blob, index) => {
                    return new File(
                        [blob],
                        data.photos[index].projImageName,
                        { type: 'image/jpeg' } // Set the appropriate MIME type here
                    );
                });

                setProjectFiles(convertedFiles);


            } catch (error) {
                console.error(error);
            }
        };

        fetchImages();
    }, []);

    // Render multiple input fields for selecting photos
    const renderImageInputs = () => {
        return fileNames.map((fileName, index) => (
            <div key={index} className={BusinessStyles.ImgInput}>
                <img
                    className={BusinessStyles.ImgInput_img}
                    width="100%"
                    src={sources[index]}
                    alt={fileName}
                />
            </div>
        ));
    };

    const generateLocalUrl = (file) => {
        try {
            const url = URL.createObjectURL(file);
            return url;
        } catch (error) {
            console.error('Error generating local URL:', error);
            return null;
        }
    };


    const handleRemoveProjectFile = (index) => {
        console.log(index)

        setProjectFiles(prevProjectFiles => prevProjectFiles.filter((_, i) => i !== index));

    }

    return (
        <>
        <BackdropComponent/>
        <div className={BusinessStyles.businessMain}>
        <div className={BusinessStyles.TabHeader}>
        <h1 ><span onClick={()=>{navigate("/project",{replace:true})}}><ArrowBackIosIcon/></span>Edit Project</h1>
        </div>
            <form action="" className={BusinessStyles.editHighlightForm} onSubmit={handleSubmit} encType='multipart/form-data'>

                <div className={BusinessStyles.projectFormRow}>
                    <label >Project Name<span>*</span></label>
                    <input type="text" value={projHeading} name='projHeading' onChange={(event) => setprojHeading(event.target.value)} />
                </div>
                <div className={BusinessStyles.projectFormRow}>
                    <label >Client<span>*</span></label>
                    <input type="text" value={projClient} name='projClient' onChange={(event) => setprojClient(event.target.value)} />
                </div>
                <div className={BusinessStyles.projectFormRow}>
                    <label >Location<span>*</span></label>
                    <input type="text" value={projLocation} name='projLocation' onChange={(event) => setprojLocation(event.target.value)} />
                </div>
                <div className={BusinessStyles.projectFormRow}>
                    <label >Category<span>*</span></label>
                    <input type="text" value={projCategory} name='projCategory' onChange={(event) => setprojCategory(event.target.value)} />
                </div>
                <div className={BusinessStyles.projectFormRow}>
                    <label >Value<span>*</span></label>
                    <input type="text" value={projValue} name='projValue' onChange={(event) => setprojValue(event.target.value)} />
                </div>
                <div className={BusinessStyles.projectFormRow}>
                    <label >Project Date<span>*</span></label>
                    <input type="text" value={projDate} name='projDate' onChange={(event) => setprojDate(event.target.value)} />
                </div>
                {/* <label >Location</label>
          <input type="text" value={projCategory} name='projCategory' onChange={(event) => setprojLocation(event.target.value)} /> */}


                <div className={BusinessStyles.projectFormRow}>
                    <label >Short Description<span>*</span></label>
                    <div className={BusinessStyles.reactQuill}>
                        <ReactQuill theme="snow"

                            value={projShortDesc}
                            onChange={setprojShortDesc} />
                    </div>
                    {/* <input type="text" value={projShortDesc} name='projShortDesc' onChange={(event) => setprojShortDesc(event.target.value)} /> */}
                </div>
                <div className={BusinessStyles.projectFormRow}>
                    <label >Link<span>*</span></label>
                    <input type="text"
                        value={projLink} name='projLink' onChange={(event) => setProjLink(event.target.value)} />
                </div>
                <div className={BusinessStyles.projectFormRow}>
                    <label >Upload Cover Photo<span>*</span></label>

                    <div className={BusinessStyles.businessFormImagePreview}>
                        <div className={BusinessStyles.businessFormImagePreviewRow}>
                            {coverFile && <div className={BusinessStyles.businessFormImagePreviewCont}>
                                <img
                                    className={BusinessStyles.ImgInput_img}
                                    width="100%"
                                    src={generateLocalUrl(coverFile)}

                                    alt='uploadedimage'
                                />
                                <span onClick={() => { setCoverFile(null) }}>x</span>
                            </div>}

                        </div>
                        <div className={BusinessStyles.ImgInputDiv} >


                            <input
                                ref={inputRef}
                                hidden
                                type="file"
                                onChange={handleCoverFileChange}
                            />
                            <p>{coverFile?.name}</p>
                            <button type='button' onClick={() => { inputRef?.current.click(); }} >Browse</button>



                        </div>

                    </div>
                </div>


                <div className={BusinessStyles.projectFormRow}>
                    <label >Project Description<span>*</span></label>
                    <div className={BusinessStyles.reactQuill}>
                        <ReactQuill
                            theme="snow"
                            value={projDesc}
                            onChange={setprojDesc}
                        />
                    </div>

                </div>



                <div className={BusinessStyles.projectFormRow}>
                    <label >Project Images <span>*</span></label>
                    {/* {renderImageInputs()} */}

                    <span >(only .jpg / .png)</span>
                    <div className={BusinessStyles.businessFormImagePreview}>

                        <div className={BusinessStyles.ImgInputDiv} >


                            <input
                                ref={inputRef2}
                                hidden
                                type="file"
                                onChange={handleProjectPhotoChange}
                                multiple
                            />
                            <p>{projectFiles.map((rt) => {
                                return rt.name
                            }).join(", ")}</p>
                            <button type='button' onClick={() => { inputRef2?.current.click(); }} >Browse</button>



                        </div>

                        <div className={BusinessStyles.businessFormImagePreviewRow}>
                            {projectFiles.map((pro, index) => {
                                return (
                                    <div className={BusinessStyles.businessFormImagePreviewCont}>
                                        <img
                                            className={BusinessStyles.ImgInput_img}
                                            width="100%"
                                            src={generateLocalUrl(pro)}

                                            alt='uploadedimage'
                                        />
                                        <span onClick={() => { handleRemoveProjectFile(index) }}>x</span>
                                    </div>
                                )

                            })}

                        </div>


                    </div>
                </div>






                <div className={BusinessStyles.projectFormButtonRow}>
                    <Button variant='contained' type='submit' className={BusinessStyles.saveChanges}>Save changes</Button>
                </div>

            </form>

        </div >
        </>
    )
}

export default EditProject