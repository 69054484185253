import React from 'react'
import EditInvStyle from './EditInvOpportunities.module.css'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { useParams, useNavigate } from 'react-router-dom';
import { fetchNotifications } from '../../state/GlobalState'
import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
// import this for redux read write
const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 18,
    p: 4,
};


const EditInvOpportunities = () => {
    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const [checked, setChecked] = React.useState(true);
    const { id,id2,id3 } = useParams();
    const [data, setData] = useState([])
    const [channel, setChannel] = useState('')
    const [channelResponse,setChannelResponse]=React.useState("");
    var mynewid = 'id2'
    // function ChildModal({type}) {

    //     const [open, setOpen] = React.useState(false);
    //     const [channelFormData,setChannelFormData]=React.useState({
    //         channelName:"",
    //         channelType:type
    //     });


    // const handleInput=(e)=>{
    //     const {name,value}=e.target;
    //     setChannelFormData((prev)=>{
    //        return ({
    //         ...prev,
    //         [name]:value,
    //        })
    //     })
    // }

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const handleChannelSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/getInvestmentOpportunitiesById/${id}`
            )
            // setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    // const [careerCategoryName, setCareerCategoryName] = useState('')
    // const [postSuccess, setPostSuccess] = useState(false)

    // const handlePostDepartment = async (event) => {
    //     event.preventDefault()
    //     try {
    //         const deptResponse = await axios.post(
    //             `${process.env.REACT_APP_GECEADMIN}/career/addCategory`,
    //             { careerCategoryName },
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //             }
    //         )
    //         dispatch(fetchNotifications())
    //         alert('Department Added.')

    //         dispatch(setNotificationCount({
    //             count: count + 1,
    //         }))


    //         setPostSuccess(true)
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }

    //     return (
    //         <React.Fragment>
    //             <Button className={EditInvStyle.childModalButton} onClick={handleOpen}>Add</Button>
    //             <Modal
    //                 open={open}
    //                 onClose={handleClose}
    //                 aria-labelledby="child-modal-title"
    //                 aria-describedby="child-modal-description"
    //             >
    //                 <Box sx={{ ...style }} className={EditInvStyle.childModalStart}>

    //                     <Typography variant='h6'>Add Name</Typography>
    //                     {/* <form action="" className={EditInvStyle.departmentForm} onSubmit={handlePostDepartment}> */}
    //                     <form onSubmit={handleChannelSubmit}  >
    //                         <Box className={EditInvStyle.departmentForm}>
    //                             <label className={EditInvStyle.departmentFormLabel}>Name</label>
    //                             <input type='text' name='channelName' value={channelFormData.channelName} onChange={handleInput}></input>
    //                             {/* <input type='hidden' value={type} name='channelType'></input> */}
    //                             <Button className={EditInvStyle.departmentFormBtn} type='submit' variant='contained'>Add</Button>
    //                         </Box>
    //                     </form>

    //                 </Box>
    //             </Modal>
    //         </React.Fragment>
    //     );
    // }


    // get data from redux


    const getHighlightsData = async () => {

        console.log("heelsa sa")
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/getInvestmentOpportunitiesById/${id}`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDetailbyId = async (channelId) => {
        try {
            const res = await axios.delete(`${process.env.REACT_APP_GECEADMIN}/channel/deleteChannel/${channelId}`)

            console.log(res)
            dispatch(fetchNotifications())

            dispatch(setNotificationCount({
                count: count + 1,
            }))

            getHighlightsData()
            alert('Delete Successful!')
            getHighlightsData()
        } catch (error) {
            console.log(error)
        }
    }

   

    const handleClick2 = async (event) => {
        event.preventDefault()
       
        try {
            const resName = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/channel/addChannel/${id}`,
                { channelName: channel, channelType: 'Pitch' },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log(resName)
            
           const ttId=resName.data.channelId;
               
           navigate(`/pitch/${id}/${ttId}`)
               
            
            // navigate(`/pitch/${id}`)
           
        } catch (error) {
            console.error(error)
        }
    }


    useEffect(() => {
        getHighlightsData();

    }, [])

    const handleClick = (type) => {
        navigate("/formates", { state: { id: id, type: type } }, { replace: true })
    }
    const handleClick3 = (type) => {
        navigate("/videofor", { state: { id: id, type: type } }, { replace: true })
    }

    // const handleClick2 = (type) => {
    //     navigate("/videofor", { state: { id: id, type: type } }, { replace: true })
    // }

    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);




    const handlePageChange=(type,channelId,channel)=>{

        console.log(type);
        switch(type)
        {
            case 'pdf':navigate(`/editpdf/${id}/${channel.channelId}/${channel.pdf.pdfId}`, {state:{channelData:channel}} );
                        console.log("hello")
                break;
            case 'Pitch':navigate(`/pitch/${id}/${channel.channelId}/${channel?.summaryoverview?.summaryId}`,{state:{channelData:channel}});
                break;
            case 'Video':navigate(`/editVi/${id}/${channel.channelId}/${channel.file.fileId}`, {state:{channelData:channel}} );
                break;
            default:console.log("no type selected");
        }
    }


    return (
        <div className={EditInvStyle.highlightMain}>
            <div className={EditInvStyle.highlightContent}>
                <div className={EditInvStyle.highlightHeader}>
                    <div style={{marginBottom:'2%',display:'flex',width:'100%',justifyContent:'space-between',marginTop:'1%'}}>
                    <Typography sx={{  color: '#121926', fontWeight: '600', fontSize: '20px',display:'flex',alignItems:'center',gap:'10px' }} >
                        {data.invName}
                        <span onClick={()=>{navigate(`/editInvestmentBanner/${id}`)}}>
                         <DriveFileRenameOutlineIcon style={{cursor:'pointer'}} />
                         </span>
                    </Typography>
                    <Button sx={{ textTransform: 'capitalize',width:'10%' }} variant='contained' onClick={handleOpen}>Add</Button>
                    </div>

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box sx={{ ...style }} className={EditInvStyle.InvModal}>
                            <div className={EditInvStyle.modalClose}>
                                <Typography variant='h2' >Select type</Typography>
                                <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
                            </div>
                            <Box className={EditInvStyle.InvPermission}>
                                <label>PDF</label>
                                <Button variant="contained" type={"Pdf"} onClick={() => handleClick("pdf")}>Select</Button>

                            </Box>
                            <Box className={EditInvStyle.InvPermission}>
                                <label>Video</label>
                                <Button variant="contained" type={"Video"} onClick={() => handleClick3("Video")}>Select</Button>

                            </Box>
                            <Box className={EditInvStyle.InvPermission}>
                                <label>Pitch</label>
                                {/* <Button variant="contained" type={"Pitch"} onClick={() => handleClick2("Pitch")}>Select</Button> */}
                                <Button variant="contained" type={"Pitch"} onClick={handleOpen2}>Select</Button>
                                <Modal
                                    open={open2}
                                    onClose={handleClose2}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style2} className={EditInvStyle.secondfromStart}>
                                        <form onSubmit={handleClick2}>
                                            <div className={EditInvStyle.secondfrom}>
                                                <Typography variant='h2'>Add Name</Typography>

                                            </div>
                                            <div className={EditInvStyle.secondfrom}>
                                                <label>Name</label>
                                                <input type='text' placeholder='Name' name='channel'  value={channel} onChange={(event) => setChannel(event.target.value)}/>
                                            </div>
                                            {/* <Link to='/pitch'> */}
                                            <Button variant="contained" type='submit'>Add</Button>
                                            {/* </Link> */}
                                        </form>
                                    </Box>
                                </Modal>

                            </Box>
                        </Box>
                    </Modal>

                </div>
                <Box className={EditInvStyle.highlightComponent}>
                    <div className={EditInvStyle.highlightComponentLeft}>
                        <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Name
                        </Typography>
                    </div>
                    <div className={EditInvStyle.highlightComponentRight}>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Last modified
                        </Typography>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Action
                        </Typography>
                    </div>
                </Box>
                {data.channels?.map((highlight) => {
                    return (
                        <Box className={EditInvStyle.highlightComponent}>
                            <div className={EditInvStyle.highlightComponentLeft}>
                                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                                    {highlight.channelName}
                                </Typography>
                            </div>
                            <div className={EditInvStyle.highlightComponentRight}>
                                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                                    {data.date}
                                </Typography>
                                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600', display: 'flex', alignItems: 'baseline' }}>

                                    <i onClick={()=>handlePageChange(highlight.channelType,highlight.channelId,highlight)}>
                                        <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                    </i>
                                    <IconButton disableRipple onClick={() => deleteDetailbyId(highlight.channelId)}>
                                        <DeleteForeverOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                    </IconButton>
                                </Typography>
                            </div>
                        </Box>
                    )

                })}

            </div>
        </div>
    )
}

export default EditInvOpportunities






