import React from 'react'
import Typography from '@mui/material/Typography';
import ContactUsStyles from './ContactUs.module.css';
import { Box, Button } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { Link } from 'react-router-dom';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';


export const ContactUs = () => {
  return (
    <div className={ContactUsStyles.contactUsMain}>
    <div className={ContactUsStyles.contactUsContent}>
        <Typography sx={{ marginTop: '1%', color: '#121926', fontWeight: '600', fontSize: '20px',marginBottom:'3%' }} >
            Contact Us
        </Typography>
        <Box className={ContactUsStyles.contactUsComponent}>
            <div className={ContactUsStyles.contactUsComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Title
                </Typography>
            </div>
            <div className={ContactUsStyles.contactUsComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Last modified
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Action
                </Typography>
            </div>
        </Box>
        <Box className={ContactUsStyles.contactUsComponent}>
            <div className={ContactUsStyles.contactUsComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Branch Address
                </Typography>
            </div>
            <div className={ContactUsStyles.contactUsComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                    12.07.2018
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    <Link to='/branchaddress'>
                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                    </Link>
                </Typography>
            </div>
        </Box>
    </div>
</div>
  )
}

export default ContactUs