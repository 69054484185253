import React from 'react'
import BranchAddressStyles from './BranchAddress.module.css'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';


// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'

import { fetchNotifications } from '../../state/GlobalState'

const BranchAddress = () => {

         // get data from redux
         const name=useSelector((state)=>state.globalData.name);
         const count=useSelector((state)=>state.globalData.notificationCount);
         const dispatch =  useDispatch()
    

    const branch = [1, 2, 3]

    const [data, setData] = useState([])

    const getAddressData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/address/getAllAddress`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteAddressbyId = async (addressId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/address/deleteAddressById/${addressId}`
            )
            dispatch(fetchNotifications())

              // this is the snipte for the notification

    //    const dateTime=res.data.date;

    //    const [dateString,timeString]=dateTime.split("T");

    //    const date=new Date(dateString);

    //    const time=timeString.substr(0,8);


    //    const localDate=date.toLocaleDateString();

    //    dispatch(setNotifications({
    //        notification:{
    //          name:name,
    //          details:res.data.message,
    //          time:time,
    //          date:localDate
    //        }
    //      }))
         dispatch(setNotificationCount({
          count:count+1,
         }))
         

            console.log(res.data)
            
            getAddressData();
            alert('Delete Successful!' )
            // window.location.reload()
            getAddressData()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAddressData()
    }, [])

    return (
        <div className={BranchAddressStyles.branchAddressMain}>
            <div className={BranchAddressStyles.branchAddressContent}>
                <div className={BranchAddressStyles.branchAddressHeader}>
                    <Typography sx={{ marginTop: '1%', color: '#121926', fontWeight: '600', fontSize: '20px' }} >
                        Branch Addresses
                    </Typography>
                    <Link to='/addaddress'>
                        <Button sx={{ textTransform: 'capitalize' }} variant='contained'>Add Branch</Button>
                    </Link>
                </div>
                <Box className={BranchAddressStyles.branchAddressComponent}>
                    <div className={BranchAddressStyles.branchAddressComponentLeft}>
                        <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Name
                        </Typography>
                    </div>
                    <div className={BranchAddressStyles.branchAddressComponentRight}>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Last modified
                        </Typography>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Action
                        </Typography>
                    </div>
                </Box>
                {data.map((address) =>
                    <Box className={BranchAddressStyles.branchAddressComponent}>
                        <div className={BranchAddressStyles.branchAddressComponentLeft}>
                            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                                {address.branchAddressName}
                            </Typography>
                        </div>
                        <div className={BranchAddressStyles.branchAddressComponentRight}>
                            <Typography variant='h6' sx={{ fontSize: '15px' }}>
                                {address.date}
                            </Typography>
                            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600', display: 'flex', alignItems: 'baseline' }}>
                                <Link to={`/editaddress/${address?.addressId}`} state={address}>
                                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </Link>
                                <IconButton disableRipple onClick={() => deleteAddressbyId(address.addressId)}>
                                    <DeleteForeverOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                </IconButton>
                            </Typography>
                        </div>
                    </Box>
                ).reverse()}
            </div>
        </div>
    )
}

export default BranchAddress