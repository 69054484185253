import React, { useEffect, useState } from 'react'
import EditInvStyle2 from './EditInvOppo2.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditInvOppo2 = () => {

    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [imageSelect, setImageSelect] = useState(false)

    const [investmentHeader, setInvestmentHeader] = useState('')
    const [investmentDetails, setInvestmentDetails] = useState('')

    useEffect(() => {
        if (data) {
            setInvestmentHeader(data.highlights?.investmentHeader)
            setInvestmentDetails(data.highlights?.investmentDetails)
        }
    }, [data])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setImageSelect(true)
        setFile(file)
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardImage = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = ""
        setImageSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        formDataObj.append("investmentHeader", investmentHeader)
        formDataObj.append("investmentDetails", investmentDetails)

        try {
            const imageResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/investmentHighlights/uploadLogoById/${data.logoId}`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "image/jpeg"
                    }
                }
            )
            console.log(imageResponse)

            const highlightResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/investmentHighlights/updateInvestmentHighlights/${data.highlights.investmentId}`,
                { investmentHeader, investmentDetails },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log('Highlight Updated.')
            console.log(highlightResponse)
            alert('Highlight Updated.')
            discardImage()
            navigate('/investment')
        } catch (error) {
            console.error(error)
        }
    }

    // useEffect(() => {
    //     const fetchImage = async () => {
    //       try {
    //         const imageResponse = await axios.get(
    //           `${process.env.REACT_APP_GECEADMIN}/investmentHighlights/file/${data.logo}`
    //         );
    //         console.log(imageResponse);
    //         setSource(imageResponse);
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     };
    
    //     fetchImage();
    //   }, [data.logo]);
    

    return (
        <div className={EditInvStyle2.editHighlightMain}>
            <div className={EditInvStyle2.editHighlightContent}>
                <form action="" className={EditInvStyle2.editHighlightForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={EditInvStyle2.editHighlightFormLabel}>Title</label>
                    <input className={EditInvStyle2.editHighlightFormText} type="text" value={investmentHeader === null? 'Empty Value': investmentHeader} name='investmentHeader' onChange={(event) => setInvestmentHeader(event.target.value)} />
                    <label className={EditInvStyle2.editHighlightFormLabel}>Description</label>
                    <textarea className={EditInvStyle2.editHighlightFormDesc} value={investmentDetails  === null? 'Empty Value': investmentDetails} name='investmentDetails' onChange={(event) => setInvestmentDetails(event.target.value)}/>
                    <label className={EditInvStyle2.editHighlightFormLabel}>Url</label>
                    <div className={EditInvStyle2.ImgInput}>
                        <input
                            ref={inputRef}
                            className={EditInvStyle2.ImgInput_input}
                            type="file"
                            onChange={handleFileChange}
                        />
                        {!source && <button type='button' onClick={handleChoose}>Browse</button>}
                        {source && (
                            <img
                                className={EditInvStyle2.ImgInput_img}
                                width="100%"
                                src={`${process.env.REACT_APP_GECEADMIN}/investmentHighlights/file/${data.logo}`}
                                alt='uploadedimage'
                            />
                        )}
                            </div>
                        <div className={EditInvStyle2.ImgInput_footer}>
                            {imageSelect ?
                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                    {fileName}
                                </div> :
                                ''}
                                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
                        </div>
                    <Button variant='contained' type='submit' className={EditInvStyle2.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default EditInvOppo2

