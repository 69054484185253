import React, { useState } from 'react'
import EditHighlightStyles from './AddProject.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useBackdrop from '../../hooks/useBackdrop/useBackdrop';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const AddProject = () => {
    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()
    const {openBackdrop,closeBackdrop,BackdropComponent}=useBackdrop()
    // formDataObj.append("file", file);
    // formDataObj.append("files", files);




    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const inputRef = React.useRef();
    const inputRef2 = React.useRef();
    const photosInputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [files, setFiles] = useState(null)
    const [investData, setInvestData] = useState({
        projHeading: '',
        projClient: '',
        projLocation: '',
        projCategory: '',
        projValue: '',
        projLink: '',
        projDate: '',
        projShortDesc: '',
        projDesc: '',
    })
    const [source, setSource] = React.useState();
    const [sources, setSources] = React.useState();

    const [fileName, setFileName] = React.useState('');
    const [fileNames, setFileNames] = React.useState([]);
    const [prevFiles, setPrevFiles] = useState([]);

    const [imageSelect, setImageSelect] = useState(false)
    const [imageSelects, setImageSelects] = useState(false)

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setInvestData({ ...investData, [name]: value })
    }
    const handleShortDescChange = (value) => {
        setInvestData({ ...investData, projShortDesc: value });
    };
    const handleProjDescChange = (value) => {
        setInvestData({ ...investData, projDesc: value });
        // setProjDesc(value);
    };

    const [coverFile, setCoverFile] = useState(null)


    const [projectFiles, setProjectFiles] = useState([]);


    const handleCoverFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)

        setCoverFile(file)
    };

    const handleProjectPhotoChange = (event) => {
        const selectedFiles = Array.from(event.target.files);

        setProjectFiles((prevFiles) => {
            const filesArray = prevFiles || []; // Convert prevFiles to an empty array if it's null or undefined
            return [...filesArray, ...selectedFiles];
        });

    };
    const discardImages = (event) => {


        setCoverFile(null);
        setProjectFiles([]);
        setImageSelects(false);
    };





    const handleSubmit = async (event) => {
        openBackdrop();
        event.preventDefault()

        const hasEmptyFields = Object.values(investData).some((value) => value === "");
        console.log(hasEmptyFields);
        if(!hasEmptyFields && coverFile!==null && projectFiles.length>=1)
        {
            try {
                const formDataObjCover = new FormData()
                formDataObjCover.append("file", coverFile)
    
    
                const formDataObjProject = new FormData()
    
    
                for (let i = 0; i < projectFiles.length; i++) {
                    formDataObjProject.append('files', projectFiles[i]);
                }
    
    
                const investResponse = await axios.post(
                    `${process.env.REACT_APP_GECEADMIN}/project/addProject`,
                    investData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                ).then(async(res)=>{

                    const imageResponse = await axios.post(
                        `${process.env.REACT_APP_GECEADMIN}/project/uploadProjectCover/${res.data.projectId}`,
                        formDataObjCover,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        }
                    ).catch((error)=>{
                        alert("Error Adding Project Cover")
                        console.log("Error Adding Project Cover")
                    })
                    // console.log(imageResponse)
        
        
        
        
                    const imageResponses = await axios.post(
                        `${process.env.REACT_APP_GECEADMIN}/project/uploadProjectPhotos/${res.data.projectId}`,
                        formDataObjProject,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        }
                    ).catch((error)=>{
                        alert("Error Adding Project photos")
                        console.log("Error Adding Project photos")
                    })

                    dispatch(fetchNotifications())
    
                    dispatch(setNotificationCount({
                        count: count + 1,
                    }))
                    // console.log(imageResponses)
        
                    alert('Project Added.')
        
                    navigate('/project',{replace:true})
        
                    discardImages();

                    closeBackdrop();
                }).catch((error)=>{
                    alert("Error Adding Data")
                    console.log("error Adding Data")
                    closeBackdrop();
                })
    
    
                // console.log(investResponse)
    
                // this is the snipte for the notification
               
    
            } catch (error) {
                console.error(error)
                closeBackdrop();
            }
        }
        else
        {
            if(hasEmptyFields)
            {
                alert("Please Pull a Dash on the Field you dont want to fill! Fields can't be empty");
            }
            if(coverFile===null || coverFile===undefined || coverFile==="" )
            {
                alert("Project Cover Photo Not Selected")
            }
            if(projectFiles.length===0)
            {
                alert("Please add atleast one Project Photo")
            }
            closeBackdrop();
        }
       
    }

    const removeImage = (index) => {
        // Create a copy of the sources, fileNames, and files arrays
        const newSources = [...sources];
        const newFileNames = [...fileNames];
        const newFiles = [...files];

        // Remove the image, filename, and file at the specified index
        newSources.splice(index, 1);
        newFileNames.splice(index, 1);
        newFiles.splice(index, 1);

        // Update the state with the new arrays
        setSources(newSources);
        setFileNames(newFileNames);
        setFiles(newFiles);

        // Check if there are still images, and set imageSelects accordingly
        setImageSelects(newSources.length > 0);
    };



    const generateLocalUrl = (file) => {
        try {
            const url = URL.createObjectURL(file);
            return url;
        } catch (error) {
            console.error('Error generating local URL:', error);
            return null;
        }
    };


    const handleRemoveProjectFile = (index) => {
        console.log(index)

        setProjectFiles(prevProjectFiles => prevProjectFiles.filter((_, i) => i !== index));

    }



    return (
        <>
        <BackdropComponent/>
        <div className={EditHighlightStyles.editHighlightMain}>
        <div className={EditHighlightStyles.TabHeader}>
        <h1 ><span onClick={()=>{navigate("/project",{replace:true})}}><ArrowBackIosIcon/></span>Add Project</h1>
        </div>
                <form action="" className={EditHighlightStyles.editHighlightForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <div className={EditHighlightStyles.projectFormRow}>

                        <label >Project Name<span>*</span></label>
                        <input type="text" required value={investData.projHeading} name='projHeading' onChange={handleInputChange} />
                    </div>
                    <div className={EditHighlightStyles.projectFormRow}>
                        <label >Client<span>*</span> </label>
                        <input type="text" required value={investData.projClient} name='projClient' onChange={handleInputChange} />
                    </div>
                    <div className={EditHighlightStyles.projectFormRow}>
                        <label >Location <span>*</span></label>

                        <input type="text" required value={investData.projLocation} name='projLocation' onChange={handleInputChange} />
                    </div>
                    <div className={EditHighlightStyles.projectFormRow}>
                        <label >Category<span>*</span></label>
                        <input type="text" required value={investData.projCategory} name='projCategory' onChange={handleInputChange} />
                    </div>
                    <div className={EditHighlightStyles.projectFormRow}>
                        <label >Value <span>*</span></label>
                        <input type="text" required value={investData.projValue} name='projValue' onChange={handleInputChange} />
                    </div>
                    <div className={EditHighlightStyles.projectFormRow}>
                        <label >Link<span>*</span></label>
                        <input type="text" required value={investData.projLink} name='projLink' onChange={handleInputChange} />
                    </div>
                    <div className={EditHighlightStyles.projectFormRow}>
                        <label >Project Date<span>*</span></label>
                        <input type="text" required value={investData.projDate} name='projDate' onChange={handleInputChange} />
                    </div>
                    <div className={EditHighlightStyles.projectFormRow}>
                        <label >Short Description<span>*</span></label>
                        <div className={EditHighlightStyles.reactQuill}>
                        <ReactQuill theme="snow"
                            
                            value={investData.projShortDesc}
                            onChange={handleShortDescChange} />
                        </div>
                       
                    </div>
                    {/* <input type="text" value={investData.projShortDesc} name='projShortDesc' onChange={handleInputChange} /> */}
                    {/* <label >Cover Image</label>
                    <input type="text" value={investData.investmentHeader} name='investmentHeader' onChange={handleInputChange} /> */}
                    <div className={EditHighlightStyles.projectFormRow}>
                        <label >Upload Cover Photo<span>*</span></label>
                        <div className={EditHighlightStyles.businessFormImagePreview}>
                            <div className={EditHighlightStyles.businessFormImagePreviewRow}>
                                {coverFile && <div className={EditHighlightStyles.businessFormImagePreviewCont}>
                                    <img
                                        className={EditHighlightStyles.ImgInput_img}
                                        width="100%"
                                        src={generateLocalUrl(coverFile)}

                                        alt='uploadedimage'
                                    />
                                    <span onClick={() => { setCoverFile(null) }}>x</span>
                                </div>}

                            </div>
                            <div className={EditHighlightStyles.ImgInputDiv} >


                                <input
                                    ref={inputRef}
                                    hidden
                                    type="file"
                                    onChange={handleCoverFileChange}
                                />
                                <p>{coverFile?.name}</p>
                                <button type='button' onClick={() => { inputRef?.current.click(); }} >Browse</button>



                            </div>

                        </div>
                    </div>
                    <div className={EditHighlightStyles.projectFormRow}>

                        <label >Project Description<span>*</span></label>
                        <div className={EditHighlightStyles.reactQuill}>
                        <ReactQuill
                            className={EditHighlightStyles.customQuill} // Use the appropriate class name from your CSS module
                            theme="snow"
                            value={investData.projDesc}
                            onChange={handleProjDescChange}
                        />
                        </div>
                    </div>
                    {/* <textarea className={EditHighlightStyles.editHighlightFormDesc} value={investData.projDesc} name='projDesc' onChange={handleInputChange} /> */}




                    <div className={EditHighlightStyles.projectFormRow}>

                        <label className={EditHighlightStyles.businessFormLabel}>Project Images<span>*</span></label>
                        {/* {renderImageInputs()} */}

                        <span className={EditHighlightStyles.businessFormLabel}>(only .jpg / .png)</span>
                        <div className={EditHighlightStyles.businessFormImagePreview}>

                            <div className={EditHighlightStyles.ImgInputDiv} >


                                <input
                                    ref={inputRef2}
                                    hidden
                                    type="file"
                                    onChange={handleProjectPhotoChange}
                                    multiple
                                />
                                <p>{projectFiles.map((rt) => {
                                    return rt.name
                                }).join(", ")}</p>
                                <button type='button' onClick={() => { inputRef2?.current.click(); }} >Browse</button>



                            </div>

                            <div className={EditHighlightStyles.businessFormImagePreviewRow}>
                                {projectFiles.map((pro, index) => {
                                    return (
                                        <div className={EditHighlightStyles.businessFormImagePreviewCont}>
                                            <img
                                                className={EditHighlightStyles.ImgInput_img}
                                                width="100%"
                                                src={generateLocalUrl(pro)}

                                                alt='uploadedimage'
                                            />
                                            <span onClick={() => { handleRemoveProjectFile(index) }}>x</span>
                                        </div>
                                    )

                                })}

                            </div>


                        </div>
                    </div>



                    <div className={EditHighlightStyles.projectFormButtonRow}>
                        <Button variant='contained' type='submit' className={EditHighlightStyles.saveChanges}>Save changes</Button>
                    </div>
                </form>

            
        </div >
        </>
    )
}

export default AddProject