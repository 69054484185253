import React, { useState } from 'react'
import adddocStyle from './AddDoc.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
import { useParams } from 'react-router-dom';

const AddDoc = () => {
    const { id, id2 } = useParams();

    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()
    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    // const [DocumentData, setDocumentData] = useState({
    //     graphName: '',
    // })
    const [source, setSource] = React.useState();
    const [previewUrl, setPreviewUrl] = useState('');
    const [fileName, setFileName] = React.useState('');
    const [pdfSelect, setPdfSelect] = useState(false)

    // const handleInputChange = (event) => {
    //     const { name, value } = event.target
    //     setDocumentData({ ...DocumentData, [name]: value })
    // }

    const handlePdfChange = (event) => {
        const selectedFile = event.target.files[0]
        setFile(selectedFile)
        setPreviewUrl(URL.createObjectURL(selectedFile))
        setPdfSelect(true)
    }
    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardPdf = (event) => {
        // setSource(null)
        // setFileName('')
        setPreviewUrl('')
        setFile(null)
        inputRef.current.value = ""
        setPdfSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        // formDataObj.append("graphName", DocumentData.graphName)
        // formDataObj.append("graphDesc", DocumentData.graphDesc)

        try {
            const pdfResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/documents/uploadDocument/${id}/${id2}`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "application/pdf",
                    },
                }
            )

            console.log(pdfResponse)
            // const imageResponse = await axios.post(
            //     `${process.env.REACT_APP_GECEADMIN}/graph/addGraphImage/${pdfResponse.data.graphId}`,
            //     formDataObj,
            //     {
            //         headers: {
            //             "Content-Type": "image/jpeg"
            //         }
            //     }
            // )
            // alert('Document Added.')
            // navigate(`/doc/${id}/${id2}`)
            dispatch(fetchNotifications())

              dispatch(setNotificationCount({
               count:count+1,
              }))
              
            //   uptill here 
                 alert('Document Added.')
                 discardPdf()
                 navigate(`/doc/${id}/${id2}`)

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={adddocStyle.teammemberMain} >
            <div className={adddocStyle.teammemberContent}>
                <Typography variant='h6'>KJSSCOSMOS</Typography>
                <form action="" className={adddocStyle.teammemberForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                   
                    <label className={adddocStyle.teammemberFormLabel}>Upload</label>
                    <div className={adddocStyle.ImgInput}>
                        <input
                            ref={inputRef}
                            className={adddocStyle.ImgInput_input}
                            type="file"
                            onChange={handlePdfChange}
                        />
                        {!previewUrl && <button type='button' onClick={handleChoose}>Browse</button>}
                        {previewUrl && (
                            <iframe src={previewUrl} width="100%" height="400px" title="PDF Preview" />
                        )}
                    </div>
                    <label className={adddocStyle.editDetailFormLabel}>Choose a PDF:</label>
                    <div className={adddocStyle.ImgInput_footer}>
                        {pdfSelect ?
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'start',marginTop:'2%' }}>
                                {fileName}
                                <DeleteForeverOutlinedIcon onClick={discardPdf} sx={{ cursor: 'pointer' ,fontSize:'30px'}} />
                            </div> :
                            ''}
                    </div>
                    <Button variant='contained' type='submit' className={adddocStyle.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default AddDoc