import React, { useEffect, useState } from 'react'
import edocStyle from './EditDoc.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

import { useParams } from 'react-router-dom';


const EditDoc = () => {
  const { id, id2, id3 } = useParams();

  // get data from redux
  const name = useSelector((state) => state.globalData.name);
  const count = useSelector((state) => state.globalData.notificationCount);
  const dispatch = useDispatch()

  const location = useLocation()
  const data = location.state
  console.log(data)

  const navigate = useNavigate()

  const inputRef = React.useRef();

  const [file, setFile] = useState(null)
  const [source, setSource] = React.useState();
  const [previewUrl, setPreviewUrl] = useState('');
  const [fileName, setFileName] = React.useState('');
  const [pdfSelect, setPdfSelect] = useState(false);

  //   const [graphName, setgraphName] = useState('')
  //   const [graphDesc, setgraphDesc] = useState('')

  // useEffect(() => {
  //   if (data) {
  //     setPreviewUrl(`${process.env.REACT_APP_GECEADMIN}/documents/updateDocument/${id}/${id2}/${data.docs.documentId}`);
  //     console.log(data);
  //   }
  // }, [data])


  const handlePdfChange = (event) => {
    const selectedFile = event.target.files[0]
    setFile(selectedFile)
    setPreviewUrl(URL.createObjectURL(selectedFile))
    setPdfSelect(true)
  }
  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const discardPdf = (event) => {

    setPreviewUrl('')
    setFile(null)
    inputRef.current.value = ""
    setPdfSelect(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formDataObj = new FormData()
    formDataObj.append("file", file)
    // formDataObj.append("graphName", graphName)


    try {
      const pdfResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/documents/updateDocument/${id}/${id2}/${data.docs.documentId}`,
        formDataObj,
        {
          headers: {
            "Content-Type": "application/pdf"
          }
        }
      )
      console.log(pdfResponse)
    } catch (error) {
      console.error(error)
    }
    alert('Document  Updated.')

    dispatch(fetchNotifications())

    dispatch(setNotificationCount({
      count: count + 1,
    }))
    discardPdf()
    navigate(`/doc/${id}/${id2}`)
  }
  // try {
  //   const businessResponse = await axios.post(
  //     `${process.env.REACT_APP_GECEADMIN}/graph/editGraph/${id}/${id2}/${id3}`,
  //     //   {graphName, graphDesc},
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //   // console.log('Business Updated.')
  //   // console.log(businessResponse)

  // } catch (error) {
  //   console.error(error)
  // }


  //   useEffect(() => {
  //     const fetchImage = async () => {
  //       try {
  //         const pdfResponse = await axios.get(
  //           `${process.env.REACT_APP_GECEADMIN}/graph/file/${data.images.newImage}`
  //         );
  //         console.log(pdfResponse);
  //         setSource(pdfResponse);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     };

  //     fetchImage();
  //   }, [data.images.newImage]);

  return (
    <div className={edocStyle.teammemberMain}>
      <div className={edocStyle.teammemberContent}>
        <form action="" className={edocStyle.teammemberForm} onSubmit={handleSubmit} encType='multipart/form-data'>
          {/* <label className={edocStyle.teammemberFormLabel}>Name</label>
          <input className={edocStyle.teammemberFormText} type="text" value={graphName} name='graphName' onChange={(event) => setgraphName(event.target.value)} />
           */}
          <label className={edocStyle.teammemberFormLabel}>Upload</label>
          <div className={edocStyle.ImgInput}>
            <input
              ref={inputRef}
              className={edocStyle.ImgInput_input}
              type="file"
              onChange={handlePdfChange}
            />
            {!previewUrl && <button type='button' onClick={handleChoose}>Browse</button>}
            {previewUrl && (
              <iframe src={previewUrl} width="100%" height="400px" title="PDF Preview" />
            )}

          </div>
          <div className={edocStyle.ImgInput_footer}>
            {pdfSelect ?
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                {fileName}
              </div> :
              ''}
          </div>
          <DeleteForeverOutlinedIcon onClick={discardPdf} sx={{ cursor: 'pointer' }} />
          <Button variant='contained' type='submit' className={edocStyle.saveChanges}>Save changes</Button>
        </form>
      </div>
    </div>
  )
}

export default EditDoc