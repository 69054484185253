import React, { useState } from 'react'
import EditGalleryStyles from './EditGallery.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

const EditGallery = () => {


  
    // get data from redux
    const name=useSelector((state)=>state.globalData.name);
    const count=useSelector((state)=>state.globalData.notificationCount);
    const dispatch =  useDispatch()


  const inputRef = React.useRef();

  const location = useLocation()
  const data = location.state
  console.log(data)

  const navigate = useNavigate()


  const [file, setFile] = useState(null)
  const [source, setSource] = React.useState();
  const [fileName, setFileName] = React.useState('');
  const [imageSelect, setImageSelect] = useState(false)


  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageResponse = await axios.get(
          `${process.env.REACT_APP_GECEADMIN}/gallery/file/${data.newImage}`
        );
        console.log(imageResponse);
        setSource(imageResponse);
      } catch (error) {
        console.error(error);
      }
    };

    fetchImage();
  }, [data.newImage]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name)
    const url = URL.createObjectURL(file);
    setSource(url);
    setImageSelect(true)
    setFile(file)
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const discardImage = (event) => {
    setSource(null)
    setFileName('')
    setFile(null)
    inputRef.current.value = ""
    setImageSelect(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formDataObj = new FormData()
    formDataObj.append("file", file)
    try {
      const imageResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/gallery/uploadImageById/${data.imageId}`,
        formDataObj,
        {
          headers: {
            "Content-Type": "image/jpeg"
          }
        }
      )
      console.log(imageResponse)
      console.log('Image Updated.')

      dispatch(fetchNotifications())
        // this is the snipte for the notification

        // const dateTime=imageResponse.data.date;

        // const [dateString,timeString]=dateTime.split("T");

        // const date=new Date(dateString);

        // const time=timeString.substr(0,8);


        // const localDate=date.toLocaleDateString();

        // dispatch(setNotifications({
        //     notification:{
        //       name:name,
        //       details:imageResponse.data.message,
        //       time:time,
        //       date:localDate
        //     }
        //   }))
          dispatch(setNotificationCount({
           count:count+1,
          }))
          
        //   uptill here 

        
      alert('Image Updated.')
      discardImage()
      navigate('/gallery')
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageResponse = await axios.get(
          `${process.env.REACT_APP_GECEADMIN}/gallery/file/${data.newImage}`
        );
        console.log(imageResponse);
        setSource(imageResponse);
      } catch (error) {
        console.error(error);
      }
    };

    fetchImage();
  }, [data.newImage]);


  return (
    <div className={EditGalleryStyles.editGalleryMain}>
      <div className={EditGalleryStyles.editGalleryContent}>
        <form action="" className={EditGalleryStyles.editGalleryForm} onSubmit={handleSubmit} encType='multipart/form-data'>
          <div className={EditGalleryStyles.ImgInput}>
            <input
              ref={inputRef}
              className={EditGalleryStyles.ImgInput_input}
              type="file"
              onChange={handleFileChange}
            />
            {!source && <button type='button' onClick={handleChoose}>Choose</button>}
            {source && (
              <img
                className={EditGalleryStyles.ImgInput_img}
                width="100%"
                src={`${process.env.REACT_APP_GECEADMIN}/gallery/file/${data.newImage}`}
                alt='uploadedimage'
              />
            )}
              </div>
            <div className={EditGalleryStyles.ImgInput_footer}>
              {imageSelect ?
                <div >
                  {fileName}

                </div> :
                ''}
                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer', }} />
            </div>
                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
          <Button variant='contained' type='submit' className={EditGalleryStyles.saveChanges}>Save changes</Button>
        </form>
      </div>
    </div>
  )
}

export default EditGallery