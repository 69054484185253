import React from 'react'
import busiStyle from './TheBusiness.module.css'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
import { useParams } from 'react-router-dom';


const TheBusiness = () => {
    const { id,id2 } = useParams();

     // get data from redux
     const name=useSelector((state)=>state.globalData.name);
     const count=useSelector((state)=>state.globalData.notificationCount);
     const dispatch =  useDispatch()

    const [data, setData] = useState([])

    const getinvestorData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/channel/getChannelById/${id2}`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteDetailbyId = async (businessId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/investorBusiness/deleteById/${id}/${id2}/${businessId}`
            )
            dispatch(fetchNotifications())
    
              dispatch(setNotificationCount({
               count:count+1,
              }))
              
            alert('Delete Successful!')
            getinvestorData()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getinvestorData()
    }, [])

    return (
        <div className={busiStyle.companyDetailsMain}>
            <div className={busiStyle.companyDetailsContent}>
                <div className={busiStyle.companyDetailsHeader}>
                    <Typography sx={{ marginTop: '1%', color: '#121926', fontWeight: '600', fontSize: '20px' }} >
                    Our Business
                    </Typography>
                    <Link to={`/addBusi/${id}/${id2}`}>
                    <Button sx={{ textTransform: 'capitalize' }} variant='contained'>Add</Button>
                    </Link>
                </div>
                <Box className={busiStyle.companyDetailsComponent}>
                    <div className={busiStyle.companyDetailsComponentLeft}>
                        <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Name
                        </Typography>
                    </div>
                    <div className={busiStyle.companyDetailsComponentRight}>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Last modified
                        </Typography>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Action
                        </Typography>
                    </div>
                </Box>
                {data.bussiness?.map((det) =>
                    <Box className={busiStyle.companyDetailsComponent}>
                        <div className={busiStyle.companyDetailsComponentLeft}>
                            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                                {det.businessHeading}
                            </Typography>
                        </div>
                        <div className={busiStyle.companyDetailsComponentRight}>
                            <Typography variant='h6' sx={{ fontSize: '15px' }}>
                                {det.date}
                            </Typography>
                            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600', display: 'flex', alignItems: 'baseline' }}>
                            <Link to={`/editBusi/${id}/${id2}/${det?.businessId}`} state={{graphInfo:det}} >
                            {/* <Link to={`/EditInvOppo/${det?.businessId}`} > */}
                                {/* <Link to= '/EditInvOppo'> */}
                                    <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer'}} />
                                </Link>
                                <IconButton disableRipple onClick={() => deleteDetailbyId(det.businessId)}>
                                <DeleteForeverOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer',marginLeft:'10px'  }} />
                                </IconButton>
                            </Typography>
                        </div>
                    </Box>
                ).reverse()}
            </div>
        </div>
    )
}

export default TheBusiness





