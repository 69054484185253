import { BrowserRouter, Route, Routes, Navigate, HashRouter } from "react-router-dom";
import ClippedDrawer from './components/Drawer/Drawer';
import './App.css';
import { AuthProvider } from './context/AuthProvider';
import { useSelector, useDispatch } from 'react-redux';
import LoginPage from './components/LoginPage/LoginPage';
import RequireAuth from './middleware/RequireAuth'

function App() {



  return (
    <>

      <HashRouter>
        <AuthProvider>
          <Routes>

          <Route element={<RequireAuth />}>

            <Route path='/*' element={<ClippedDrawer />} />
            </Route>
            <Route exact path='/login' element={<LoginPage />} />

          </Routes>
        </AuthProvider>
      </HashRouter>
    </>
  );
}

export default App;