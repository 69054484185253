import React, { useEffect } from 'react'
import jobApplicantStyles from "./JobApplicants.module.css"
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function createData(id, name, email, number, date, job) {
    return { id, name, email, number, date, job };
}

const rows = [
    createData(1, 'Joseph William 1', 'Joseph@mail.com', 901235456, "12.07.2018", "JS Developer"),
    createData(2, 'Ashy Handgun 2', 'Akshay@mail.com', 1403500365, "12.07.2018", "HR"),
    createData(3, 'Larry Doe 3', 'larry@mail.com', 6048323973, "12.07.2018", "React JS Developer"),
    createData(4, 'Sara Soudan 4', 'Sara@mail.com', 3272117434, "12.07.2018", "Sales Head"),
    createData(5, 'Larry Doe 3', 'larry@mail.com', 3760221303, "12.07.2018", "Jr React Developer"),
    createData(6, 'Joseph William 1', 'Joseph@mail.com', 925475400, "12.07.2018", "Node JS Developer"),
    createData(7, 'Sara Soudan 4', 'Sara@mail.com', 830192060, "12.07.2018", "Spring Boot Developer"),
    createData(8, 'Joseph William 1', 'Joseph@mail.com', 485237000, "12.07.2018", "HR"),
    createData(9, 'Ashy Handgun 2', 'Akshay@mail.com', 126577691, "12.07.2018", "React JS Developer"),
    createData(10, 'Larry Doe 3', 'larry@mail.com', 126317000, "12.07.2018", "Sales"),
    createData(11, 'Sara Soudan 4', 'Sara@mail.com', 678022000, "12.07.2018", "JS Developer"),
    createData(12, 'Joseph William 1', 'Joseph@mail.com', 672545757, "12.07.2018", "IOS Developer"),
    createData(13, 'Jack parrow', 'Akshay@mail.com', 146793744, "12.07.2018", "Data Scientist"),
    createData(14, 'Larry Doe 3', 'larry@mail.com', 2009624137, "12.07.2018", "Sales Head"),
    createData(15, 'Joseph William 5', 'larry@mail.com', 210147125, "12.07.2018", "HR"),
];




const JobApplicants = () => {

    // get data from redux
    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()


    const [data, setData] = useState([])

    const getApplicantData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/career/getAllCareerOpenings`
            )
            setData(res.data)
            console.error("helloss")
            console.log(res.data)
            // console.log(res.data[0].jobrole[0].resume[0].userAppForm.applicantName)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getApplicantData();

    }, [])




    const [jobRole, setJobRole] = React.useState("");

    const handleJobRoles = (event) => {
        setJobRole(event.target.value);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [query, setQuery] = useState("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteApplicantbyId = async (resumeId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/career/deleteById/${resumeId}`
            )
            console.log(res)
            dispatch(fetchNotifications())
            // this is the snipte for the notification

            //    const dateTime=res.data.date;

            //    const [dateString,timeString]=dateTime.split("T");

            //    const date=new Date(dateString);

            //    const time=timeString.substr(0,8);


            //    const localDate=date.toLocaleDateString();

            //    dispatch(setNotifications({
            //        notification:{
            //          name:name,
            //          details:res.data.message,
            //          time:time,
            //          date:localDate
            //        }
            //      }))
            dispatch(setNotificationCount({
                count: count + 1,
            }))

            //   uptill here 

            alert('Delete Successful!')
            // window.location.reload()
            getApplicantData()
        } catch (error) {
            console.log(error)
        }
    }

    const filteredData = data.flatMap((category) =>
        category.jobrole
            .filter((job) => {
                if (jobRole === 'All Roles') {
                    return true; // Include all job roles
                } else {
                    return job.careerJobRoleName.includes(jobRole); // Filter based on jobRole
                }
            })
            .flatMap((job) =>
                job.resume.filter((row) =>
                    row.userAppForm.applicantName.toLowerCase().includes(query)
                )
                    .map((row) => (
                        {
                            careerCategoryName: category.careerCategoryName,
                            careerJobRoleName: job.careerJobRoleName,
                            job: job,
                            ...row,
                        }
                    ))
            )
    );




    //   console.log(filteredData)


    return (
        <>
            <Card sx={{ maxWidth: '95%', border: 'none', outline: 'none', boxShadow: 'none', mt: '3%' }}>
                <CardContent>
                    <Paper className={jobApplicantStyles.myboder}>
                        <TableContainer sx={{ maxHeight: 650 }}>
                            <div className={jobApplicantStyles.jobApplicantListBox1}>
                                <Box className={jobApplicantStyles.Box1}>
                                    <Typography variant='h5'>Job Applications</Typography>
                                    <Typography variant='h6'>101 Applications</Typography>
                                </Box>
                            </div>
                            <div className={jobApplicantStyles.jobApplicantListBox2}>
                                <Box className={jobApplicantStyles.Box1}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon sx={{ zIndex: '1' }} />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            onChange={(e) => setQuery(e.target.value)}
                                            inputProps={{ 'aria-label': 'search' }}
                                            sx={{ width: '100%', color: '#121926', backgroundColor: '#F8FAFC', borderRadius: '10px', border: '1px solid #bdbdbd' }}
                                        />
                                    </Search>
                                </Box>
                                <Box className={jobApplicantStyles.Box2}>
                                    Filter by
                                    <FormControl sx={{ m: 1, width: '200px' }}>
                                        <Select
                                            value={jobRole}
                                            // filter={job.careerJobRoleName}
                                            onChange={handleJobRoles}
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                            style={{ height: '5vh' }}
                                        >

                                            <MenuItem value="">All Roles</MenuItem>
                                            <MenuItem value='Javascript'>Javascript</MenuItem>
                                            <MenuItem value='Technical'>Technical</MenuItem>
                                            <MenuItem value='React JS Developer'>React JS Developer</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className={jobApplicantStyles.Mainhead}>
                                        <TableCell className={jobApplicantStyles.Tablehead}>Name</TableCell>
                                        <TableCell className={jobApplicantStyles.Tablehead}>Department</TableCell>
                                        <TableCell className={jobApplicantStyles.Tablehead}>Applied On</TableCell>
                                        <TableCell className={jobApplicantStyles.Tablehead}>Applied For</TableCell>
                                        <TableCell className={jobApplicantStyles.Tablehead}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {

                                        filteredData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                return (
                                                    <TableRow className={jobApplicantStyles.MainRow} hover role="checkbox" tabIndex={-1} key={row.userAppForm.applicationId} >
                                                        <TableCell className={jobApplicantStyles.Tablerow1}> {row.userAppForm.applicantName}<Box className={jobApplicantStyles.Email}>{row.userAppForm.applicantEmail}</Box></TableCell>
                                                        <TableCell className={jobApplicantStyles.Tablerow}>
                                                            {row.careerCategoryName}
                                                        </TableCell>
                                                        <TableCell className={jobApplicantStyles.Tablerow}>{row.userAppForm.date} </TableCell>
                                                        <TableCell className={jobApplicantStyles.Tablerow}>
                                                            {row.careerJobRoleName}
                                                        </TableCell>
                                                        <TableCell className={jobApplicantStyles.Tablerow2}>
                                                            <Link
                                                                to={`/applicants/${row?.resumeId}`}
                                                                state={{ row: row }}
                                                            >


                                                                <VisibilityOutlinedIcon className={jobApplicantStyles.Tableicon} />
                                                            </Link>
                                                            <Link disableRipple onClick={() => deleteApplicantbyId(row.resumeId)}>
                                                                <DeleteForeverOutlinedIcon style={{ marginLeft: '10%' }} className={jobApplicantStyles.Tableicon} />
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }).reverse()

                                    }


                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ paddingTop: '2%' }}

                        />
                    </Paper>
                </CardContent>
            </Card>
        </>
    )
}

export default JobApplicants