import React, { useState } from 'react'
import EditDetailStyles from './AddCompanyDetail.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import axios from 'axios';
// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'

import { useLocation, useNavigate } from 'react-router-dom';


const AddCompanyDetail = () => {
    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

       // get data from redux
       const name=useSelector((state)=>state.globalData.name);
       const count=useSelector((state)=>state.globalData.notificationCount);
       const dispatch =  useDispatch()


    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [companyData, setCompanyData] = useState({
        companyDetailsHeading: '',
    })
    const [source, setSource] = React.useState();
    const [previewUrl, setPreviewUrl] = useState('');
    const [fileName, setFileName] = React.useState('');
    const [pdfSelect, setPdfSelect] = useState(false)

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setCompanyData({ ...companyData, [name]: value })
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setPdfSelect(true)
        setFile(file)
    };

    const handlePdfChange = (event) => {
        const selectedFile = event.target.files[0]
        setFile(selectedFile)
        setPreviewUrl(URL.createObjectURL(selectedFile))
        setPdfSelect(true)
    }

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardPdf = (event) => {
        // setSource(null)
        // setFileName('')
        setPreviewUrl('')
        setFile(null)
        inputRef.current.value = ""
        setPdfSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        formDataObj.append("companyDetailsHeading", companyData.companyDetailsHeading)

        try {
            const pdfResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/companydetails/uploadPdf`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "application/pdf"
                    }
                }
            )
            console.log(pdfResponse)
            alert('Detail Added.')
                        navigate('/companydetails')


            const companyResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/companydetails/addCompanyDetails/${pdfResponse.data.pdfId}`,
                companyData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )

             // this is the snipte for the notification

       const dateTime=companyResponse.data.date;

       const [dateString,timeString]=dateTime.split("T");

       const date=new Date(dateString);

       const time=timeString.substr(0,8);


       const localDate=date.toLocaleDateString();

       dispatch(setNotifications({
           notification:{
             name:name,
             details:companyResponse.data.message,
             time:time,
             date:localDate
           }
         }))
         dispatch(setNotificationCount({
          count:count+1,
         }))
         
       //   uptill here 
            console.log('Detail Added.')
            console.log(companyResponse)
            alert('Detail Added.')
            discardPdf()
            navigate('/companydetails')

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={EditDetailStyles.editDetailMain}>
            <div className={EditDetailStyles.editDetailContent}>
                <form action="" className={EditDetailStyles.editDetailForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={EditDetailStyles.editDetailFormLabel}>Title</label>
                    <input className={EditDetailStyles.editDetailFormText} type="text" value={companyData.companyDetailsHeading} name='companyDetailsHeading' onChange={handleInputChange} />
                    <label className={EditDetailStyles.editDetailFormLabel}>Upload</label>
                    <div className={EditDetailStyles.ImgInput}>
                        <input
                            ref={inputRef}
                            className={EditDetailStyles.ImgInput_input}
                            type="file"
                            onChange={handlePdfChange}
                        />
                        {!previewUrl && <button type='button' onClick={handleChoose}>Browse</button>}
                        {previewUrl && (
                            // <img
                            //     className={EditDetailStyles.ImgInput_img}
                            //     width="100%"
                            //     src={source}
                            //     alt='uploadedimage'
                            // />
                            <iframe src={previewUrl} width="100%" height="500px" title="PDF Preview" />
                            )}
                            </div>
                            <label className={EditDetailStyles.editDetailFormLabel}>Choose a PDF:</label>
                        <div className={EditDetailStyles.ImgInput_footer}>
                            {pdfSelect ?
                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                    {fileName}
                                    <DeleteForeverOutlinedIcon onClick={discardPdf} sx={{ cursor: 'pointer' }} />
                                </div> :
                                ''}
                        </div>
                    <Button variant='contained' type='submit' className={EditDetailStyles.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default AddCompanyDetail