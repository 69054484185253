import React, { useEffect, useState } from 'react'
import EditEventStyles from './EditEvent.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';


  // import this for redux read write
  import { useSelector, useDispatch } from 'react-redux'
  import { setNotifications } from '../../state/GlobalState'
  import { setNotificationCount } from '../../state/GlobalState'
  import { fetchNotifications } from '../../state/GlobalState'
  
const EditEvent = () => {

        // get data from redux
        const name=useSelector((state)=>state.globalData.name);
        const count=useSelector((state)=>state.globalData.notificationCount);
        const dispatch =  useDispatch()


    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const [eventName, setEventName] = useState('')
    const [eventDesc, setEventDesc] = useState('')
    const [eventDate, setEventDate] = useState('')
    const [eventLink, setEventLink] = useState('')

    useEffect(() => {
        if (data) {
            setEventName(data.eventName)
            setEventDesc(data.eventDesc)
            setEventDate(data.eventDate)
            setEventLink(data.eventLink)
        }
    }, [data])

    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const eventResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/upcomingevents/updateById/${data.eventId}`,
                {eventName, eventDesc, eventDate, eventLink},
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log('Event Updated.')
            console.log(eventResponse)
            dispatch(fetchNotifications())
             // this is the snipte for the notification

    //    const dateTime=eventResponse.data.date;

    //    const [dateString,timeString]=dateTime.split("T");

    //    const date=new Date(dateString);

    //    const time=timeString.substr(0,8);


    //    const localDate=date.toLocaleDateString();

    //    dispatch(setNotifications({
    //        notification:{
    //          name:name,
    //          details:eventResponse.data.message,
    //          time:time,
    //          date:localDate
    //        }
    //      }))
         dispatch(setNotificationCount({
          count:count+1,
         }))
         
       //   uptill here 

            // alert('Event Updated.')
            navigate('/upcomingevents')
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={EditEventStyles.editEventMain}>
            <div className={EditEventStyles.editEventContent}>
                <form action="" className={EditEventStyles.editEventForm} onSubmit={handleSubmit}>
                    <label className={EditEventStyles.editEventFormLabel}>Title</label>
                    <input className={EditEventStyles.editEventFormText} type="text" value={eventName} name='eventName' onChange={(event) => setEventName(event.target.value)}/>
                    <label className={EditEventStyles.editEventFormLabel}>Description</label>
                    <textarea className={EditEventStyles.editEventFormDesc} value={eventDesc} name='eventDesc' onChange={(event) => setEventDesc(event.target.value)}/>
                    <label className={EditEventStyles.editEventFormLabel}>Date</label>
                    <input className={EditEventStyles.editEventFormText} type="text" value={eventDate} name='eventDate' onChange={(event) => setEventDate(event.target.value)}/>
                    <label className={EditEventStyles.editEventFormLabel}>Link</label>
                    <input className={EditEventStyles.editEventFormText} type="text" value={eventLink} name='eventLink' onChange={(event) => setEventLink(event.target.value)}/>
                    <Button variant='contained' type='submit' className={EditEventStyles.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default EditEvent