import React from 'react'
import OurBusinessStyles from './Project.module.css'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
import useBackdrop from '../../hooks/useBackdrop/useBackdrop';
const Project = () => {
    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const dispatch = useDispatch()

    const business = [1, 2, 3]

    const [data, setData] = useState([])

    const getBusinessData = async () => {
        openBackdrop();
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/project/getAllProjectDetails`
            )
            setData(res.data)
            console.log(res.data)
            closeBackdrop();
        } catch (error) {
            console.log(error)
            closeBackdrop();
        }
    }

    const deleteBusinessbyId = async (projectId) => {
        openBackdrop();
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/project/deleteProject/${projectId}`
            )
            // this is the snipte for the notification
            dispatch(fetchNotifications())

            dispatch(setNotificationCount({
                count: count + 1,
            }))
            alert('Delete Successful!')
            // window.location.reload()
            getBusinessData()
            closeBackdrop();
        } catch (error) {
            closeBackdrop();
            console.log(error)
        }
    }

    useEffect(() => {
        getBusinessData()
    }, [])


    return (
        <>
            <BackdropComponent />
            <div className={OurBusinessStyles.ourbusinessMain}>
                <div className={OurBusinessStyles.ourbusinessContent}>
                    <div className={OurBusinessStyles.ourbusinessHeader}>
                        <Typography sx={{ color: '#121926', fontWeight: '600 !important', fontSize: '20px' }} >
                            Project Page
                        </Typography>
                        <Link to='/addproject'>
                            <Button sx={{ textTransform: 'capitalize' }} variant='contained'>Add Project</Button>
                        </Link>
                    </div>
                    <div className={OurBusinessStyles.ourbusinessComponent}>
                        <table className={OurBusinessStyles.projectTable}>
                            <thead>
                                <tr className={OurBusinessStyles.tableRow}>
                                <th>
                                    Name
                                </th>
                                <th>
                                Category
                                </th>
                                <th>
                                    Last Modified
                                </th>
                                <th>
                                    Action
                                </th>
                                </tr>
                              
                            </thead>
                            <tbody>
                                {data.map((business) => {
                                    return (
                                        <tr className={OurBusinessStyles.tableRow}>
                                            <td>
                                                {business.projHeading}
                                            </td>
                                            <td>
                                                {business.projCategory}
                                            </td>
                                            <td>
                                                {business.date}
                                            </td>
                                            <td>
                                                <div className={OurBusinessStyles.projectIcons}>

                                                    <Link to='/editproject' state={business} style={{ marginRight: '10%' }} >
                                                        <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                                                    </Link>
                                                    <span onClick={() => deleteBusinessbyId(business.projectId)}>
                                                        <DeleteForeverOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />

                                                    </span >



                                                </div>
                                            </td>
                                        </tr>
                                    )

                                }
                                ).reverse()
                                }

                            </tbody>
                        </table>

                    </div>
                </div >
            </div>
        </>
    )
}

export default Project