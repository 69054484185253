
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

const initialState={
    notificationCount:0,
    user:null,
    token:null,
    name:null,
    notifications:[]
  
}

const separateDate=(gdate)=>{
    const [dateString,timeString]=gdate.split("T");
  
    // console.log(gdate.split("T"))
    const date=dateString;
  
    const time=timeString.substr(0,8);
  
   
    return [date,time]
  }

export const fetchNotifications = createAsyncThunk('state/fetchNotifications', async (_, { getState }) => {
   
  const {name}=getState().globalData;
    // console.log(datas )
    const res=await axios.get(`${process.env.REACT_APP_GECEADMIN}/notification/getAllNotification`,
    {
      headers: {
          "Accept": "*/*"
      }
  })
    const data=res.data.map((notifi)=>{
        return (
          {
            date:separateDate(notifi.date)[0],
            name:name,
            time:separateDate(notifi.date)[1],
            details:notifi.notifcationMessage,
            id:notifi.id,
          }
        )
      })

      // console.log(data)
      // console.log("heelo")
    return data;
  });
// const initialState={
   
//     roles:null,
// }




export const globalSlice=createSlice(
    {
        name:"data",
        initialState,
        reducers:{
            setNotificationCount:(state,action)=>{
                state.notificationCount=action.payload.count;
            },
            setLogin:(state,action)=>{
                state.token=action.payload.token;
                state.user=action.payload.user;
                state.name=action.payload.name;
                // state.roles=action.payload.roles;
            },
            setLogout:(state)=>{
                state.token=null;
                state.user=null;
                state.name=null;
                // state.roles=null;
               state.notifications=[]
            },
            setAddress:(state,action)=>{
                state.token=action.payload.details;
                state.Time=action.payload.Time
                // state.roles=action.payload.roles;
            },
            setNotifications:(state,action)=>{
                state.notifications=action.payload.notifications;
            },
            getNotifications:(state)=>{

            }
    
        },
        extraReducers: (builder) => {
            builder.addCase(fetchNotifications.fulfilled, (state, action) => {
              state.notifications = action.payload;
              // Update other relevant state properties here
            });
            builder.addCase(fetchNotifications.rejected, (state, action) => {
              console.log('Error:', action.error);
              // Handle rejected case if needed
            });
            builder.addCase(fetchNotifications.pending, (state) => {
              // console.log('pending');
            });
          }

    }
    
)


export const{setNotificationCount,setLogin,setLogout,setAddress,setNotifications}=globalSlice.actions;
// export const {setLogin,setLogout}=Global.actions;



export default globalSlice.reducer;