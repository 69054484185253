import React from 'react'
import Typography from '@mui/material/Typography';
import ItStyles from './ITService.module.css';
import { Box, Button } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { Link } from 'react-router-dom';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';


export const ITService = () => {
  return (
    <div className={ItStyles.investorMain}>
    <div className={ItStyles.investorContent}>
        <Typography sx={{ marginTop: '2%', color: '#121926', fontWeight: '600', fontSize: '20px',paddingBottom:'3%' }} >
            IT Service
        </Typography>
        <Box className={ItStyles.investorComponent}>
            <div className={ItStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Title
                </Typography>
            </div>
            <div className={ItStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Last modified
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    Action
                </Typography>
            </div>
        </Box>
        <Box className={ItStyles.investorComponent}>
            <div className={ItStyles.investorComponentLeft}>
                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                   IT Services 
                </Typography>
            </div>
            <div className={ItStyles.investorComponentRight}>
                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                    12.07.2018
                </Typography>
                <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                    <Link to='/ithighlights'>
                        <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
                    </Link>
                </Typography>
            </div>
        </Box>
     
   
    </div>
</div>
  )
}

export default ITService