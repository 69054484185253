import React from 'react'
import ApplicantStyles from './Applicant.module.css'
import Typography from '@mui/material/Typography';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
//  import { useLocation, useNavigate } from 'react-router';
 import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const Applicant = () => {

    const location = useLocation();
    console.log(location);
    console.log(location.state);
    const  {row}  = location.state || {}

    const job=row.job;

    console.log(row)
    console.log(job)

    const navigate = useNavigate()

    const handleResumeLink = async (row) => {
        if (row && row.resumeId) {
            const resumeId = row.resumeId;
            const apiUrl = `${process.env.REACT_APP_GECEADMIN}/career/downloadResume/${resumeId}`;

            axios.get(apiUrl, {
                responseType: 'blob' // set the response type to blob
            })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = '_blank';
                    link.click();
                })
                .catch(error => {
                    console.error('Error downloading PDF file', error);
                });
        }

    }

    return (
        <div className={ApplicantStyles.applicantMain}>
            <div className={ApplicantStyles.applicantContent}>
                <div className={ApplicantStyles.applicantHeading}>
                    <div className={ApplicantStyles.applicantIntro}>
                        <Typography className={ApplicantStyles.applicantName}>{row.userAppForm.applicantName}</Typography>
                        <Typography className={ApplicantStyles.applicantRole}>Applied for: {job.careerJobRoleName}</Typography>
                    </div>
                    <Link to='/jobapplicants' className={ApplicantStyles.goBack}>
                        Go Back
                    </Link>
                </div>
                <form action="" className={ApplicantStyles.applicantForm}>
                    <label className={ApplicantStyles.applicantFormLabel}>Email ID</label>
                    <input className={ApplicantStyles.applicantFormText} type="text" value={row.userAppForm.applicantEmail} disabled />
                    <label className={ApplicantStyles.applicantFormLabel}>Contact Number</label>
                    <input className={ApplicantStyles.applicantFormText} type="text" value={row.userAppForm.applicantContact} disabled />
                    <label className={ApplicantStyles.applicantFormLabel}>Current CTC</label>
                    <input className={ApplicantStyles.applicantFormText} type="text" value={row.userAppForm.applicantCurrentCtc} disabled />
                    <label className={ApplicantStyles.applicantFormLabel}>Expected CTC</label>
                    <input className={ApplicantStyles.applicantFormText} type="text" value={row.userAppForm.applicantExpectedCtc} disabled />
                    <label className={ApplicantStyles.applicantFormLabel}>Skills</label>
                    <input className={ApplicantStyles.applicantFormText} type="text" value={row.userAppForm.applicantSkills} disabled />
                    <label className={ApplicantStyles.applicantFormLabel}>Experience</label>
                    <input className={ApplicantStyles.applicantFormText} type="text" value={row.userAppForm.applicantTotalExperience} disabled />
                    <label className={ApplicantStyles.applicantFormLabel}>Resume</label>
                    <a onClick={() => handleResumeLink(row)} target='_blank' className={ApplicantStyles.applicantResume}>{row.resumeName}</a>
                    <label className={ApplicantStyles.applicantFormLabel}>Cover Letter</label>
                    <textarea className={ApplicantStyles.applicantFormDesc} value={row.userAppForm.applicantCoverLetter} disabled />
                </form>
            </div>
        </div>
    )
}

export default Applicant