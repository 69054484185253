import React, { useEffect } from 'react'
import JobRoleStyles from "./JobRoles.module.css"
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router';


  // import this for redux read write
  import { useSelector, useDispatch } from 'react-redux'
  import { setNotifications } from '../../state/GlobalState'
  import { setNotificationCount } from '../../state/GlobalState'
  import { fetchNotifications } from '../../state/GlobalState'


  
 


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const style = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '616px',
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '8px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
const JobRoles = () => {

function ChildModal() {

    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [careerCategoryName, setCareerCategoryName] = useState('')
    const [postSuccess, setPostSuccess] = useState(false)

    const handlePostDepartment = async (event) => {
        event.preventDefault()
        try {
            const deptResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/career/addCategory`,
                {careerCategoryName},
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            // console.log('Department Added.')
            console.log(deptResponse)


            dispatch(fetchNotifications())

            alert('Department Added.')
            navigate('/jobroles')


             // this is the snipte for the notification

    //    const dateTime=deptResponse.data.date;

    //    const [dateString,timeString]=dateTime.split("T");

    //    const date=new Date(dateString);

    //    const time=timeString.substr(0,8);


    //    const localDate=date.toLocaleDateString();

    //    dispatch(setNotifications({
    //        notification:{
    //          name:name,
    //          details:deptResponse.data.message,
    //          time:time,
    //          date:localDate
    //        }
    //      }))
         dispatch(setNotificationCount({
          count:count+1,
         }))
         
       //   uptill here 


            // alert('Department Added.')
            navigate('/jobroles')
           getRolesData()
            setPostSuccess(true)
        } catch (error) {
            console.error(error)
        }
        
          
        // if(setPostSuccess){
        //     window.location.reload()
        // }
    }

    return (
        <React.Fragment>
            <Button className={JobRoleStyles.childModalButton} onClick={handleOpen}>Add</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: '364px' }}>
                    <div className={JobRoleStyles.childModal}>
                        <Typography variant='h1'>Add Department</Typography>
                        <form action="" className={JobRoleStyles.departmentForm} onSubmit={handlePostDepartment}>
                        <label className={JobRoleStyles.departmentFormLabel}>Name</label>
                        <input className={JobRoleStyles.departmentFormText} type="text" value={careerCategoryName} name='careerCategoryName' onChange={(event) => setCareerCategoryName(event.target.value)}/>
                        <Button className={JobRoleStyles.departmentFormBtn} type='submit' variant='contained'>Add</Button>
                        </form>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
}

function createData(id, jobRole, department, date) {
    return { id, jobRole, department, date };
}

const rows = [
    createData(1, 'Graphic Designer', 'Design', "12.07.2018"),
    createData(2, 'Javascript Developer', 'Engineering', "12.07.2018"),
    createData(3, 'IOS Developer', 'Engineering', "12.07.2018"),
    createData(4, 'Software Tester', 'Support', "12.07.2018"),
    createData(5, 'Ad', 'Digital Marketing', "12.07.2018"),
    createData(6, 'HR', 'Support', "12.07.2018"),
    createData(7, 'Sales', 'Digital Marketing', "12.07.2018"),
    createData(8, 'Marketing', 'Digital Marketing', "12.07.2018"),
    createData(9, 'ReactJS Developer', 'Engineering', "12.07.2018"),
    createData(10, 'NodeJS Developer', 'Engineering', "12.07.2018"),
    createData(11, 'Spring Boot Developer', 'Engineering', "12.07.2018"),
    createData(12, 'Javascript Developer', 'Engineering', "12.07.2018"),
    createData(13, 'HR', 'Support', "12.07.2018"),
    createData(14, 'Sales', 'Digital Marketing', "12.07.2018"),
    createData(15, 'Graphic Designer', 'Design', "12.07.2018"),
];



       // get data from redux
       const name=useSelector((state)=>state.globalData.name);
       const count=useSelector((state)=>state.globalData.notificationCount);
       const dispatch =  useDispatch()


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [query, setQuery] = useState("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [data, setData] = useState([])

    const getRolesData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/career/getAllCareerOpenings`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }

       

    useEffect(() => {
        getRolesData()
    }, [])

    const deleteJobbyId = async (careerJobRoleId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/career/deleteJobRoleById/${careerJobRoleId}`
            )
            console.log(res);

            dispatch(fetchNotifications())

             // this is the snipte for the notification

        // const dateTime=res.data.date;

        // const [dateString,timeString]=dateTime.split("T");
 
        // const date=new Date(dateString);
 
        // const time=timeString.substr(0,8);
 
 
        // const localDate=date.toLocaleDateString();
 
        // dispatch(setNotifications({
        //     notification:{
        //       name:name,
        //       details:res.data.message,
        //       time:time,
        //       date:localDate
        //     }
        //   }))
          dispatch(setNotificationCount({
           count:count+1,
          }))
          
        //   uptill here 
        
            alert('Delete Successful!???')
            // window.location.reload()
            // navigate('/jobroles')

            getRolesData()
        } catch (error) {
            console.log(error)
        }
    }

    const deleteCategorybyId = async (careerCategoryId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_GECEADMIN}/career/deleteCategory/${careerCategoryId}`
            )
            console.log(res);
            dispatch(fetchNotifications())

             // this is the snipte for the notification

        // const dateTime=res.data.date;

        // const [dateString,timeString]=dateTime.split("T");
 
        // const date=new Date(dateString);
 
        // const time=timeString.substr(0,8);
 
 
        // const localDate=date.toLocaleDateString();
 
        // dispatch(setNotifications({
        //     notification:{
        //       name:name,
        //       details:res.data.message,
        //       time:time,
        //       date:localDate
        //     }
        //   }))
          dispatch(setNotificationCount({
           count:count+1,
          }))
          
        //   uptill here 
        
            alert('Delete Successful!///')
            // window.location.reload()
            getRolesData()
        } catch (error) {
            console.log(error)
        }
    }

    const department = [1, 2, 3, 4, 5]

    return (
        <>
            <Card sx={{ maxWidth:'95%', border: 'none', outline:'none',boxShadow:'none', mt:'3%' }}>
                <CardContent>
                    <Paper className={JobRoleStyles.myboder}>
                        <TableContainer sx={{ maxHeight: 650 }}>
                            <div className={JobRoleStyles.jobRolesBox1}>
                                <Box className={JobRoleStyles.Box1}>
                                    <Typography variant='h5'>Job Openings</Typography>
                                    <Typography variant='h6'>101 Openings</Typography>
                                </Box>
                                <Box className={JobRoleStyles.Box1}>
                                    <Box className={JobRoleStyles.addUserBtn}>
                                        <Button className={JobRoleStyles.addUserbtn} variant="contained" onClick={handleOpen}>Post a job</Button>
                                    </Box>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="parent-modal-title"
                                        aria-describedby="parent-modal-description"
                                    >
                                        <Box sx={{ ...style, width: '616px' }}>
                                            <div className={JobRoleStyles.modalClose}>
                                                <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
                                            </div>
                                            <div className={JobRoleStyles.modalHeader}>
                                                <Typography variant='h1'>Select a department</Typography>
                                                <ChildModal />
                                            </div>
                                            <div className={JobRoleStyles.modalDepartmentBox}>
                                                {data.map((department) => (
                                                    <div className={JobRoleStyles.modalDepartment}>
                                                        <Typography>{department.careerCategoryName}</Typography>
                                                        <div className={JobRoleStyles.modalButtons}>
                                                            <Link to='/postjob' state={department}>
                                                            <Button sx={{textTransform: 'capitalize'}} variant='contained'>Select</Button>
                                                            </Link>
                                                            <DeleteForeverOutlinedIcon sx={{cursor: 'pointer'}} onClick={() => deleteCategorybyId(department.careerCategoryId)} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Box>
                                    </Modal>
                                </Box>
                            </div>
                            <div className={JobRoleStyles.jobRolesBox2}>
                                <Box className={JobRoleStyles.Box1}>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon sx={{ zIndex: '1' }} />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            onChange={(e) => setQuery(e.target.value)}
                                            inputProps={{ 'aria-label': 'search' }}
                                            sx={{ width: '100%', color: '##121926', backgroundColor: '#F8FAFC', borderRadius: '10px', border: '1px solid #bdbdbd' }}
                                        />
                                    </Search>
                                </Box>
                            </div>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className={JobRoleStyles.Mainhead}>
                                        <TableCell className={JobRoleStyles.Tablehead}>Job Role</TableCell>
                                        <TableCell className={JobRoleStyles.Tablehead}>Department</TableCell>
                                        <TableCell className={JobRoleStyles.Tablehead}>Posted On</TableCell>
                                        <TableCell className={JobRoleStyles.Tablehead}>Action</TableCell>
                                        <TableCell className={JobRoleStyles.Tablehead}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((category) => {
                                        return (
                                            category.jobrole.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .filter(row => row.careerJobRoleName.toLowerCase().includes(query)).map((row) => {
                                                    return (
                                                        <TableRow className={JobRoleStyles.MainRow} hover role="checkbox" tabIndex={-1} key={row.careerJobRoleId} >
                                                            <TableCell className={JobRoleStyles.Tablerow1}> {row.careerJobRoleName}</TableCell>
                                                            <TableCell className={JobRoleStyles.Tablerow}>{category.careerCategoryName}</TableCell>
                                                            <TableCell className={JobRoleStyles.Tablerow}>{row.careerJobRoleDate}</TableCell>
                                                            <TableCell className={JobRoleStyles.Tablerow}>
                                                                <Link to={`/editjob/${row.careerJobRoleId}`} state={row}>
                                                                    <BorderColorOutlinedIcon className={JobRoleStyles.Tableicon} onClick={handleOpen} />
                                                                </Link>
                                                                <DeleteForeverOutlinedIcon className={JobRoleStyles.Tableicon} style={{marginLeft:'10%'}} onClick={() => deleteJobbyId(row.careerJobRoleId)} />
                                                            </TableCell>
                                                            <TableCell className={JobRoleStyles.Tablerow}><Link to={`/viewapplicants/${row?.careerJobRoleId}`} state={{ row: row, category: category }}><Button variant="contained" style={{ textTransform: 'capitalize' }}>View applicants</Button></Link></TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </CardContent>
            </Card>
        </>
    )
}

export default JobRoles