import React, { useEffect, useState } from 'react'
import EditAddressStyles from './EditAddress.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';


  // import this for redux read write
  import { useSelector, useDispatch } from 'react-redux'
  import { setNotifications } from '../../state/GlobalState'
  import { setNotificationCount } from '../../state/GlobalState'
  import { fetchNotifications } from '../../state/GlobalState'

const EditAddress = () => {

    
        // get data from redux
        const name=useSelector((state)=>state.globalData.name);
        const count=useSelector((state)=>state.globalData.notificationCount);
        const dispatch =  useDispatch()



    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const [branchAddressName, setBranchAddressName] = useState('')
    const [branchAddressDesc, setBranchAddressDesc] = useState('')
    const [contactNo, setContactNo] = useState('')

    useEffect(() => {
        if (data) {
            setBranchAddressName(data.branchAddressName)
            setBranchAddressDesc(data.branchAddressDesc)
            setContactNo(data.contactNo)
        }
    }, [data])

    const handleSubmit = async (event) => {
        event.preventDefault()
        try {
            const addressResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/address/updateAddressById/${data.addressId}`,
                { branchAddressName, branchAddressDesc, contactNo },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log('Address Updated.')
            console.log(addressResponse)

            
        //     // this is the snipte for the notification
        //     console.log("heello")
        //     dispatch(fetchNotifications())
 
        //      const dateTime=addressResponse.data.date;
 
        // const [dateString,timeString]=dateTime.split("T");
 
        // const date=new Date(dateString);
 
        // const time=timeString.substr(0,8);
 
 
        // const localDate=date.toLocaleDateString();
 
        // dispatch(setNotifications({
        //     notification:{
        //       name:name,
        //       details:addressResponse.data.message,
        //       time:time,
        //       date:localDate
        //     }
        //   }))
          dispatch(setNotificationCount({
           count:count+1,
          }))
          
        //   uptill here 
            alert('Address Updated.')
            navigate('/branchaddress')
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={EditAddressStyles.editAddressMain}>
            <div className={EditAddressStyles.editAddressContent}>
                <form action="" className={EditAddressStyles.editAddressForm} onSubmit={handleSubmit}>
                    <label className={EditAddressStyles.editAddressFormLabel}>Location</label>
                    <input className={EditAddressStyles.editAddressFormText} type="text" value={branchAddressName} name='branchAddressName' onChange={(event) => setBranchAddressName(event.target.value)}/>
                    <label className={EditAddressStyles.editAddressFormLabel}>Description</label>
                    <textarea className={EditAddressStyles.editAddressFormDesc} value={branchAddressDesc} name='branchAddressDesc' onChange={(event) => setBranchAddressDesc(event.target.value)}/>
                    <label className={EditAddressStyles.editAddressFormLabel}>Phone No.</label>
                    <input className={EditAddressStyles.editAddressFormText} type="text" value={contactNo} name='contactNo' onChange={(event) => setContactNo(event.target.value)}/>
                    <Button variant='contained' type='submit' className={EditAddressStyles.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default EditAddress