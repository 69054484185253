import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import HomepageStyles from './Homepage.module.css';
import { Box, Button } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Homepage = () => {

  return (
    <div className={HomepageStyles.homepageMain}>
      <div className={HomepageStyles.homepageContent}>
        <Typography sx={{ marginTop: '2%', color: '#121926', fontWeight: '600', fontSize: '20px' ,paddingBottom:'3%'}} >
          Homepage
        </Typography>
        <Box className={HomepageStyles.homepageComponent}>
          <div className={HomepageStyles.homepageComponentLeft}>
            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
              Title
            </Typography>
          </div>
          <div className={HomepageStyles.homepageComponentRight}>
            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
              Last modified
            </Typography>
            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
              Action
            </Typography>
          </div>
        </Box>
        <Box className={HomepageStyles.homepageComponent}>
          <div className={HomepageStyles.homepageComponentLeft}>
            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
              Hero Banner
            </Typography>
          </div>
          <div className={HomepageStyles.homepageComponentRight}>
            <Typography variant='h6' sx={{ fontSize: '15px' }}>
              12.07.2018
            </Typography>
            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
              <Link to='/homebanner'>
                <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
              </Link>
            </Typography>
          </div>
        </Box>
        <Box className={HomepageStyles.homepageComponent}>
          <div className={HomepageStyles.homepageComponentLeft}>
            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
              Our Business
            </Typography>
          </div>
          <div className={HomepageStyles.homepageComponentRight}>
            <Typography variant='h6' sx={{ fontSize: '15px' }}>
              12.07.2018
            </Typography>
            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
              <Link to='/ourbusiness'>
              <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
              </Link>
            </Typography>
          </div>
        </Box>
        <Box className={HomepageStyles.homepageComponent}>
          <div className={HomepageStyles.homepageComponentLeft}>
            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
              Meet our Team
            </Typography>
          </div>
          <div className={HomepageStyles.homepageComponentRight}>
            <Typography variant='h6' sx={{ fontSize: '15px' }}>
              12.07.2018
            </Typography>
            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
              <Link to='/meetourteam'>
              <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
              </Link>
            </Typography>
          </div>
        </Box>
        <Box className={HomepageStyles.homepageComponent}>
          <div className={HomepageStyles.homepageComponentLeft}>
            <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
            Testimonial
            </Typography>
          </div>
          <div className={HomepageStyles.homepageComponentRight}>
            <Typography variant='h6' sx={{ fontSize: '15px' }}>
              12.07.2018
            </Typography>
            <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
              <Link to='/testimonial'> 
              {/* redirect to the  testimonial main page  */}
              <BorderColorOutlinedIcon sx={{ color: '#16469d', cursor: 'pointer' }} />
              </Link>
            </Typography>
          </div>
        </Box>
      </div>
    </div>
  )
}

export default Homepage