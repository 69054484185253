import React, { useState } from 'react'
import EditEventStyles from './AddUpcomingEvent.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';


  // import this for redux read write
  import { useSelector, useDispatch } from 'react-redux'
  import { setNotifications } from '../../state/GlobalState'
  import { setNotificationCount } from '../../state/GlobalState'
  import { fetchNotifications } from '../../state/GlobalState'

const AddUpcomingEvent = () => {
    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()


     // get data from redux
     const name=useSelector((state)=>state.globalData.name);
     const count=useSelector((state)=>state.globalData.notificationCount);
     const dispatch =  useDispatch()



    const [eventData, setEventData] = useState({
        eventName: '',
        eventDesc: '',
        eventDate: '',
        eventLink: '',
    })

    const handleInputChange = (event) => {
        const {name, value} = event.target
        setEventData({ ...eventData, [name]: value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        try{
            const eventResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/upcomingevents/addEvent`,
                eventData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log('Event Added.')
            console.log(eventResponse)

            dispatch(fetchNotifications())
            
             // this is the snipte for the notification

    //    const dateTime=eventResponse.data.date;

    //    const [dateString,timeString]=dateTime.split("T");

    //    const date=new Date(dateString);

    //    const time=timeString.substr(0,8);


    //    const localDate=date.toLocaleDateString();

    //    dispatch(setNotifications({
    //        notification:{
    //          name:name,
    //          details:eventResponse.data.message,
    //          time:time,
    //          date:localDate
    //        }
    //      }))
         dispatch(setNotificationCount({
          count:count+1,
         }))
         
       //   uptill here 

       
            alert('Event Added.')
            navigate('/upcomingevents')

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={EditEventStyles.editEventMain}>
            <div className={EditEventStyles.editEventContent}>
                <form action="" className={EditEventStyles.editEventForm} onSubmit={handleSubmit}>
                    <label className={EditEventStyles.editEventFormLabel}>Title</label>
                    <input className={EditEventStyles.editEventFormText} type="text" value={eventData.eventName} name='eventName' onChange={handleInputChange} />
                    <label className={EditEventStyles.editEventFormLabel}>Description</label>
                    <textarea className={EditEventStyles.editEventFormDesc} value={eventData.eventDesc} name='eventDesc' onChange={handleInputChange}/>
                    <label className={EditEventStyles.editEventFormLabel}>Date</label>
                    <input className={EditEventStyles.editEventFormText} type="date" value={eventData.eventDate} name='eventDate' onChange={handleInputChange} />
                    <label className={EditEventStyles.editEventFormLabel}>Link</label>
                    <input className={EditEventStyles.editEventFormText} type="text" value={eventData.eventLink} name='eventLink' onChange={handleInputChange} />
                    <Button variant='contained' type='submit' className={EditEventStyles.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default AddUpcomingEvent