import React, { useEffect, useState } from 'react'
import EditDetailStyles from './EditCompanyDetail.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

const EditCompanyDetail = () => {
  // get data from redux
  const name=useSelector((state)=>state.globalData.name);
  const count=useSelector((state)=>state.globalData.notificationCount);
  const dispatch =  useDispatch()
    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [source, setSource] = React.useState();
    const [previewUrl, setPreviewUrl] = useState('');
    const [fileName, setFileName] = React.useState('');
    const [pdfSelect, setPdfSelect] = useState(false)
    const [selectedCompanyDetail, setSelectedCompanyDetail] = useState(null);
    const [pdfUrl, setPdfUrl] = useState('');



    const [companyDetailsHeading, setCompanyDetailsHeading] = useState('')

    useEffect(() => {
        if (data) {
            setCompanyDetailsHeading(data.companyDetails?.companyDetailsHeading)
        }
    }, [data])

    const openPdfInNewTab = () => {
        if (previewUrl) {
          window.open(previewUrl, '_blank');
        }
      };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setPdfSelect(true)
        setFile(file)
    };

    const handlePdfChange = (event) => {
        const selectedFile = event.target.files[0]
        setFile(selectedFile)
        setPreviewUrl(URL.createObjectURL(selectedFile))
        setPdfSelect(true)
    }

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardPdf = (event) => {
        // setSource(null)
        // setFileName('')
        setPreviewUrl('')
        setFile(null)
        inputRef.current.value = ""
        setPdfSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        formDataObj.append("file", file)
        formDataObj.append("companyDetailsHeading", companyDetailsHeading)

        try {
            const pdfResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/companydetails/updatePdfbyId/${data.pdfId}`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "application/pdf"
                    }
                }
            )
            console.log(pdfResponse)
            const pdfBlob = new Blob([pdfResponse.data], { type: 'application/pdf' });

            // Generate a URL for the Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            const detailResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/companydetails/updateCompanyDetails/${data.companyDetails.companyDetailsId}`,
                { companyDetailsHeading },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
   

            dispatch(fetchNotifications())
                // this is the snipte for the notification

    //    const dateTime=detailResponse.data.date;

    //    const [dateString,timeString]=dateTime.split("T");

    //    const date=new Date(dateString);

    //    const time=timeString.substr(0,8);


    //    const localDate=date.toLocaleDateString();

    //    dispatch(setNotifications({
    //        notification:{
    //          name:name,
    //          details:detailResponse.data.message,
    //          time:time,
    //          date:localDate
    //        }
    //      }))
         dispatch(setNotificationCount({
          count:count+1,
         }))
         
            console.log('Detail Updated.')
            console.log(detailResponse)
            alert('Detail Updated.')
            discardPdf()
            navigate('/companydetails')
        } catch (error) {
            console.error(error)
        }
    }
    
    

    const handlePdfLink = async (detail) => {
        setSelectedCompanyDetail(detail);
       
    
        if (detail && detail.pdfFile) {
            try {
                const pdfId = detail.pdfId;
                const apiUrl = `${process.env.REACT_APP_GECEADMIN}/companydetails/file/${detail.pdfFile}`;
    
                const response = await axios.get(apiUrl, {
                    responseType: 'blob' // set the response type to blob
                }).catch((err)=>{
                    console.log('Error downloading or displaying the PDF file')
                })
    
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);

                setPdfUrl(url);
               
            } catch (error) {
                console.error('Error downloading or displaying the PDF file', error);
                // Display an error message to the user
                // For example: setErrorState(true);
            }
        }
    };

    useEffect(() => {
        if(data!==null)
        {

            handlePdfLink(data);
        }
    }, [data])    




    return (
        <div className={EditDetailStyles.editDetailMain}>
            <div className={EditDetailStyles.editDetailContent}>
                <form action="" className={EditDetailStyles.editDetailForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={EditDetailStyles.editDetailFormLabel}>Title</label>
                    <input className={EditDetailStyles.editDetailFormText} type="text" value={companyDetailsHeading } name='companyDetailsHeading' onChange={(event) => setCompanyDetailsHeading(event.target.value)} />
                    <input className={EditDetailStyles.editDetailFormText} style={{marginTop:'4%'}} type="text" value={data?.pdfFile} name='companyDetailsHeading' onChange={(event) => setCompanyDetailsHeading(event.target.value)} />
                   
                   {
                       pdfUrl!=='' ? 
                <a  href={pdfUrl}  >Previous Pdf </a>
                :
                <p>Pdf Loading or not There</p>
                } 

                    <label className={EditDetailStyles.editDetailFormLabel}>Choose a PDF:</label>
                    <div className={EditDetailStyles.ImgInput}>
                        <input
                            ref={inputRef}
                            className={EditDetailStyles.ImgInput_input}
                            type="file"
                            onChange={handlePdfChange}
                        />
                        {!previewUrl && <button type='button' onClick={handleChoose}>Browse</button>}
                        {previewUrl && (
                     
                            <iframe src={previewUrl} width="100%" height="500px" title="PDF Preview" />
                        )}
                        <div className={EditDetailStyles.ImgInput_footer}> {pdfSelect ?
                                <div >
                                    {fileName}
                                    <DeleteForeverOutlinedIcon onClick={discardPdf} sx={{ cursor: 'pointer' }} />
                                </div> :
                                ''}
                        </div>
                    </div>
                    <Button variant='contained' type='submit' className={EditDetailStyles.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default EditCompanyDetail