import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import loaderStyle from "./useBackdrop.module.css"

const useBackdrop = () => {
  const [open, setOpen] = useState(false);

  const openBackdrop = () => {
    setOpen(true);
  };

  const closeBackdrop = () => {
    setOpen(false);
  };

  const BackdropComponent = () => (
    <Backdrop
      sx={{ color: '#fff', zIndex: '9999' }}
      open={open}

    >
      {/* <CircularProgress color="inherit" /> */}
      <div className={loaderStyle.dotspinner}>
        <div className={loaderStyle.Dot}></div>
        <div className={loaderStyle.Dot}></div>
        <div className={loaderStyle.Dot}></div>
        <div className={loaderStyle.Dot}></div>
        <div className={loaderStyle.Dot}></div>
        <div className={loaderStyle.Dot}></div>
        <div className={loaderStyle.Dot}></div>
        <div className={loaderStyle.Dot}></div>
      </div>
    </Backdrop>
  );

  return { openBackdrop, closeBackdrop, BackdropComponent };
}

export default useBackdrop