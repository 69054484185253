import React, { useState } from 'react'
import ourStyle from './AddOur.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
import { useParams } from 'react-router-dom';

const AddOur = () => {
    const { id, id2 } = useParams();

    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()
    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()

    const inputRef = React.useRef();

    const [file, setFile] = useState(null)
    const [ourData, setourData] = useState({
        teamName: '',
        teamDesc: '',
        teamPosition: '',
    })
    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [imageSelect, setImageSelect] = useState(false)

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setourData({ ...ourData, [name]: value })
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setImageSelect(true)
        setFile(file)
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardImage = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = ""
        setImageSelect(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
        formDataObj.append("file", file)
        formDataObj.append("teamName", ourData.teamName)
        formDataObj.append("teamDesc", ourData.teamDesc)
        formDataObj.append("teamPosition", ourData.teamPosition)

        try {
            const teamResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/investoppOurTeam/addTeamDetails/${id}/${id2}`,
                ourData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log(teamResponse)

            // console.log(teamResponse)
            // const imageResponse = await axios.post(
            //     `${process.env.REACT_APP_GECEADMIN}/graph/addGraphImage/${teamResponse.data.graphId}`,
            //     formDataObj,
            //     {
            //         headers: {
            //             "Content-Type": "image/jpeg"
            //         }
            //     }
            // )
            alert('Team Added.')
            navigate(`/our/${id}/${id2}`)
            dispatch(fetchNotifications())

            dispatch(setNotificationCount({
                count: count + 1,
            }))

            discardImage()

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={ourStyle.teammemberMain}>
            <div className={ourStyle.teammemberContent}>
                <form action="" className={ourStyle.teammemberForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={ourStyle.teammemberFormLabel}>Teamname</label>
                    <input className={ourStyle.teammemberFormText} type="text" value={ourData.teamName} name='teamName' onChange={handleInputChange} />
                    <label className={ourStyle.teammemberFormLabel}>Team Description</label>
                    <input className={ourStyle.teammemberFormText} type="text" value={ourData.teamDesc} name='teamDesc' onChange={handleInputChange} />
                    <label className={ourStyle.teammemberFormLabel}>Team Position</label>
                    <input className={ourStyle.teammemberFormText} type="text" value={ourData.teamPosition} name='teamPosition' onChange={handleInputChange} />
                    {/* <label className={ourStyle.teammemberFormLabel}>Upload</label>
                    <div className={ourStyle.ImgInput}>
                        <input
                            ref={inputRef}
                            className={ourStyle.ImgInput_input}
                            type="file"
                            onChange={handleFileChange}
                        />
                        {!source && <button type='button' onClick={handleChoose}>Browse</button>}
                        {source && (
                            <img
                                className={ourStyle.ImgInput_img}
                                width="100%"
                                src={source}
                                alt='uploadedimage'
                            />
                        )}
                    </div>
                    <div className={ourStyle.ImgInput_footer}>
                        {imageSelect ?
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                {fileName}
                                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
                            </div> :
                            ''}
                    </div> */}
                    <Button variant='contained' type='submit' className={ourStyle.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default AddOur