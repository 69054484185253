import React from 'react'
import linkstyle from './CreateLink.module.css'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { useParams, useNavigate } from 'react-router-dom';
import { fetchNotifications } from '../../state/GlobalState'
import CloseIcon from '@mui/icons-material/Close';
import { Opacity } from '@mui/icons-material';
import useBackdrop from '../../hooks/useBackdrop/useBackdrop';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import clipboardCopy from 'clipboard-copy';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 10,
    p: 4,

};



const Createlink = () => {


    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const [responseLink, setResponseLink] = useState("");
    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()
    const [copied, setCopied] = useState(false);
    const [open, setOpen] = React.useState(false); // send link
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const [open3, setOpen3] = React.useState(false); //stop sharig
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);


    const navigate = useNavigate()
    const [stopData, setStopData] = useState({
        token: '',
        email: ''
    })
    const { id, invName } = useParams();

    // console.log(invName)

    const [data, setData] = useState([])

    const getLinkData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/getAllTokenUsers`
            )
            setData(res.data)
            console.log(res.data)
        } catch (error) {
            console.log(error)
        }
    }



    useEffect(() => {
        getLinkData()
    }, [])


    const copyToClipboard = (data) => {

        clipboardCopy(data);
        setCopied(true);
    };


    const [linkData, setLinkData] = useState({
        name: '',
        email: '',
        daysToExpire: '',
    })

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setLinkData({ ...linkData, [name]: value })
    }



    const handleStopSharingData = (email, token) => {

        handleOpen2();

        setStopData({
            token: token,
            email: email
        })
    }


    const handleStopSharing = async () => {

        if (stopData.token !== '' && stopData.email !== '') {
            try {
                const res = axios.post(`${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/expireLink/${stopData.email}`, { token: stopData.token }).then((res) => {
                    getLinkData()
                    console.log(res.data)
                }
                ).catch((err) => {
                    console.log("error stoping link")
                })
            } catch (error) {

            }


            handleClose2();
        }
        else {
            alert("error stoping link token not found");
            handleClose2();
        }

    }
    const handleSubmit = async (event) => {
        openBackdrop()
        // console.log("handleSubmit")
        event.preventDefault()

        if (linkData.name !== '' && linkData.email !== '' && linkData.daysToExpire !== '') {
            const formDataObj = new FormData()

            formDataObj.append("name", linkData.name)
            formDataObj.append("email", linkData.email)
            formDataObj.append("daysToExpire", linkData.daysToExpire)


            try {
                const AlllinkResponse = await axios.post(
                    `${process.env.REACT_APP_GECEADMIN}/investmentOpportunities/${id}/generateTokenForAdmin`,
                    formDataObj,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                ).then((res) => {
                    closeBackdrop()

                    console.log(res)
                    setResponseLink(res.data.link);
                    dispatch(fetchNotifications())
                    dispatch(setNotificationCount({
                        count: count + 1,
                    }))
                    navigate(`/createL/${id}/${invName}`)
                    getLinkData()

                    // handleClose2()

                    handleClose()
                    handleOpen3()

                    setLinkData({
                        name: '',
                        email: '',
                        daysToExpire: '',
                    })

                })






            } catch (error) {
                closeBackdrop()
                console.error(error)
            }
        }
        else {
            closeBackdrop()
            if (linkData.name === '') {
                alert('Please enter name.');
                return false;
            } else if (linkData.email === '') {
                alert('Please enter email.');
                return false
            } else {
                alert('Please Enter Expire.');
                return false;
            }
        }

    }

    return (
        <div className={linkstyle.highlightMain}>
            <div className={linkstyle.highlightContent}>
                <div className={linkstyle.highlightHeader}>
                    <div style={{ marginBottom: '2%', display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '1%' }}>
                        <Typography sx={{ color: '#121926', fontWeight: '600', fontSize: '20px' }} >
                            {invName}
                        </Typography>
                        <Button sx={{ textTransform: 'capitalize', width: '10%' }} onClick={handleOpen} variant='contained' >Share</Button>
                        <Modal
                            open={open}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            BackdropProps={{
                                timeout: 500,
                                style: { backgroundColor: '#454545', opacity: '30%' }  // You can customize the style of the backdrop here
                            }}
                            slotProps={{
                                backdrop: {
                                    timeout: 500,
                                },
                            }}
                        >
                            <Box className={linkstyle.LinkModal} sx={style}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5%' }}>
                                    <Typography variant="h6">Share ‘EPC Aggregator</Typography>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon sx={{ fontSize: '25px' }} />
                                    </IconButton>
                                </div>
                                <form action='' onSubmit={handleSubmit} >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label>Name</label>
                                        <input type='text' value={linkData.name} name='name' onChange={handleInputChange} />
                                        <label>Email</label>
                                        <input type='email' value={linkData.email} name='email' onChange={handleInputChange} />
                                        <label>Expire in Day</label>
                                        <div className={linkstyle.inputrow}>
                                            <input value={linkData.daysToExpire} name='daysToExpire' onChange={handleInputChange} />
                                            <span>Days</span>
                                        </div>
                                    </div>
                                    <Box className={linkstyle.LinkButton}>
                                        <Button variant="contained" type='submit' >Send Link</Button>

                                    </Box>
                                </form>

                            </Box>
                        </Modal>

                        <Modal
                            open={open3}
                            onClose={handleClose3}
                            BackdropProps={{
                                timeout: 500,
                                style: { backgroundColor: '#454545', opacity: '30%' }  // You can customize the style of the backdrop here
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={style}>
                                <div style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px" }}>
                                        <h3 style={{ margin: "0" }}>Invitation Sent</h3>
                                        <IconButton onClick={() => {
                                            handleClose3();
                                             setResponseLink("");
                                            setCopied(false)
                                        }}>
                                            <CloseIcon sx={{ fontSize: '25px' }} />
                                        </IconButton>
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                        <p style={{ margin: "0", fontWeight: "600" }} >Link</p>

                                        <p style={{ width: '100%', height: '1.5rem', lineHeight: "1.4rem", overflow: 'hidden', borderBottom: '1px solid black', margin: "0" }}>{responseLink}</p>

                                    </div>

                                    <Box className={linkstyle.LinkButton}>
                                        <Button variant="contained" onClick={() => copyToClipboard(responseLink)} >{copied ? 'copied ' : 'Copy Link'}</Button>

                                    </Box>

                                </div>

                            </Box>
                            {/*
                                <Typography variant="h6">
                                    Inv
                                </Typography>
                                <Typography>
                                    <h1>{responseLink}</h1>
                                </Typography>

                                <button onClick={()=>copyToClipboard("helloravi")} > {copied ?'copied ':'Copy Link' }</button>
                            </Box> */}
                        </Modal>
                    </div>

                </div>
                <Box className={linkstyle.highlightComponent}>
                    <div>
                        <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Name
                        </Typography>
                    </div>

                    <div>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Email
                        </Typography>
                    </div>

                    <div>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Day to Expire
                        </Typography>
                    </div>
                    <div>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Link
                        </Typography>
                    </div>
                    <div>
                        <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: '600' }}>
                            Action
                        </Typography>
                    </div>
                </Box>
                {data.map((item) => {
                    return (
                        <Box className={linkstyle.highlightComponent2} key={item.tokenId}>
                            <div>
                                <Typography variant='h5' sx={{ fontSize: '15px', fontWeight: '600' }}>
                                    {item.name}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                                    {item.email}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant='h6' sx={{ fontSize: '15px' }}>
                                    {item.daysToExpire}
                                </Typography>
                            </div>
                            <div className={linkstyle.CopyLinkButton} style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }} onClick={() => copyToClipboard(item.link)}>
                                <FileCopyOutlinedIcon />
                                <Typography variant='h6' sx={{ fontSize: '15px' }} >Copy Link</Typography>


                            </div>
                            <div>
                                {item?.expired ?
                                    <p style={{ color: 'red' }}>Expired</p>
                                    :
                                    <Button sx={{ textTransform: 'capitalize' }} variant='contained' onClick={() => handleStopSharingData(item.email, item.token)} >Stop Sharing</Button>
                                }
                                <Modal
                                    open={open2}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    BackdropProps={{
                                        timeout: 500,
                                        style: { backgroundColor: '#454545', opacity: '30%' }
                                    }}
                                    slotProps={{
                                        backdrop: {
                                            timeout: 500,
                                        },
                                    }}
                                >
                                    <Box className={linkstyle.LinkModal2} sx={style}>
                                        <div>
                                            <Typography variant='h2'>Are you sure?</Typography>
                                            <Typography variant='h3'>This person wont be able to access the link permanently</Typography>
                                        </div>
                                        <Box className={linkstyle.LinkButton2}>
                                            <Button variant="outlined" onClick={handleClose2}>Cancel</Button>
                                            <Button variant="contained" onClick={handleStopSharing} >Stop Sharing</Button>


                                        </Box>
                                    </Box>
                                </Modal>
                            </div>

                        </Box>
                    )

                })}

            </div >
            < BackdropComponent />
        </div >
    )
}

export default Createlink






