import React, { useEffect, useState } from 'react'
import summaryStyle from './ShortSummary.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { useParams } from 'react-router-dom';
import EditJobStyles from '../EditJob/EditJob.module.css'
import ReactQuill from 'react-quill';




const ShortSummary = () => {
  const { id,id2,summaryId } = useParams();

  const name = useSelector((state) => state.globalData.name);
  const count = useSelector((state) => state.globalData.notificationCount);
  const dispatch = useDispatch()

  const location = useLocation()
  const data = location.state
  console.log(data)

  const navigate = useNavigate()

  const inputRef = React.useRef();
  const [file, setFile] = useState(null)


  const [shortDesc, setShortDesc] = useState('')
  const [highlights, setHighlights] = useState('')
  const [target, setTarget] = useState('')
  const [minimum, setMinimum] = useState('')
  const [investmentRaised, setInvestmentRaised] = useState('')
  const [previousRounds, setPreviousRounds] = useState('')
  const [stage, setStage] = useState('')
  const [investorRole, setInvestorRole] = useState('')


  const getData=async()=>{
    try {
        const res=await axios.get(`${process.env.REACT_APP_GECEADMIN}/summaryandoverview/getSummaryById/${id}/${id2}/${summaryId}`)
        const {data}=res;
           setShortDesc(data.shortDesc)
      setHighlights(data?.highlights)
      setTarget(data.target)
      setMinimum(data.minimum)
      setInvestmentRaised(data.investmentRaised)
      setPreviousRounds(data.previousRounds)
    
      setStage(data.stage)
      setInvestorRole(data.investorRole)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    // if (data) {
    //   setShortDesc(data.team.shortDesc)
    //   setHighlights(data.team.highlights)
    //   setTarget(data.team.target)
    //   setMinimum(data.team.minimum)
    //   setInvestmentRaised(data.team.investmentRaised)
    //   setPreviousRounds(data.team.previousRounds)
    //   setPreviousRounds(data.team.previousRounds)
    //   setStage(data.team.stage)
    //   setInvestorRole(data.team.investorRole)
    // }
    getData();
  }, [])



  const handleChoose = (event) => {
    inputRef.current.click();
  };



  const handleSubmit = async (event) => {
    event.preventDefault()
    const formDataObj = new FormData()
    formDataObj.append("file", file)
    formDataObj.append("shortDesc", shortDesc)
    formDataObj.append("highlights", highlights)
    formDataObj.append("target", target)
    formDataObj.append("minimum", minimum)
    formDataObj.append("investmentRaised", investmentRaised)
    formDataObj.append("previousRounds", previousRounds)
    formDataObj.append("stage", stage)
    formDataObj.append("investorRole", investorRole)
    // /summaryandoverview/addSummary/{invId}/{channelId}
    try {
      const shortResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/summaryandoverview/addSummary/${id}/${id2}`,
        { shortDesc, highlights, target, minimum, investmentRaised, previousRounds, stage, investorRole },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      console.log('Summary Updated.')
      console.log(shortResponse)


      dispatch(setNotificationCount({
        count: count + 1,
      }))

      alert('Summary Updated.')
      navigate(`/pitch/${id}/${id2}`)
    } catch (error) {
      console.error(error)
    }
  }


  // useEffect(() => {
  //   const fetchImage = async () => {
  //     try {
  //       const imageResponse = await axios.get(
  //         `${process.env.REACT_APP_GECEADMIN}/team/file/${data.newteamImageName}`
  //       );
  //       console.log(imageResponse);
  //       setSource(imageResponse);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchImage();
  // }, [data.newteamImageName]);

  return (
    <div className={summaryStyle.teammemberMain}>
      <div className={summaryStyle.teammemberContent}>
        <form action=" " className={summaryStyle.teammemberForm} onSubmit={handleSubmit} encType='multipart/form-data'>
          <label className={summaryStyle.teammemberFormLabel}>Short Summary</label>
          <textarea className={summaryStyle.teammemberFormDesc} rows="4" cols="50" value={shortDesc} name='shortDesc' onChange={(event) => setShortDesc(event.target.value)} />
          <label className={summaryStyle.teammemberFormLabel}>Highlights</label>
          <div  className={EditJobStyles.reactQill} >
                    <ReactQuill theme="snow"  value={highlights}  onChange={setHighlights}  />
                </div>
          {/* <textarea className={summaryStyle.teammemberFormDesc} rows="6" cols="50" value={highlights} name='highlights' onChange={(event) => setHighlights(event.target.value)} /> */}
          <Typography variant='h6'>Overview</Typography>
          <label className={summaryStyle.teammemberFormLabel}>Target</label>
          <input className={summaryStyle.teammemberFormText} type="text" value={target} name='target' onChange={(event) => setTarget(event.target.value)} />
          <label className={summaryStyle.teammemberFormLabel}>Minimum</label>
          <input className={summaryStyle.teammemberFormText} type="text" value={minimum} name='minimum' onChange={(event) => setMinimum(event.target.value)} />
          <label className={summaryStyle.teammemberFormLabel}>Investment Raised</label>
          <input className={summaryStyle.teammemberFormText} type="text" value={investmentRaised} name='investmentRaised' onChange={(event) => setInvestmentRaised(event.target.value)} />
          <label className={summaryStyle.teammemberFormLabel}>Previous Rounds</label>
          <input className={summaryStyle.teammemberFormText} type="text" value={previousRounds} name='previousRounds' onChange={(event) => setPreviousRounds(event.target.value)} />
          <label className={summaryStyle.teammemberFormLabel}> MVP/Finished Product</label>
          <input className={summaryStyle.teammemberFormText} type="text" value={stage} name='stage' onChange={(event) => setStage(event.target.value)} />
          <label className={summaryStyle.teammemberFormLabel}>Investor Role</label>
          <input className={summaryStyle.teammemberFormText} type="text" value={investorRole} name='investorRole' onChange={(event) => setInvestorRole(event.target.value)} />
          
          <Button variant='contained' type='submit' className={summaryStyle.saveChanges}>Save changes</Button>
        </form>
      </div>
    </div>
  )
}

export default ShortSummary