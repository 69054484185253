import React, { useState, useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { setLogout } from '../state/GlobalState';

const RequireAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();
    const dispatch = useDispatch();
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        if (auth && auth.token) {
            const payloadToken = jwt_decode(auth.token);
            const expirationTime = payloadToken.exp * 1000;
            const currentTime = Date.now();
            const timeUntilExpiration = expirationTime - currentTime;

            if (timeUntilExpiration > 0 && timeUntilExpiration <= 300000) { // 5 minutes in milliseconds
                // Show warning if the token will expire in 5 minutes
                setShowWarning(true);

                // Set a timeout to hide the warning after 5 seconds
                const timeoutId = setTimeout(() => {
                    setShowWarning(false);
                }, 5000);

                // Clean up the timeout when the component unmounts
                return () => clearTimeout(timeoutId);
            }
        }
    }, [auth]);

    if (!auth || !auth.token || auth.token === "" || auth.token === null || !auth.name || auth.name === "" || auth.name === null) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        const payloadToken = jwt_decode(auth.token);
        const isTokenExpired = Date.now() >= payloadToken.exp * 1000;

        if (!isTokenExpired) {
            return (
                <div>
                    {showWarning && <p style={{ position: "fixed", top: "8px", left: "10px", backgroundColor: "yellow", color: "black", padding: "10px", zIndex: "99999999" }}>Your token will expire in less then 5 minutes. Please logout and log in again.</p>}
                    <Outlet />
                </div>
            );
        } else {
            dispatch(setLogout());
            return <Navigate to="/login" state={{ from: location }} replace />;
        }
    }
};

export default RequireAuth;