import React, { useEffect, useState } from 'react'
import editV from './EditVideo.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

import { useParams } from 'react-router-dom';


const EditVideo = () => {
    const { id, id2, id3, channelId } = useParams();

    // get data from redux

    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch()
    const [channelData, setChannelData] = React.useState(
        {
            channelName: ' ',
            channelType: ' ',
        }
    )

    const navigate = useNavigate()

    const inputRef = React.useRef();
    const [source, setSource] = React.useState();
    const [fileName, setFileName] = React.useState('');
    const [videoSelect, setVideoSelect] = useState(false)
    const [file, setFile] = useState(null)

    const location = useLocation()
    const data = location.state

    useEffect(()=>{
        if(data)
        
        {
            setSource(`${process.env.REACT_APP_GECEADMIN}/videofile/file/${data.channelData.file.newFileName}`)
            setChannelData({channelName:data.channelData.channelName,channelType:data.channelData.channelType})

        }
    },[data])
  

    const handleInputs = (e) => {
        const {name, value} = e.target;

        setChannelData((prev) => {
            return (
                {
                    ...prev,
                    [name]: value
                }
            )
        })
    }
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name)
        const url = URL.createObjectURL(file);
        setSource(url);
        setVideoSelect(true)
        setFile(file)
       
    };

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    const discardVideo = (event) => {
        setSource(null)
        setFileName('')
        setFile(null)
        inputRef.current.value = "";
        setVideoSelect(false);
    };


    const handleSubmit = async (event) => {

        event.preventDefault();
        const formDataObj = new FormData()
        formDataObj.append("file", file);
       
        let resid = null;


        try {
            const resName = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/channel/updateChannel/${id2}`,
                {
                    channelName: channelData.channelName,
                    channelType: channelData.channelType
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            console.log(resName)
            const resid = resName.data.channelId
        } catch (error) {
            console.error(error)
        }

        try {
            const resUrl = await axios.post(`${process.env.REACT_APP_GECEADMIN}/videofile/updateFile/${id}/${id2}/${id3}`,
                formDataObj,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            console.log(resUrl)
            alert('video Updated.')

            navigate(`/EditInvOppo/${id}`)
            dispatch(fetchNotifications())

            dispatch(setNotificationCount({
                count: count + 1,
            }))
        } catch (error) {
            console.error(error)
        }
    }



    return (
        <div className={editV.teammemberMain}>
            <div className={editV.teammemberContent}>
                <form action="" className={editV.teammemberForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={editV.teammemberFormLabel}>Name</label>
                    <input className={editV.teammemberFormText} type="text" value={channelData.channelName} name='channelName' onChange={handleInputs} />
                    <label className={editV.teammemberFormLabel}>Upload</label>
                    <div className={editV.ImgInput}>
                        <input
                            ref={inputRef}
                            className={editV.ImgInput_input}
                            type="file"
                            onChange={handleFileChange}
                        // accept=".mov,.mp4"
                        />
                        {!source && <button type='button' onClick={handleChoose}>Browse</button>}
                        {source && (
                            <video
                                className={editV.VideoInput_video}
                                width="100%"
                                controls
                                src={source}
                            />

                        )}
                    </div>
                    <div className={editV.ImgInput_footer}>
                        {videoSelect ?
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                {fileName}
                            </div> :
                            ''}
                            <DeleteForeverOutlinedIcon onClick={discardVideo} sx={{ cursor: 'pointer' }} />
                    </div>
                    <Button variant='contained' type='submit' className={editV.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default EditVideo