import React, { useEffect, useState } from 'react'
import forStyle from './AddFormat.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const AddFormat = () => {
   
    const location = useLocation()
   
    const {id,type}=location.state
    // console.log(data)

    const navigate = useNavigate()

    const [channel, setChannel] = useState('')
    const [invPdf, setInvPdf] = useState('')
    const [channelId,setChannelId]=useState("");
  
 
    const handleSubmit = async (event) => {
        event.preventDefault()
        const formDataObj = new FormData()
     

        try {
            const resName = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/channel/addChannel/${id}`,
                {channelName:channel,channelType:type},
                {
                      headers: {
                       "Content-Type": "application/json",
                         },
                }
            )
            console.log(resName)
            const resid=resName.data.channelId
           
           
           const resUrl = await axios.post(`${process.env.REACT_APP_GECEADMIN}/pdf/addPdf/${id}/${resid}`,
           {pdfFile:invPdf},
           {
            headers: {
             "Content-Type": "application/json",
               },
                }
           )
            console.log('Highlight Updated.')
            console.log(resUrl)
            alert('Pdf Updated.')
        
            navigate(`/EditInvOppo/${id}`)
        } catch (error) {
            console.error(error)
        }
    }

    // useEffect(() => {
    //     const fetchImage = async () => {
    //       try {
    //         const imageResponse = await axios.get(
    //           `${process.env.REACT_APP_GECEADMIN}/investmentHighlights/file/${data.logo}`
    //         );
    //         console.log(imageResponse);
    //         setSource(imageResponse);
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     };
    
    //     fetchImage();
    //   }, [data.logo]);
    

    return (
        <div className={forStyle.editHighlightMain}>
            <div className={forStyle.editHighlightContent}>
                <form action="" className={forStyle.editHighlightForm} onSubmit={handleSubmit} encType='multipart/form-data'>
                    <label className={forStyle.editHighlightFormLabel}>Name</label>
                    <input className={forStyle.editHighlightFormText} type="text" value={channel} name='channel' onChange={(event) => setChannel(event.target.value)} />
                    <label className={forStyle.editHighlightFormLabel}>Url</label>
                    <input className={forStyle.editHighlightFormText} type="text" value={invPdf} name='invPdf' onChange={(event) => setInvPdf(event.target.value)} />
 
                        {/* <div className={forStyle.ImgInput_footer}>
                            {imageSelect ?
                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                    {fileName}
                                </div> :
                                ''}
                                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
                        </div> */}
                    <Button variant='contained' type='submit' className={forStyle.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default AddFormat

