

import { Box, Typography } from '@mui/material';
import React from 'react';
import NStyles from './Notifications.module.css';
import axios from 'axios';
import LoginPage from '../LoginPage/LoginPage';
import { useState } from 'react';
import { useEffect } from 'react';
import { isLoggedIn, doLogout } from "../../auth";
import { useSelector,useDispatch } from 'react-redux';
import {fetchNotifications } from "../../state/GlobalState";
function Notifications() {


  // const [login, setLogin] = useState(false)
  // const [user, setUser] = useState(undefined)
  
  const dispatch=useDispatch();

  useEffect(()=>{
    dispatch(fetchNotifications())
  },[])

  const today = new Date();
const year = today.getFullYear();
let month = today.getMonth() + 1; 
let day = today.getDate();
if(day<10)
{
  day="0"+day
}

if(month<10)
{
  month="0"+month
}
 const todayDate=year+"-"+month+'-'+day;


 const handleDate=(date)=>{

    const dday=date.split('-');

    
    if(date===todayDate)
    {
      return "Today"
    }
    else if((day-dday[3])===1)
    {
      return "Yesterday"
    }
    else{
      return date;
    }
 }

  // useEffect(() => {
  //   setLogin(isLoggedIn())
  //   const data = JSON.parse(localStorage.getItem('data'))
  //   setUser(data?.name || data?.sendDetails?.name)
  // }, [login])


  // const notificationDataObj=useState({
  //   // email:"",name:"",description:"",time:"",
  //   details:'',name:'',time:''

  // })

  const notifications = useSelector((state) => state.globalData.notifications);




  // TODO: make an endpoint where the user can hit the api and can post data which will have the dataobj which is notificationDataObj
  // so every click where you want a notification to be you post this obj to the api and save it in the database
  //  
  const noticationgrup = notifications
    .reduce((groups, notification) => {
      let date = handleDate(notification.date);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(notification);
      return groups;
    }, {})


    console.log(noticationgrup);
  return (
    <div className={NStyles.NotificationsMain}>
      <Box className={NStyles.NotificationsContainer}>
        <Typography variant="h2">Notifications</Typography>
        

        {/* Map over the notificationsData array */}
        {
        
   

          Object.keys(noticationgrup).reverse().map(date=>{

            const noti=noticationgrup[date];
            return (
              <>


               <Typography variant="h3">{
                   date
                  }</Typography>
                {
                noti.map((notification, index) => {
                  return(
                  <>
                  

                  <Box
                    key={index} // Use a unique key for each item in the array
                    className={NStyles.NotificationsAlert}
                    sx={{
                      alignItems: { xs: 'baseline', sm: 'baseline', md: 'center', lg: 'baseline' },
                    }}
                  >
                    <Box className={NStyles.AlertMain}>
                      <Typography
                        variant="h4"
                        sx={{
                          flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row !important' },
                          width: { xs: '80%', md: '80%' },
                        }}
                      >
                        {notification.name}
                        <span>{notification.details}</span>

                      </Typography>
                    </Box>
                    <Typography variant="h6">{notification.time}</Typography>
                  </Box>
                  </>
                  )
                }).reverse()}

              </>
          
            )
            

          })
       

        }

        {/* Rest of your code */}
      </Box>
    </div>
  );
}

export default Notifications;




