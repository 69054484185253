import React, { useEffect, useState } from 'react'
import eidtStyle from './Editbusi.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

import { useParams } from 'react-router-dom';


const Editbusi = () => {
  const { id, id2, id3 } = useParams();

  // get data from redux
  const name = useSelector((state) => state.globalData.name);
  const count = useSelector((state) => state.globalData.notificationCount);
  const dispatch = useDispatch()

  const location = useLocation()
  const data = location.state
  console.log(data)

  const navigate = useNavigate()

  const inputRef = React.useRef();

  const [file, setFile] = useState(null)
  const [source, setSource] = React.useState();
  const [fileName, setFileName] = React.useState('');
  const [imageSelect, setImageSelect] = useState(false)

  const [businessHeading, setbusinessHeading] = useState('')
  const [businessLink, setbusinessLink] = useState('')

  useEffect(() => {
    if (data) {
      setbusinessHeading(data.graphInfo.businessHeading)
      setbusinessLink(data.graphInfo.businessLink)

      setSource(`${process.env.REACT_APP_GECEADMIN}/investorBusiness/file/${data.graphInfo.image.newBusinessImageName}`);
      console.log(data);
    }
  }, [data])

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name)
    const url = URL.createObjectURL(file);
    setSource(url);
    setImageSelect(true)
    setFile(file)
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const discardImage = (event) => {
    setSource(null)
    setFileName('')
    setFile(null)
    inputRef.current.value = ""
    setImageSelect(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formDataObj = new FormData()
    formDataObj.append("file", file)
    formDataObj.append("businessHeading", businessHeading)
    formDataObj.append("businessLink", businessLink)

    try {
      const imageResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/investorBusiness/uploadBusinessImageById/${id3}/${data.graphInfo.images.businessImageId}`,
        formDataObj,
        {
          headers: {
            "Content-Type": "image/jpeg"
          }
        }
      )
      console.log(imageResponse)
    } catch (error) {
      console.error(error)
    }
    try {
      const businessResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/investorBusiness/updateBusinessDetails/${id}/${id2}/${id3}`,
        { businessHeading, businessLink },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      // console.log('Business Updated.')
      // console.log(businessResponse)

    } catch (error) {
      console.error(error)
    }
    alert('Business Updated.')

    dispatch(fetchNotifications())

    dispatch(setNotificationCount({
      count: count + 1,
    }))
    discardImage()
    navigate(`/theBusi/${id}/${id2}`)
  }

  //   useEffect(() => {
  //     const fetchImage = async () => {
  //       try {
  //         const imageResponse = await axios.get(
  //           `${process.env.REACT_APP_GECEADMIN}/graph/file/${data.images.newImage}`
  //         );
  //         console.log(imageResponse);
  //         setSource(imageResponse);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     };

  //     fetchImage();
  //   }, [data.images.newImage]);

  return (
    <div className={eidtStyle.teammemberMain}>
      <div className={eidtStyle.teammemberContent}>
        <form action="" className={eidtStyle.teammemberForm} onSubmit={handleSubmit} encType='multipart/form-data'>
          <label className={eidtStyle.teammemberFormLabel}>Name</label>
          <input className={eidtStyle.teammemberFormText} type="text" value={businessHeading} name='businessHeading' onChange={(event) => setbusinessHeading(event.target.value)} />
          <label className={eidtStyle.teammemberFormLabel}>Description</label>
          <textarea className={eidtStyle.teammemberFormDesc} value={businessLink} rows={8} name='businessLink' onChange={(event) => setbusinessLink(event.target.value)} />
          <label className={eidtStyle.teammemberFormLabel}>Upload</label>
          <div className={eidtStyle.ImgInput}>
            <input
              ref={inputRef}
              className={eidtStyle.ImgInput_input}
              type="file"
              onChange={handleFileChange}
            />
            {!source && <button type='button' onClick={handleChoose}>Browse</button>}
            {source && (
              <img
                className={eidtStyle.ImgInput_img}
                width="100%"
                // src={`${process.env.REACT_APP_GECEADMIN}/graph/file/${data.graphs.images.newImage}`}
                src={source}
                alt='uploadedimage'
              />
            )}
          </div>
          <div className={eidtStyle.ImgInput_footer}>
            {imageSelect ?
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                {fileName}
              </div> :
              ''}
          </div>
          <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
          <Button variant='contained' type='submit' className={eidtStyle.saveChanges}>Save changes</Button>
        </form>
      </div>
    </div>
  )
}

export default Editbusi