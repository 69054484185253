import React, { useState, useEffect, useRef } from 'react';
import HomeBannerStyles from './EditAboutUs.module.css';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setNotificationCount, fetchNotifications, setNotifications } from '../../state/GlobalState';

const EditAboutUs = () => {

    const name = useSelector((state) => state.globalData.name);
    const count = useSelector((state) => state.globalData.notificationCount);
    const dispatch = useDispatch();

    const location = useLocation();
    const data = location.state
    console.log(data)

    const navigate = useNavigate();

    const [bannerData, setBannerData] = useState({
        aboutUsHeading: '',
        aboutUsVideo: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setBannerData({ ...bannerData, [name]: value })
    }

    const getData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_GECEADMIN}/aboutUs/getAllaboutUs`,


            )

            const { data } = res;

            console.log(data)
            setBannerData(prev => {
                return {
                    aboutUsHeading: data[0]?.aboutUsHeading,
                    aboutUsVideo: data[0]?.aboutUsVideo,
                }
            });
            // setBannerData(data[0].aboutUsHeading);
            // setBannerData(data[0].aboutUsVideo);


        } catch (error) {
            console.log("error getting data or data doesnot exists")
        }
    }


    useEffect(() => {
        getData();
    }, [])
    const handleSubmit = async (event) => {
        event.preventDefault();
        // const formDataObj = new FormData();
        // formDataObj.append("aboutUsHeading", bannerData.aboutUsHeading);
        // formDataObj.append("aboutUsVideo", bannerData.aboutUsVideo);
        let stringWithSingleQuotes = bannerData.aboutUsVideo.replace(/"/g, "'");

        try {
            const bannerResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/aboutUs/addAboutUsVideoandHeading`,
                {
                    aboutUsHeading: bannerData.aboutUsHeading,
                    aboutUsVideo: `${stringWithSingleQuotes}`,
                }
                ,

            )

            dispatch(fetchNotifications())

            dispatch(setNotificationCount({
                count: count + 1,
            }))


            alert('video Link Updated.')
            navigate('/aboutUs')
            const dateTime = bannerResponse.data.date;


            // const dateTime = investResponse.data.data.Time;

            const [dateString, timeString] = dateTime.split("T");

            const date = new Date(dateString);
            const time = timeString.substr(0, 8);

            const localDate = date.toLocaleDateString();

            dispatch(setNotifications({
                notification: {
                    name: name,
                    details: bannerResponse.data.message,
                    time: time,
                    date: localDate
                }
            }))
            dispatch(setNotificationCount({
                count: count + 1,
            }))

            // alert('link Added.')
            // navigate('/aboutUs')
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <div className={HomeBannerStyles.homebannerMain}>
            <div className={HomeBannerStyles.homebannerContent}>
                <form action="" className={HomeBannerStyles.homebannerForm} onSubmit={handleSubmit} encType="multipart/form-data">
                    <label className={HomeBannerStyles.homebannerFormLabel}>Title</label>
                    <input
                        className={HomeBannerStyles.homebannerFormText}
                        type="text"
                        name="aboutUsHeading"
                        // placeholder={bannerData?.aboutUsHeading}
                        value={bannerData.aboutUsHeading}
                        onChange={handleInputChange}
                    />
                    <label className={HomeBannerStyles.homebannerFormLabel}>Upload</label>

                    <input
                        className={HomeBannerStyles.homebannerFormText}
                        type="text"
                        name="aboutUsVideo"
                        // placeholder={bannerData?.aboutUsVideo}
                        value={bannerData?.aboutUsVideo}
                        onChange={handleInputChange}
                    />
                    <Button variant="contained" type="submit" className={HomeBannerStyles.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    );
};

export default EditAboutUs;
