import React, { useEffect, useState } from 'react'
import editOurStyle from './EditOur.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'

import { useParams } from 'react-router-dom';


const EditOur = () => {
  const { id, id2, id3 } = useParams();

  // get data from redux
  const name = useSelector((state) => state.globalData.name);
  const count = useSelector((state) => state.globalData.notificationCount);
  const dispatch = useDispatch()

  const location = useLocation()
  const data = location.state
  console.log(data)

  const navigate = useNavigate()

  const inputRef = React.useRef();

  const [file, setFile] = useState(null)

  const [teamName, setteamName] = useState('')
  const [teamDesc, setteamDesc] = useState('')
  const [teamPosition, setteamPosition] = useState('')

  useEffect(() => {
    if (data) {
      setteamName(data.graphInfo.teamName)
      setteamDesc(data.graphInfo.teamDesc)
      setteamPosition(data.graphInfo.teamPosition)
      console.log(data);
    }
  }, [data])



  const handleSubmit = async (event) => {
    event.preventDefault()
    const formDataObj = new FormData()
    formDataObj.append("file", file)
    formDataObj.append("teamName", teamName)
    formDataObj.append("teamDesc", teamDesc)
    formDataObj.append("teamPosition", teamPosition)

    // try {
    //   const imageResponse = await axios.post(
    //     `${process.env.REACT_APP_GECEADMIN}/investoppOurTeam/updateTeamDetails/${id3}/${data.team.images.imageId}`,
    //     formDataObj,
    //     {
    //       headers: {
    //         "Content-Type": "image/jpeg"
    //       }
    //     }
    //   )
    //   console.log(imageResponse)
    // } catch (error) {
    //   console.error(error)
    // }
    try {
      const businessResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/investoppOurTeam/updateTeamDetails/${id}/${id2}/${id3}`,
        { teamName, teamDesc, teamPosition },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      // console.log('Business Updated.')
      // console.log(businessResponse)

    } catch (error) {
      console.error(error)
    }
    alert('Team Updated.')

    dispatch(fetchNotifications())

    dispatch(setNotificationCount({
      count: count + 1,
    }))
    navigate(`/our/${id}/${id2}`)
  }

  //   useEffect(() => {
  //     const fetchImage = async () => {
  //       try {
  //         const imageResponse = await axios.get(
  //           `${process.env.REACT_APP_GECEADMIN}/graph/file/${data.images.newImage}`
  //         );
  //         console.log(imageResponse);
  //         setSource(imageResponse);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     };

  //     fetchImage();
  //   }, [data.images.newImage]);

  return (
    <div className={editOurStyle.teammemberMain}>
      <div className={editOurStyle.teammemberContent}>
        <form action="" className={editOurStyle.teammemberForm} onSubmit={handleSubmit} encType='multipart/form-data'>
          <label className={editOurStyle.teammemberFormLabel}>Teamname</label>
          <input className={editOurStyle.teammemberFormText} type="text" value={teamName} name='teamName' onChange={(event) => setteamName(event.target.value)} />
          <label className={editOurStyle.teammemberFormLabel}>Team Description</label>
          <textarea className={editOurStyle.teammemberFormText} rows={8} type="text" value={teamDesc} name='teamDesc' onChange={(event) => setteamDesc(event.target.value)} />
          <label className={editOurStyle.teammemberFormLabel}>Team Position</label>
          <input className={editOurStyle.teammemberFormText} type="text" value={teamPosition} name='teamPosition' onChange={(event) => setteamPosition(event.target.value)} />
          {/* <label className={editOurStyle.teammemberFormLabel}>Upload</label>
          <div className={editOurStyle.ImgInput}>
            <input
              ref={inputRef}
              className={editOurStyle.ImgInput_input}
              type="file"
              onChange={handleFileChange}
            />
            {!source && <button type='button' onClick={handleChoose}>Browse</button>}
            {source && (
              <img
                className={editOurStyle.ImgInput_img}
                width="100%"
                src={`${process.env.REACT_APP_GECEADMIN}/graph/file/${data.graphs.images.newImage}`}
                alt='uploadedimage'
              />
            )}
          </div>
          <div className={editOurStyle.ImgInput_footer}>
            {imageSelect ?
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                {fileName}
              </div> :
              ''}
          </div> */}
          {/* <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} /> */}
          <Button variant='contained' type='submit' className={editOurStyle.saveChanges}>Save changes</Button>
        </form>
      </div>
    </div>
  )
}

export default EditOur