
import React, { useState } from 'react'
import EditAddressStyles from './AddAddress.module.css'
import { Button } from '@mui/material';
import axios from 'axios';

  // import this for redux read write
  import { useSelector, useDispatch } from 'react-redux'
  import { setNotifications } from '../../state/GlobalState'
  import { setNotificationCount } from '../../state/GlobalState'
  import { fetchNotifications } from '../../state/GlobalState'

  import { useLocation, useNavigate } from 'react-router-dom';




const AddAddress = () => {
    const location = useLocation()
    const data = location.state
    console.log(data)

    const navigate = useNavigate()
    // get data from redux
    const name=useSelector((state)=>state.globalData.name);
    const count=useSelector((state)=>state.globalData.notificationCount);
    const dispatch =  useDispatch()


    const [addressData, setAddressData] = useState({
        branchAddressName: '',
        branchAddressDesc: '',
        contactNo: '',
    })



    const handleInputChange = (event) => {
        const {name, value} = event.target
        setAddressData({ ...addressData, [name]: value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()




        if(addressData.branchAddressName!=='' && addressData.branchAddressDesc!=='' && addressData.contactNo!=='')
        {
            const formDataObj = new FormData()

            formDataObj.append("branchAddressName", addressData.branchAddressName)
            formDataObj.append("branchAddressDesc", addressData.branchAddressDesc)
            formDataObj.append("contactNo", addressData.contactNo)


        try{
            const businessResponse = await axios.post(
                `${process.env.REACT_APP_GECEADMIN}/address/addAddress`,
                addressData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            // console.log('Address Added.')
            console.log(businessResponse)



             // this is the snipte for the notification
           dispatch(fetchNotifications())



   
         dispatch(setNotificationCount({
          count:count+1,
         }))


             //   uptill here
             navigate('/branchaddress')
            alert('Address Added.')


        } catch (error) {
            console.error(error)
        }
    }
        else {
            if (addressData.branchAddressName === '') {
              alert('Please provide the address name.');
              return false;
            } else if (addressData.branchAddressDesc === '') {
              alert('Please provide the address description.');
              return false
            } else {
              alert('Please provide the contact number.');
              return false;

              
            }
          }

    }

    return (
        <div className={EditAddressStyles.editAddressMain}>
            <div className={EditAddressStyles.editAddressContent}>
                <form action="" className={EditAddressStyles.editAddressForm} onSubmit={handleSubmit}>
                    <label className={EditAddressStyles.editAddressFormLabel}>Location</label>
                    <input className={EditAddressStyles.editAddressFormText} type="text" value={addressData.branchAddressName} name='branchAddressName' onChange={handleInputChange}/>
                    <label className={EditAddressStyles.editAddressFormLabel}>Description</label>
                    <textarea className={EditAddressStyles.editAddressFormDesc} value={addressData.branchAddressDesc} name='branchAddressDesc' onChange={handleInputChange}/>
                    <label className={EditAddressStyles.editAddressFormLabel}>Phone No.</label>
                    <input className={EditAddressStyles.editAddressFormText} type="text" value={addressData.contactNo} name='contactNo' onChange={handleInputChange}/>
                    <Button variant='contained' type='submit' className={EditAddressStyles.saveChanges}>Save changes</Button>
                </form>
            </div>
        </div>
    )
}

export default AddAddress









