import { useSelector, useDispatch } from 'react-redux';

import { setLogin, setLogout,setUserData, setOrgLogo, setMobNum, setProfilePic } from '../state/GlobalState';

const useAuth = () => {
    // const name=useSelector((state)=>state.authData.name);
    // const companyName=useSelector((state)=>state.authData.companyName);
    // const email=useSelector((state)=>state.authData.email);
    // const token=useSelector((state)=>state.authData.token);
    // const userData=useSelector((state)=>state.authData.userData);
    // const roles=useSelector((state)=>state.authData.roles);

    const auth = {
        user: useSelector((state) => state.globalData.user),
        token: useSelector((state) => state.globalData.token),
        name: useSelector((state) => state.globalData.name),
     
    }

    return {auth}
}

export default useAuth;

