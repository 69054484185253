import React, { useEffect, useState } from 'react'
import TeamMemberStyles from './EditTeamMember.module.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// import this for redux read write
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'



const EditTeamMember = () => {

     // get data from redux
     const name=useSelector((state)=>state.globalData.name);
     const count=useSelector((state)=>state.globalData.notificationCount);
     const dispatch =  useDispatch()

  const location = useLocation()
  const data = location.state
  console.log(data)

  const navigate = useNavigate()

  const inputRef = React.useRef();

  const [file, setFile] = useState(null)
  const [source, setSource] = React.useState();
  const [fileName, setFileName] = React.useState('');
  const [imageSelect, setImageSelect] = useState(false)

  const [teammateName, setTeammateName] = useState('')
  const [teammatePosition, setTeammatePosition] = useState('')
  const [teammateInfo, setTeammateInfo] = useState('')

  useEffect(() => {
    if (data) {
      setTeammateName(data.team.teammateName)
      setTeammatePosition(data.team.teammatePosition)
      setTeammateInfo(data.team.teammateInfo)
    }
  }, [data])

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name)
    const url = URL.createObjectURL(file);
    setSource(url);
    setImageSelect(true)
    setFile(file)
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const discardImage = (event) => {
    setSource(null)
    setFileName('')
    setFile(null)
    inputRef.current.value = ""
    setImageSelect(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formDataObj = new FormData()
    formDataObj.append("file", file)
    formDataObj.append("teammateName", teammateName)
    formDataObj.append("teammatePosition", teammatePosition)
    formDataObj.append("teammateInfo", teammateInfo)

    try {
      const imageResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/team/updateTeamImagebyId/${data.teamImageId}`,
        formDataObj,
        {
          headers: {
            "Content-Type": "image/jpeg"
          }
        }
      )
      console.log(imageResponse)

      const businessResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/team/updateTeamDetails/${data.team.teammateId}`,
        { teammateName, teammatePosition, teammateInfo },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      console.log('Member Updated.')
      console.log(businessResponse)

       // this is the snipte for the notification

      //  const dateTime=businessResponse.data.date;

      //  const [dateString,timeString]=dateTime.split("T");

      //  const date=new Date(dateString);

      //  const time=timeString.substr(0,8);


      //  const localDate=date.toLocaleDateString();

      //  dispatch(setNotifications({
      //      notification:{
      //        name:name,
      //        details:businessResponse.data.message,
      //        time:time,
      //        date:localDate
      //      }
      //    }))
         dispatch(setNotificationCount({
          count:count+1,
         }))
         
       //   uptill here 



      alert('Member Updated.')
      discardImage()
      navigate('/meetourteam')
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageResponse = await axios.get(
          `${process.env.REACT_APP_GECEADMIN}/team/file/${data.newteamImageName}`
        );
        console.log(imageResponse);
        setSource(imageResponse);
      } catch (error) {
        console.error(error);
      }
    };

    fetchImage();
  }, [data.newteamImageName]);

  return (
    <div className={TeamMemberStyles.teammemberMain}>
      <div className={TeamMemberStyles.teammemberContent}>
        <form action="" className={TeamMemberStyles.teammemberForm} onSubmit={handleSubmit} encType='multipart/form-data'>
          <label className={TeamMemberStyles.teammemberFormLabel}>Name</label>
          <input className={TeamMemberStyles.teammemberFormText} type="text" value={teammateName} name='teammateName' onChange={(event) => setTeammateName(event.target.value)} />
          <label className={TeamMemberStyles.teammemberFormLabel}>Position</label>
          <input className={TeamMemberStyles.teammemberFormText} type="text" value={teammatePosition} name='teammatePosition' onChange={(event) => setTeammatePosition(event.target.value)} />
          <label className={TeamMemberStyles.teammemberFormLabel}>Description</label>
          <textarea className={TeamMemberStyles.teammemberFormDesc} value={teammateInfo} name='teammateInfo' onChange={(event) => setTeammateInfo(event.target.value)} />
          <label className={TeamMemberStyles.teammemberFormLabel}>Upload</label>
          <div className={TeamMemberStyles.ImgInput}>
            <input
              ref={inputRef}
              className={TeamMemberStyles.ImgInput_input}
              type="file"
              onChange={handleFileChange}
            />
            {!source && <button type='button' onClick={handleChoose}>Browse</button>}
            {source && (
              <img
                className={TeamMemberStyles.ImgInput_img}
                width="100%"
                src={`${process.env.REACT_APP_GECEADMIN}/team/file/${data.newteamImageName}`}
                alt='uploadedimage'
              />
            )}
              </div>
            <div className={TeamMemberStyles.ImgInput_footer}>
              {imageSelect ?
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                  {fileName}
                </div> :
                ''}
            </div>
                <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
          <Button variant='contained' type='submit' className={TeamMemberStyles.saveChanges}>Save changes</Button>
        </form>
      </div>
    </div>
  )
}

export default EditTeamMember