import React, { useState } from 'react';
import BusinessStyles from './EditTestimonial.module.css';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TeamMemberStyles from '../EditTeamMember/EditTeamMember.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { setNotifications } from '../../state/GlobalState'
import { setNotificationCount } from '../../state/GlobalState'
import { fetchNotifications } from '../../state/GlobalState'
import { useEffect} from 'react';

const EditTestimonial = () => {

  const location = useLocation();
  const data = location.state;
  console.log(data);

  const navigate = useNavigate();
  const inputRef = React.useRef();

  const name = useSelector((state) => state.globalData.name);
  const count = useSelector((state) => state.globalData.notificationCount);
  const dispatch = useDispatch()


  const [file, setFile] = useState(null)
  const [source, setSource] = React.useState();
  const [fileName, setFileName] = React.useState('');
  const [imageSelect, setImageSelect] = useState(false)


  const [testimonialName, setTestimonialName] = useState('')
  const [testimonialPosition, setTestimonialPosition] = useState('')
  const [testimonialMessage, setTestimonialMessage] = useState('')

  useEffect(() => {
    if (data) {
      setTestimonialName(data?.testimonialName)
      setTestimonialPosition(data?.testimonialPosition)
      setTestimonialMessage(data?.testimonialMessage)
    }
  }, [data])

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name)
    const url = URL.createObjectURL(file);
    setSource(url);
    setImageSelect(true)
    setFile(file)
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  const discardImage = (event) => {
    setSource(null)
    setFileName('')
    setFile(null)
    inputRef.current.value = ""
    setImageSelect(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const formDataObj = new FormData()
    formDataObj.append("file", file)
    formDataObj.append("testimonialName", testimonialName)
    formDataObj.append("testimonialPosition", testimonialPosition)
    formDataObj.append("testimonialMessage", testimonialMessage)

    try {
      const businessResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/testimonial/updateTestimonial/${data.testimonialId}`,
        { testimonialName, testimonialPosition, testimonialMessage },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log(businessResponse)

      const imageResponse = await axios.post(
        `${process.env.REACT_APP_GECEADMIN}/testimonial/updateTestimonialImage/${data.testimonial_images.testimonialImageId}`,
        formDataObj,
        {
          headers: {
            "Content-Type": "image/jpeg"
          }
        }
      )
      console.log(imageResponse)
      // console.log('Testimonial Updated.');
      // console.log(businessResponse);

      dispatch(fetchNotifications())

      dispatch(setNotificationCount({
        count: count + 1,
      }))

      alert('Testimonial Updated.');
      navigate('/testimonial');
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    const fetchImage = async () => {
      try {
        const imageResponse = await axios.get(
          `${process.env.REACT_APP_GECEADMIN}/testimonial/file/${data.testimonial_images.newtestimonialImageName}`
        );
        console.log(imageResponse);
        setSource(imageResponse);
      } catch (error) {
        console.error(error);
      }
    };

    fetchImage();
  }, [data.testimonial_images.newtestimonialImageName]);


  return (
    <div className={BusinessStyles.businessMain}>
      <div className={BusinessStyles.businessContent}>
        <form
          action=""
          className={BusinessStyles.businessForm}
          onSubmit={handleSubmit}
        >
          <label className={BusinessStyles.businessFormLabel}>Name</label>
          <input
            className={BusinessStyles.businessFormText}
            type="text"
            value={testimonialName}
            name="testimonialName"
            onChange={(event) => setTestimonialName(event.target.value)} 
            // onChange={handleInputChange}
          />
          <label className={BusinessStyles.businessFormLabel}>Position</label>
          <input
            className={BusinessStyles.businessFormText}
            type="text"
            value={testimonialPosition}
            name="testimonialPosition"
            // onChange={handleInputChange}
            onChange={(event) => setTestimonialPosition(event.target.value)} 

          />
          <label className={BusinessStyles.businessFormLabel}>Message</label>
          <textarea
            className={BusinessStyles.businessFormDesc}
            value={testimonialMessage}
            name="testimonialMessage"
            // onChange={handleInputChange}
            onChange={(event) => setTestimonialMessage(event.target.value)} 

          />

          <label className={TeamMemberStyles.teammemberFormLabel}>Upload</label>
          <div className={TeamMemberStyles.ImgInput}>
            <input
              ref={inputRef}
              className={TeamMemberStyles.ImgInput_input}
              type="file"
              onChange={handleFileChange}
            />
            {!source && <button type='button' onClick={handleChoose}>Browse</button>}
            {source && (
              <img
                className={TeamMemberStyles.ImgInput_img}
                width="100%"
                src={`${process.env.REACT_APP_GECEADMIN}/testimonial/file/${data.testimonial_images.newtestimonialImageName}`}
                alt='uploadedimage'
              />
            )}
          </div>
          <div className={TeamMemberStyles.ImgInput_footer} >
            {imageSelect ?
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                {fileName}
              </div> :
              ''}
               <DeleteForeverOutlinedIcon onClick={discardImage} sx={{ cursor: 'pointer' }} />
          </div>

          <Button variant="contained" type="submit" className={BusinessStyles.saveChanges}>
            Save changes
          </Button>
        </form>
      </div>
    </div>
  );
};

export default EditTestimonial;
