
import React, { useEffect, useState } from 'react'
import DashboardStyles from './Dashboard.module.css'
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import WifiTetheringOutlinedIcon from '@mui/icons-material/WifiTetheringOutlined';
import ReactDOM from "react-dom";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { useSelector, useDispatch } from 'react-redux';
import { setNotifications,fetchNotifications } from "../../state/GlobalState";
import axios from "axios";



const Progress_bar = ({ bgcolor, progress, height }) => {



  const Parentdiv = {
    height: height,
    width: '100%',
    backgroundColor: 'whitesmoke',
    borderRadius: 40,
    margin: 5,
    fontSize: '5px',

  }

  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: 'right'
  }

  const progresstext = {
    padding: 5,
    color: 'transparent',
    fontWeight: 600
  }

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}>{`${progress}%`}</span>
      </div>
    </div>
  )
}




const Dashboard = () => {
  const dispatch = useDispatch();

// refresh notification
  useEffect(()=>{
    dispatch(fetchNotifications())
  },[])
  const notifications = useSelector((state) => state.globalData.notifications);
  const data = [
    { name: 'Mon', currentWeek: 1000, previousWeek: 500 },
    { name: 'Tue', currentWeek: 2000, previousWeek: 1000 },
    { name: 'Wed', currentWeek: 3000, previousWeek: 2000 },
    { name: 'Thu', currentWeek: 2500, previousWeek: 3000 },
    { name: 'Fri', currentWeek: 4000, previousWeek: 2500 },
    { name: 'Sat', currentWeek: 3500, previousWeek: 3000 },
    { name: 'Sun', currentWeek: 5000, previousWeek: 4500 },
  ];

  const SingleWork = ({ icon, heading, para, time, date }) => {
    return (
      <div className={DashboardStyles.NotifnBox}>
        <Box className={DashboardStyles.dashNotinCard}>
          <Box className={DashboardStyles.DashiconStart}><Typography variant='h5'>{icon}</Typography> </Box>
          <Box className={DashboardStyles.DashicontentStart}>
            <Typography variant='h4'>{heading}</Typography>
            <Typography variant='h6'>{para}</Typography>
          </Box>
          <Box className={DashboardStyles.DashicontentStart2}>
            <Typography variant='h6'>{date}</Typography>
            <Typography variant='h6'>{time}</Typography>
          </Box>
        </Box>
      </div>





    )
  }














  return (
    <div className={DashboardStyles.dashboardMain}>
      <div className={DashboardStyles.dashboardMainContent}>
        <div className={DashboardStyles.visitWidgets}>
          <Box className={DashboardStyles.dashBox}>
            <Card variant="outlined" className={DashboardStyles.dashCardBox}>
              <CardContent>
                <Typography variant="h6">Total Visit</Typography>
                <Typography variant="h4"> 721k</Typography>
              </CardContent>

            </Card>


            <Card variant="outlined" className={DashboardStyles.dashCardBox}>
              <CardContent>
                <Typography variant="h6">Bounce Rate</Typography>
                <Typography variant="h4"> 367K</Typography>
              </CardContent>
            </Card>


            <Card variant="outlined" className={DashboardStyles.dashCardBox}>
              <CardContent>
                <Typography variant="h6">Pages per visit</Typography>
                <Typography variant="h4"> 721K </Typography>
              </CardContent>
            </Card>


            <Card variant="outlined" className={DashboardStyles.dashCardBox} >
              <CardContent>
                <Typography variant="h6">Avg Visit Duration</Typography>
                <Typography variant="h4" > 00:01:19 </Typography>
              </CardContent>
            </Card>
          </Box>
        </div>
        <div className={DashboardStyles.dashboardRow2}>
          <div className={DashboardStyles.statistics}>
            <Typography sx={{ pl: "9%", color: '#1c1c1c', fontWeight: '600', fontSize: '15px', marginBottom: '5%' }}>Weekly Active Users</Typography>
            <LineChart width={550} height={300} data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              {/* <CartesianGrid stroke="none" /> */}
              {/* <Tooltip /> */}
              <Legend align='left' />
              <Line type="natural" dataKey="currentWeek" stroke="red" />
              <Line type="natural" dataKey="previousWeek" stroke="blue" />
            </LineChart>
          </div>
          <div className={DashboardStyles.trafficLocation}>
            <Typography variant='h1'>Traffic by Location</Typography>
            <ComposableMap>
              <Geographies geography="/features.json">
                {
                  ({ geographies }) =>
                    geographies.map((geo) => (
                      <Geography key={geo.rsmKey} geography={geo} />
                    ))
                }
              </Geographies>
            </ComposableMap>
            <Box className={DashboardStyles.trafficBoxMain}>
              <Box className={DashboardStyles.trafficBox}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant='h6'>New Zeland</Typography>
                  <Typography variant='h5'>100K</Typography>
                </Box>
                <Box>
                  <Progress_bar bgcolor="#CBDBF8" progress='100' height={8} />
                </Box>
              </Box>
              <Box className={DashboardStyles.trafficBox}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant='h6'>Singapore</Typography>
                  <Typography variant='h5'>96K</Typography>
                </Box>
                <Box>
                  <Progress_bar bgcolor="#CBDBF8" progress='96' height={8} />
                </Box>
              </Box>
              <Box className={DashboardStyles.trafficBox}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant='h6'>Sydney</Typography>
                  <Typography variant='h5'>82K</Typography>
                </Box>
                <Box>
                  <Progress_bar bgcolor="#CBDBF8" progress='82' height={8} />
                </Box>
              </Box>
              <Box className={DashboardStyles.trafficBox}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant='h6'>Michigan</Typography>
                  <Typography variant='h5'>80K</Typography>
                </Box>
                <Box>
                  <Progress_bar bgcolor="#CBDBF8" progress='80' height={8} />
                </Box>
              </Box>
            </Box>
          </div>
        </div>
        <div className={DashboardStyles.activeUsers}>
        </div>
      </div>
      <div className={DashboardStyles.dashboardNotifications}>

        <Typography variant='h1'>Notification </Typography>
        {
          notifications &&
          notifications.slice(-9).reverse().map((notification) => {
            return <SingleWork icon={<BugReportOutlinedIcon />} heading={notification.name} para={notification.details} time={notification.time} date={notification.date} />
          })
        }


      </div>
    </div>
  )
}

export default Dashboard